import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./EgyCatchFawryPage.scss";

import EgyCatchFawryPageDisplay from "./EgyCatchFawryPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router";
import { saveTracker } from "../../helpers/agencyTracker";

import { client } from "../../auth/Client";




const randomBg = bgSliders.randomBgooredoo();
function EgyCatchFawryPage({ t }) {
  const [isUserSubscribed, setisUserSubscribed] = useState(false);
  const [isErrorExist, setisErrorExist] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const location = useLocation();
  const history = useHistory();

  //const useParams = useParams();
 
    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'ZijMZyHHxh';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
        console.log(datag)
  
      }
      await saveTracker(datag);
  
    }
    
    async function login(userid) {
      if (userid) {
        var url = `http://www.api.playit.mobi/api/v2/playit/fetchSubDetails?userid=${userid}`;
        axios.get(url)
          .then(res => {
           console.log('res after sub' , res.data)
            if (res.data.status == true) {
              if (res.data.data.username && res.data.data.password) {
                
                var loged = setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                if (loged) {
                 console.log('login done')
                   history.push("/");
                } 

                const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
                if (hasAccess) { 
                    console.log('login access done')
                    setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                    // window.location.href = "/"
                  history.push(`/slogin/${res.data.data.user_id}`);
                  // this.props.history.push('/');

                  // window.location.reload();
                  
                }
              }
            }
            else {
              seterrorMessage(res.data.data)
              setErrorMesgSubscribe(res.data.data)
              setisErrorExist(true);
              setErrorMesg(true);
            
              // seterrors(false)
              // seterrsub(true)
              // seterrsubMsg(res.data.data);
            }
           // setloading(false)
  
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(function () {
            console.log('complete');
          });
  
  
      }
      
    }
    async function confirmLogin(username,password) {

      const hasAccess = await client.login(username,password);
      return hasAccess;
    }
  

    
  useEffect(() => {
    const params = queryString.parse(window.location.search);
 
    var queryParams;
    console.log(params);
    if (params.orderStatus == "PAID" || params.statusCode == "200") {
      console.log("SUCCESS")
      queryParams = window.location.search.split('?');
      queryParams = queryParams[1];
      console.log('queryParams' ,queryParams);
      //queryParams += "&PackageCode=" + packageCode;
      console.log(queryParams);
      login(params.customerProfileId);
      
    }
    else{
      
        console.log("ERROR")
        setisErrorExist(true);
        seterrorMessage(params.message);
                console.log(params.message);
                console.log(errorMesg);      

    }

  }, []);
  
  


  return (
    <EgyCatchFawryPageDisplay  bgImg={randomBg}>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

  

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal formsLayoutduverify">
        {/* <div className="header" className="header">
                <Link to="/">
                  <div className="logoo" />
                </Link>
              </div> */}
          {/* <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia">
          </div> */}

          <div className="Permission">
            {isErrorExist ?     
            <p>{errorMessage}</p>
            : 
            <p><Trans>Thanksubscribing</Trans></p>
            }
          </div>

       

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

    </EgyCatchFawryPageDisplay>
  );
}
export default withTranslation()(EgyCatchFawryPage)
