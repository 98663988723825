/* eslint-disable no-duplicate-case */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SafeRoute from "./auth/SafeRoute";
import EthSignupPage from "./operators/pages/EthSignupPage/EthSignupPage";
import EthVerifyPage from "./operators/pages/EthVerifyPage/EthVerifyPage";
import NcellSignupPage from "./operators/pages/NcellSignupPage/NcellSignupPage";
import VivaSignupPage from "./operators/pages/OmanSignupPage/SignupPage";
import JawwalSignupPage from "./operators/pages/JawwalSignupPage/SignupPage";
import UnitelSignupPage from "./operators/pages/UnitelSignupPage/SignupPage";
import GamesroidSignupPage from "./operators/pages/GamesroidSignupPage/SignupPage";
import RamdankGamesroidSignupPage from "./operators/pages/RamdankGamesroidSignupPage/SignupPage";
import KsaSignupPage from "./operators/pages/KsaSignupPage/SignupPage";
import platItFreeSignupPage from "./operators/pages/playItfreeSignup/SignupPage";
import NigeriaSignupPage from './operators/pages/NigeriaSignupPage/SignupPage';
import JawwalgamezroidSignupPage from './operators/pages/JawwalgamezroidSignupPage/SignupPage'
import OoredoopsSignupPage from "./operators/pages/OoredoopsSignupPage/SignupPage";
import ZainiqSignupPage from "./operators/pages/ZainiqSignupPage/SignupPage";
import MauritaniaSignupPage from "./operators/pages/MauritaniaSignupPage/SignupPage";

import KorekiqSignupPage from "./operators/pages/korekiqSignupPage/SignupPage"

import UmniahSignupPage from "./operators/pages/UmniahSignupPage/SignupPage";
// import DuWifiSignupPage from "./operators/pages/duWifiSignupPage/SignupPage";
import YmenSignupPage from "./operators/pages/YmenSignupPage/SignupPage";
import asiacellSignupPage from "./operators/pages/asiacellSignupPage/SignupPage";
import { DuverifyPage, EgyVerifyPage, IndonesiaVerifyPage } from "./pages"

import SharedUnsubscribe from "./operators/pages/SharedUnsubscribe/SharedUnsubscribe";
import { FAQPage, FavouritesPage, ForgetPasswordPage, GameDetailsPage, GamePage, EgyCatchPage, HomePage, HomePageCompetition, LanguagePage, LoginPage, ZainCatchPage, ZainGamezroidCatchPage, KorikCatchPage, LogoutPage, NotFoundPage, PaymentPage, ProfilePage, packagesPage, FreePointPage, WinnersPage, GamezroidcomProfilePage, RankingPage, RankingPagCompetition, GamezroidcomRankingPage, SignupPage, SubscribeCompletePage, TermsPage, helpPage, Rules, AdsPage, UnsubscribePage, VerifyPage, VoucherPage, KsaCatchPage, asiacellCatchPage, EgyLoginPage, BahrainCatchPage, OmantelCatchPage, UmniahCatchPage, StckuwaitCatchPage, asiacellThankYouPage, ThankYouPage, gamezoneThankYouPage, OoredooLoginPage, EthioLoginPage,EgyMondiaPayLoginPage, AlgeriaorLoginPage, DuLoginPage, GamezroidComLoginPage, MondiaSubscribePage, DuSubscribePage, NewEgySubscribePage, EgyptSubscribePage, IndonesiaSubscribePage, NewNigeriaSubscribePage,MondiaVerifyPage, MondiaCatchPage, EgyCatchFawryPage } from "./pages";
import VideosPage from "./pages/VideosPage";
import ValentinePage from "./pages/ValentinePage";
import WomenDayPage from './pages/WomenDayPage';
import WheelPage from './pages/WheelPage';
import ShortUrlLoginPage from "./pages/ShortUrlLoginPage/ShortUrlLoginPage";
import Search from "./components/Search/Search";
import VivaPageeDisplay from "./pages/VivaPage/VoucherPage.styled";
//import VivaSignupPage from "./operators/pages/VivaSignupPage/VivaSignupPage";
import GameFreamePage from "./pages/GameDetailsPage/GameFreamePage";
// import { signup } from "./operators/pages/EthSignupPage/services";


function redirect() {
    return window.location.href = "http://zainiq.playit.mobi/"
}

// function redirect2() {
//     return window.location.href = "http://jawwal2.playit.mobi/"
// }
/* locked routing to subscripe in zainiq port*/
function unSubcripeRedirect() {
    if (window.location.href.includes('zainiq.playit')) {
        return false;
    }
    if (window.location.href.includes('jawwal')) {
        return false;
    }
}

export default class Routes extends Component {
    render() {
        return (
            <Router >
                <Switch>
                   
                    <
                        SafeRoute locked={true}
                        path="/logout"
                        component={LogoutPage}
                    /> <
                        SafeRoute locked={true}
                        path="/my-games"
                        component={FavouritesPage}
                    /> { /* <SafeRoute locked={true} path="/profile" component={ProfilePage} /> */} <
                        SafeRoute locked={true}
                        path="/profile"
                        component={getHostComponentByRoute("profile")}
                    />

                    <
                        SafeRoute hasNav={true && unSubcripeRedirect()}
                        locked={true}
                        path="/unsubscribe"
                        component={getHostComponentByRoute("unsubscribe")}
                    />

                    <
                        SafeRoute locked={true}
                        path="/add-voucher"
                        component={VoucherPage}
                    /> { /* <SafeRoute exact path="/" component={HomePage} /> */} { /* <SafeRoute exact path="/home" component={HomePage} /> */} <
                        SafeRoute exact path="/home"
                        component={HomePage}
                    />


                    <
                        SafeRoute path="/slogin/:id"
                        component={ShortUrlLoginPage}
                    /> <
                        SafeRoute path="/viva"
                        component={VivaPageeDisplay}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/catch-zainiq"
                        component={ZainCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/catch-zainiqgamezroid"
                        component={ZainGamezroidCatchPage}
                    />
                    <
                        SafeRoute exact hasNav={false}
                        path="/catch-korikPlayIT"
                        component={KorikCatchPage}
                    />

                    <
                        SafeRoute exact hasNav={false}
                        path="/catch-ksa"
                        component={KsaCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/login/catch-ksa"
                        component={KsaCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/catch-asiacell"
                        component={asiacellCatchPage}
                    />


                    <
                        SafeRoute exact hasNav={false}
                        path="/thankyou"
                        component={ThankYouPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/thank-ksa"
                        component={ThankYouPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/thank-gamezone"
                        component={gamezoneThankYouPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/thank-asiacell"
                        component={asiacellThankYouPage}
                    />


                    <
                        SafeRoute exact hasNav={false}
                        path="/catch-egy"
                        component={EgyCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/catch-ncell"
                        component={EgyCatchPage}
                    />
                    <
                        SafeRoute exact hasNav={false}
                        path="/eth_terms"
                        component={EthVerifyPage}
                    />

                    <
                        SafeRoute exact hasNav={false}
                        path="/catch-stckuwait"
                        component={StckuwaitCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/bahrain-catch"
                        component={BahrainCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/omantel-catch"
                        component={OmantelCatchPage}
                    /> <
                        SafeRoute exact hasNav={false}
                        path="/umniah-catch"
                        component={UmniahCatchPage}
                    />


                    <
                        SafeRoute exact hasNav={false}
                        path="/egy-login"
                        component={EgyLoginPage}
                    /> { /* <SafeRoute exact hasNav={false} path="/login" component={LoginPage} /> */}

                    <
                        SafeRoute exact path="/packages"
                        component={packagesPage}
                    /> <
                        SafeRoute exact path="/invite"
                        component={FreePointPage}
                    />




                    <
                        SafeRoute exact path="/winners"
                        component={WinnersPage}
                    />




                    <
                        SafeRoute exact path="/"
                        component={getHostComponentByRoute("/")}
                    />


                    <
                        SafeRoute hasNav={false}
                        path="/login"
                        component={getHostComponentByRoute("login")}
                    />

                    <
                        SafeRoute hasNav={false}
                        path="/signup"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup11"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup21"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup31"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup41"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup51"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup61"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup71"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signupksa"
                        component={getHostComponentByRoute("signup")}
                    /> 
                    <
                        SafeRoute hasNav={false}
                        path="/signup1"
                        component={getHostComponentByRoute("signup")}
                    />
                    <
                        SafeRoute hasNav={false}
                        path="/signup2"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup3"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup4"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup5"
                        component={getHostComponentByRoute("signup")}
                    /> <
                        SafeRoute hasNav={false}
                        path="/signup6"
                        component={getHostComponentByRoute("signup")}
                    />

                    <
                        SafeRoute hasNav={false}
                        path="/Ramdanksignup"
                        component={getHostComponentByRoute("signup")}
                    />




                    <
                        SafeRoute hasNav={false}
                        path="/complete"
                        component={SubscribeCompletePage}
                    /> <
                        Route hasNav={false}
                        path="/verify"
                        component={VerifyPage}
                    /> <
                        SafeRoute path="/change-language"
                        component={LanguagePage}
                    /> <
                        SafeRoute hasNav={false}
                        path="/mondiasubscribe"
                        component={MondiaSubscribePage}
                    />

                    <
                        SafeRoute hasNav={true}
                        path="/mondiaverify"
                        component={MondiaVerifyPage}
                    />

                    <
                        SafeRoute hasNav={true}
                        path="/duverify"
                        component={DuverifyPage}
                    />
                    <
                        SafeRoute hasNav={true}
                        path="/egyverify"
                        component={EgyVerifyPage}
                    />

                    <
                    SafeRoute hasNav={true}
                    path="/indonesiaverify"
                    component={IndonesiaVerifyPage}
                />
                    <
                        SafeRoute hasNav={true}
                        path="/egycatchfawry/:id"
                        component={EgyCatchFawryPage}
                    />

                    <SafeRoute hasNav={false}
                        path="/mondiacatch"
                        component={MondiaCatchPage}
                    />

                    <SafeRoute hasNav={false}
                        path="/ducatch"
                        component={MondiaCatchPage}
                    />


                    <
                        SafeRoute hasNav={false}
                        path="/forget-password"
                        component={ForgetPasswordPage}
                    />


                    <
                        SafeRoute path="/leaderboard"
                        component={getHostComponentByRoute("leaderboard")}
                    />


                    <
                        SafeRoute hasNav={false}
                        path="/valentine-pastime"
                        component={ValentinePage}
                    /> <
                        SafeRoute hasNav={false}
                        path="/women-day"
                        component={WomenDayPage}
                    /> <
                        SafeRoute path="/Wheel"
                        component={WheelPage}
                    /> <
                        SafeRoute path="/videoPlay"
                        component={VideosPage}
                    /> <
                        SafeRoute path="/faqs"
                        component={FAQPage}
                    /> <
                        SafeRoute path="/gamex/:gameID"
                        component={GamePage}
                    /> <
                        SafeRoute path="/game/:gameID"
                        component={GameDetailsPage}
                    /> <
                        SafeRoute path="/gamefree/:gameID/:userID"
                        component={GameDetailsPage}
                    /> <
                        SafeRoute path="/frame/:gameID"
                        component={GameFreamePage}
                    /> <
                        SafeRoute exact path="/payment"
                        component={PaymentPage}
                    /> <
                        SafeRoute path="/search"
                        component={Search}
                    /> <
                        SafeRoute path="/tos"
                        component={TermsPage}
                    /> <
                        SafeRoute path="/help"
                        component={helpPage}
                    /> <
                        SafeRoute path="/rules"
                        component={Rules}
                    /> <
                        SafeRoute path="/ads"
                        component={AdsPage}
                    />
                    <
                        SafeRoute path="/egy-subscribe"
                        hasNav={false}
                        component={EgyptSubscribePage}
                    />
                    <SafeRoute component={NotFoundPage}
                    />
                </Switch>
            </Router>
        );
    }
}

//console.log(document.location.host)
const getHostComponentByRoute = route => {
    const hostname = document.location.host;
    if (route === "signup") {
        switch (hostname) {
            case "ethio.playit.mobi":
                return EthSignupPage;
            case "ncell.playit.mobi":
                return NcellSignupPage;
            case "omantel.playit.mobi":
                return VivaSignupPage;
            case "www.egypt.playit.mobi":
                return EgyptSubscribePage;
            case "egypt.playit.mobi":
                return EgyptSubscribePage;
            case "ksa.playit.mobi":
                return KsaSignupPage;
            case "umniah.playit.mobi":
                return UmniahSignupPage;
            case "gamesstore.you-tele.com":
                return YmenSignupPage;
            case "www.gamesstore.you-tele.com":
                return YmenSignupPage;
            case "gamesstore.you.com.ye":
                return YmenSignupPage;
            case "www.gamesstore.you.com.ye":
                return YmenSignupPage;
            case "ksa2.playit.mobi":
                return KsaSignupPage;
            case "zain-iq.playit.mobi": //TODO: FOR TESTING ONLY
                return redirect;
            case "jawwal.playit.mobi": //TODO: FOR TESTING ONLY
                return JawwalSignupPage;
            case "gamezone.playit.mobi": //TODO: FOR TESTING ONLY
                return UnitelSignupPage;
            case "competition.playit.mobi": //TODO: FOR TESTING ONLY
                return platItFreeSignupPage;
            case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
                return platItFreeSignupPage;
            case "mtnng.playit.mobi":
                return NigeriaSignupPage;
            case "www.mtnng.playit.mobi":
                return NigeriaSignupPage

                case "newmtnng.playit.mobi":
                    return NewNigeriaSubscribePage;
                case "www.newmtnng.playit.mobi":
                    return NewNigeriaSubscribePage


                
            case "ao.playit.mobi": //TODO: FOR TESTING ONLY
                return UnitelSignupPage;
            case "ethio.gamezones.mobi":
                return UnitelSignupPage;
            case "www.ethio.gamezones.mobi":
                return UnitelSignupPage;
            case "jawwal.gamezroid.com":
                return JawwalgamezroidSignupPage;
            case "www.jawwal.gamezroid.com":
                return JawwalgamezroidSignupPage
            case "gamezroid.com":
                return GamesroidSignupPage;

            case "ethio.gamezroid.com":
                return GamesroidSignupPage;
            case "www.ethio.gamezroid.com":
                return GamesroidSignupPage;

            case "www.gamezroid.com":
                return GamesroidSignupPage;
            case "ramdank.gamezroid.com":
                return RamdankGamesroidSignupPage;
            case "www.ramdank.gamezroid.com":
                return RamdankGamesroidSignupPage;
            case "asiacell.gamezroid.com":
                return asiacellSignupPage;
            case "ooredoops.playit.mobi":
                return OoredoopsSignupPage
            case "www.ooredoops.playit.mobi":
                return OoredoopsSignupPage;
            case "www.zainiq.gamezroid.com":
                return ZainiqSignupPage
            case "zainiq.gamezroid.com":
                return ZainiqSignupPage;

            case "www.korekiq.playit.mobi":
                return KorekiqSignupPage
            case "korekiq.playit.mobi":
                return KorekiqSignupPage;
            case "dzo.playit.mobi":
                return MondiaSubscribePage;
            case "www.dzo.playit.mobi":
                return MondiaSubscribePage;


            case "mauritania.playit.mobi":
                return MauritaniaSignupPage;

            case "www.mauritania.playit.mobi":
                return MauritaniaSignupPage;

            case "telkomsel.playit.mobi":
                return IndonesiaSubscribePage;

            case "www.telkomsel.playit.mobi":
                return IndonesiaSubscribePage;

            case "du.playit.mobi":
                return DuSubscribePage;
            case "www.du.playit.mobi":
                return DuSubscribePage;




            default:
                //  return IndonesiaSubscribePage;
                //return EthSignupPage;
                return SignupPage;
                //return MauritaniaSignupPage



        }
    } else if (route === "unsubscribe") {
        switch (hostname) {
            case "ethio.playit.mobi":
                return SharedUnsubscribe;
            case "omantel.playit.mobi":
                return SharedUnsubscribe;
            case "umniah.playit.mobi":
                return SharedUnsubscribe;
            case "jawwal.playit.mobi":
                return SharedUnsubscribe;
            case "gamezone.playit.mobi": //TODO: FOR TESTING ONLY
                return SharedUnsubscribe;
            case "mtnng.playit.mobi":
                return SharedUnsubscribe;
            case "www.mtnng.playit.mobi":
                return SharedUnsubscribe;
            case "jawwal.gamezroid.com":
                return SharedUnsubscribe;
            case "www.jawwal.gamezroid.com":
                return SharedUnsubscribe;
            case "ooredoops.playit.mobi":
                return SharedUnsubscribe
            case "www.ooredoops.playit.mobi":
                return SharedUnsubscribe;
            case "zainiq.gamezroid.com":
                return SharedUnsubscribe
            case "www.zainiq.gamezroid.com":
                return SharedUnsubscribe;
            // case "dzo.playit.mobi":
            //   return SharedUnsubscribe;
            //   case "www.dzo.playit.mobi":
            //     return SharedUnsubscribe;

            default:
                return UnsubscribePage;
            // return SharedUnsubscribe
        }
    } else if (route === "leaderboard") {
        // eslint-disable-next-line default-case
        switch (hostname) {
            case "competition.playit.mobi":
                return RankingPagCompetition;

            case "www.competition.playit.mobi": //TODO: FOR TESTING ONLY
                return RankingPagCompetition;


            case "ramdank.gamezroid.com":
                return WinnersPage;

            case "www.ramdank.gamezroid.com":
                return WinnersPage
        }
        return RankingPage;
        // return WinnersPage;


    } else if (route === "login") {
        // eslint-disable-next-line default-case
        switch (hostname) {
            case "ooredoops.playit.mobi":
                return OoredooLoginPage
            case "www.ooredoops.playit.mobi":
                return OoredooLoginPage;
            case "ramdank.gamezroid.com":
                return GamezroidComLoginPage;
            case "www.ramdank.gamezroid.com":
                return GamezroidComLoginPage;
            case "dzo.playit.mobi":
                return AlgeriaorLoginPage;
            case "www.dzo.playit.mobi":
                return AlgeriaorLoginPage;
            case "du.playit.mobi":
                return DuLoginPage;
            case "www.du.playit.mobi":
                return DuLoginPage;

            case "egypt.playit.mobi":
                return EgyMondiaPayLoginPage
            case "www.egypt.playit.mobi":
                return EgyMondiaPayLoginPage;
        }
        return LoginPage;
        // return EthioLoginPage;


    } else if (route === "profile") {
        // eslint-disable-next-line default-case
        switch (hostname) {
            case "ramdank.gamezroid.com":
                return GamezroidcomProfilePage;
            case "www.ramdank.gamezroid.com":
                return GamezroidcomProfilePage;
        }
        return ProfilePage;
        // return GamezroidcomProfilePage;


    } else if (route === "/") {
        // eslint-disable-next-line default-case
        switch (hostname) {
            case "ramdank.gamezroid.com":
                return HomePageCompetition;
            case "www.ramdank.gamezroid.com":
                return HomePageCompetition;

        }
        return HomePage
        // return HomePageCompetition;
    }
};