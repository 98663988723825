import React from "react";
import { Trans } from "react-i18next";
import {withRouter} from 'react-router-dom';

import h from "../../../helpers/helpers"


const hostName = window.location.hostname;
const iconsTab = h.getIconByHostName(hostName);

//const ethioGamezroid = hostName.includes('local')
//const ethioGamezone = hostName.includes('local')

const ethioGamezroid = hostName.includes('gamezroid') && hostName.includes('ethio')
const ethioGamezone = hostName.includes('gamezones') && hostName.includes('ethio')




//console.log("uuu" , iconsTab)
//console.lo



export default function MainTab({
  mainTabsInfo,
  activeMainTab,
  setActiveMainTab
  
}) 

{

 
  const parent = mainTabsInfo.map((i, k ,value) => {
    value = 3
    console.log(value , 'task')
    return (
      
      <div>{value !==3 && <p className="now">child</p>}</div>
      // <div 
      //   style={{ display: ethioGamezroid && i.slug == 'online' || ethioGamezone && i.slug == 'android' ? 'none' : '' }}
      //   key={`mainTab-${k}`}
      //   className={activeMainTab === i.slug ||  ethioGamezone && i.slug == 'online'? "isActive mainTab" : "mainTab"}
      //   onClick={() => {
      //     setActiveMainTab(i.slug);
      //   }}
      // >
      //   <img src={"/assets/icons/" + iconsTab + "/" + i.slug + ".svg"} alt={i.slug} />
      //   <Trans>sub_navbar.{i.name}</Trans>
      // </div>
    
      
    );
    
  });

  const mainTabs = mainTabsInfo.map((i, k) => {
    return (
      <div 
        style={{ display: ethioGamezroid && i.slug == 'online' || ethioGamezone && i.slug == 'android' ? 'none' : '' }}
        key={`mainTab-${k}`}
        className={activeMainTab === i.slug ||  ethioGamezone && i.slug == 'online'? "isActive mainTab" : "mainTab"}
        onClick={() => {
          setActiveMainTab(i.slug);
        }}
      >
        <img src={"/assets/icons/" + iconsTab + "/" + i.slug + ".svg"} alt={i.slug} />
        <Trans>sub_navbar.{i.name}</Trans>
      </div>
    
      
    );
    
  });
  
  return <div><div className="mainTabs container">{mainTabs} 
  
  </div>
  <div>
    {parent}
  </div>
  </div>
}
