import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./OoredooLoginPage.scss";
import Modal from 'react-awesome-modal';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import OoredooLoginPageDisplay from "./OoredooLoginPage.styled";
import { useHistory } from "react-router";

const randomBg = bgSliders.getRandomBg();
function OoredooLoginPage( {t}) {

 
  const [phone, setphone] = useState("");
  const [verify_code, setverify_code] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [verify_errorMsg, setverify_errorMsg] = useState("");
  const [otpid, setotpid] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [showverif, setshowverif] = useState(false);
  const [showLoginPage, setshowLoginPage] = useState(true);
  const [loading, setloading] = useState(false);
  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();

  

  useEffect(() => {
    check();
  })
 
  async function check() {
    if (await checkAuth()) {
     history.push("/");
    }
  }

  async function login(e) {
    e.preventDefault();
    if (!isValid(phone)) {
      seterrors(true);
      seterrorMsg("Empty_Phone_Number")
      return;
    }
      if (phone) {
        var url = `http://api.playit.mobi//api/v2/imi/ooredoo/sendLogin?username=${phone}`;
        setloading(true);
        axios.get(url)
          .then(res => {
            if (res.data.status == true) {
              if (res.data.user_id) {
                setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              }
              if(res.data.otpid)
              {
                setotpid(res.data.otpid)
               
              }
              setshowverif(true) 
            }
            else {
              console.log( "res login" , res);
              seterrors(false)
              seterrsub(true)
              seterrsubMsg(res.data.msg);
            }
            setloading(false)
  
          })
          .catch(function (error) {
            console.log(error)
            //setshowverif(true)
          })
          .finally(function () {
            console.log('complete');
          });
  
  
      }
  }
 
  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

  function isValid(phone) {
    return phone ? true : false;
  }



  const verify = async e => {
    e.preventDefault();
    const url = "http://api.playit.mobi//api/v2/imi/ooredoo";
   // setloading(true);
      axios.get(`${url}/verifyToLoginuser?username=${phone}&pin=${verify_code}&otpid=${otpid}`)
        .then(res => {
          console.log(res);
          if (res.data.status == true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              window.location.href = "/";
            }
            window.location.href = "/";
          } else {
            setverify_errors(true);
            setverify_errorMsg(res.data.msg)
            //verify_errorMsg(res.data.message)
            //setVerfiErrorMesg(true)
          }
          //setloading(false);

        })
        .catch(function (error) {
          console.log(error);
          //setVerfiErrorMesg(true)
        })
        .finally(function () {

          console.log('complete');
        });
    
  };

 function onInputChange(name, value) {
   if(name === "verify_code")
   {
     setverify_code(value)
   }
   else{
   setphone(value)
   }
  }

    return (
      <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <OoredooLoginPageDisplay className="" bgImg={randomBg}>
     
        {showLoginPage ? (
          <div className="formPage">
            <form
              onSubmit={e => {
                login(e);
              }}
            >
              <div className="formsLayout" style={{minHeight:'50vh'}}>
                <div className="header">
                  <Link to="/">
                    <div className="logo" />
                  </Link>
                </div>
                <div className="smsText" style={show ? { display: 'none' } : {}}>
                  <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
                </div>
                <div className="center" style={{width:'90%'}}>

                  <div className="form" style={show ? {} : { display: 'none' }}>
                    {!showverif ? 
                    <div className="iconInput">
                      <i className="icon phone" />
                      <input
                        type="number"
                        className="input"
                        autoComplete="phone"
                        placeholder={t("972xxxxxxxx")}
                        onChange={e =>
                          onInputChange("phone", e.target.value)
                        }
                      />
                    </div>
                    : null}
                    

                    {showverif ?
                      <div className="">
                        <p><Trans>verifycode_or</Trans></p>
                        <input
                          type="number"
                          className="input"
                          placeholder={t("Enter_verifycode")}
                          onChange={e =>
                            onInputChange("verify_code", e.target.value)
                          } />
                      </div> : null
                    }


                  </div>
                </div>

                {/* <div className="footer" style={show ? {} : { display: 'none' }}>
                  {!showverif?
                    <div className="normBtn">
                      <button className="btn">
                        <Trans>sign_in</Trans>
                      </button>
                    </div>
                    : null}
                  {showverif ? 
                    <div className="normBtn">
                      <button className="btn" onClick={e => { verify(e) }}>
                        <Trans>Verify</Trans>
                      </button>
                  </div> : null}

                  {!showverif ?(
                    <p>
                      <Trans>login.don’t_have_account</Trans>{" "}
                      <Link to="signup">
                        <Trans>sign_up</Trans>
                      </Link>
                    </p>
                  ) : null}
                </div> */}
              </div>
            </form>


            <div className="footer" style={show ? {} : { display: 'none' }}>
                  {!showverif?
                    <div className="normBtn" onClick={e => { login(e) }}>
                      <button className="btn">
                        <Trans>sign_in</Trans>
                      </button>
                    </div>
                    : null}
                  {showverif ? 
                    <div className="normBtn">
                      <button className="btn" onClick={e => { verify(e) }}>
                        <Trans>Verify</Trans>
                      </button>
                  </div> : null}

                  {!showverif ?(
                    <p>
                      <Trans>login.don’t_have_account</Trans>{" "}
                      <Link to="signup">
                        <Trans>sign_up</Trans>
                      </Link>
                    </p>
                  ) : null}
                </div>
            {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
            {verify_errors ? <FloatingAlert type="error">{verify_errorMsg}</FloatingAlert> : ""}
            {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


          </div>
        ) : null}
      </OoredooLoginPageDisplay>
      </>
    );
   
  }




/* End Update State Use REDUX */
export default withTranslation()(OoredooLoginPage)



