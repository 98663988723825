import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./RamdankGamesroidSignupPage.scss";
import RamdankGamesroidSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import Modal from 'react-awesome-modal';
import Operators from "../../../operators/operators.json"
import * as localLang from "../../../i18n/i18n";
import { useHistory } from "react-router";

const randomBg = bgSliders.getRandomBg();
function RamdankGamesroidSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [loading, setloading] = useState(false);
  const [showwaiting, setshowwaiting] = useState(false);
  const [signupPlan, setSignupPlan] = useState("1276");
  const [productCatalog, setProductCatalog] = useState("");
  const [planPrice, setPlanPrice] = useState("2.5");
  const [planCode, setPlanCode] = useState("1276");
  const [networkName, setNetworkName] = useState("");
  const [planName, setplanName] = useState("daily plan,");
  const [messageCode, setmessageCode] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [counter, setCounter] = useState(60);
  const [minutes, setMinutes] = useState(1);
  const [SessionToken, setSessionToken] = useState("");
  const [showconditionplan, setShowconditionplan] = useState(true);
  const [showconditionnetwork, setShowconditionnetwork] = useState(false);
  const [showtimer, setShowTimer] = useState(false);
  const [resourceUrl, setResourceUrl] = useState("");
  const [visablemodalverify, setvisablemodalverify] = useState(false);
  const [visablemodalSubscribe, setvisablemodalSubscribe] = useState(false);
  const [oper, setoper] = useState("");
  const [enriched, setenriched] = useState(false);
  const [noenriched, setnoenriched] = useState(false);
  const [Msisdn, setMsisdn] = useState("");
  const [whiteStyle, setwhiteStyle] = useState(false);
  const [showPlay, setshowPlay] = useState(true);
  const [startpage, setstartpage] = useState(true);
  const [UrlImg1, setUrlImg1] = useState("");
  const [UrlImg2, setUrlImg2] = useState("");
  const [UrlImg3, setUrlImg3] = useState("");
  const [gcookies, setgcookies] = useState("");
  const history = useHistory();

  function readCookies() {
    console.log("my task", document.cookie.split(';'))
    var ca = document.cookie.split(';');
    var cname = '_ga'
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        console.log("cookies", c.substring(name.length, c.length));
        setgcookies(c.substring(name.length, c.length))
        //return c.substring(name.length, c.length);
      }
    }


  }
  useEffect(() => {
    readCookies();
    // changeStyle();

    // setloading(false);
    // setstartpage(false);
    // setShowverif(true);
    // setshowwaiting(false);




    //end test

    setUrlImg1("/assets/images/subscribegamezriod/like.png")
    setUrlImg2("/assets/images/subscribegamezriod/Repeat.png")
    setUrlImg3("/assets/images/subscribegamezriod/group.png")


    var language = localStorage.getItem("i18nextLng");
    const query = queryString.parse(window.location.search);
    console.log("query", query)
    const SessionIdVar = query.SessionId;
    if (query.affte) {
      localStorage.setItem('affte', query.affte)
    }
    axios.get('http://api.playit.mobi/api/v1/getTpayJsURL?language=' + language + '&sessionId=' + SessionIdVar)
      .then(res => {
        setResourceUrl(res.data.url);
        loadLibrary(res.data.url);
      })

    if (query.SessionToken) {
      setstartpage(false);
      setOperatorCode(query.OperatorCode);
      setSessionToken(query.SessionToken);
      if (query.SubId != null && query.SubId != '') {
        window.localStorage.setItem('subscriptionContractId', query.SubId);
        setvisablemodalSubscribe(true)
      }

      localLang.checklocalLanguage();

    }
    // set tracker
    const url = window.location.search;
    //console.log(url)
    //const url = "http://gamezroid.com/signup?parameter=wftpass7smlnvol12n9l5s1i&trafficsource=BothAds"
    if (url !== "") {
      console.log("tracker")
      setAgencyData(url);
    }

  }, []);

  const changeStyle = () => {
    var url = window.location.href;
    const btn = document.getElementById('signup');
    const header = document.querySelector('.header');
    const content = document.querySelector('.gamezoridcontent');
    const play = document.querySelector('.play');
    const logo = document.querySelector('.logo');
    const gamesInfo = document.getElementsByClassName('games-info');
    const iconsImg = document.getElementsByClassName('iconsimg');
    const priceText = document.querySelector('.freesubscribe');
    const conditionText = document.querySelector('.ncellTos');
    const decilimer = document.getElementsByClassName('decilimer');
    const operatorSignup = document.querySelector('.operatorSignup');
    var inputPhone;
    if (url.includes("signup21") || url.includes("signup51") || url.includes("signup61")) {
      const btn_style = {
        color: 'white',
        backgroundColor: '#BB2F14',
        border: '1px solid red'
      };

      header.style = "border-bottom: 2px solid #BB2F14;"
      content.style = 'color:black';
      priceText.style = 'color :black';
      // verifyInput.style = 'color :black';
      conditionText.style = 'color :black';
      operatorSignup.style = 'background :#fff';
      logo.style = "background: url('/assets/images/logosignup2.svg') center center / cover no-repeat";

      if (startpage == false) {
        inputPhone.style = 'background :#383535';

      }
      // if(showverif == true && startpage == false)
      // {
      //   const verifyInput = document.getElementById('verifyInput');
      //   console.log("task")
      //   verifyInput.style = 'color :black';
      // }
      for (var i = 0; i < gamesInfo.length; i++) {
        gamesInfo[i].style.color = "#601D10";
      }
      for (var i = 0; i < decilimer.length; i++) {
        decilimer[i].style.color = "black";
      }
      iconsImg[0].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/like.png')
      iconsImg[1].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/Repeat.png')
      iconsImg[2].setAttribute('src', '/assets/images/subscribegamezriod/redIcons/group.png')
      Object.assign(btn.style, btn_style);

    }

    if (url.includes("signup31")) {
      const btn_style = {
        backgroundColor: '#E09C24',
        border: '1px solid #BB2F14'
      };
      header.style = "border-bottom: 2px solid #BB2F14;"
      operatorSignup.style = 'background :black';
      play.style = "background: url('/assets/images/Path29.svg') center center / cover no-repeat; height:242px";
      Object.assign(btn.style, btn_style);
    }

    if (url.includes("signup41")) {
      setshowPlay(false)
      const btn_style = {
        backgroundColor: '#C9FE3B',
        border: '1px solid #F2D12C'
      };
      header.style = "border-bottom: 2px solid #89BF2F;"
      operatorSignup.style = 'background :black';
      play.style = "background: url('/assets/images/Path29.svg') center center / cover no-repeat";
      Object.assign(btn.style, btn_style);
    }

  }

  const directLink = () => {
    // direct to link
    const query = queryString.parse(window.location.search);
    var url = window.location.href;
    var number = "ramdank";
    if (url.includes('signup71')) {
      number = '7'
    }
    // else if (url.includes('signup31')) {
    //   number = '3'
    // }
    // else if (url.includes('signup41')) {
    //   number = '4'
    // }
    // else if (url.includes('signup51')) {
    //   number = '5'
    // }
    // else if (url.includes('signup61')) {
    //   number = '6'
    // }
    if (query.Details == null) {
      axios.get('http://www.api.playit.mobi/api/v1/getTpayURL?number=' + number)
        .then(res => {
          window.location.href = res.data.url;
        })
    }

  }

  //load library 

  const loadLibrary = (source) => {
    const popupscript = document.createElement('script');
    popupscript.id = "popupscript";
    popupscript.src = source;
    popupscript.async = true;
    document.body.appendChild(popupscript);
    setloading(true);
    popupscript.onload = () => {
      console.log("script load")
      document.body.appendChild(popupscript);
      var headerenriched = window.TPay.HeaderEnrichment.enriched();
      if (headerenriched) {
        setloading(false);
        setshowwaiting(true)
        setenriched(true);
        const operator = window.TPay.HeaderEnrichment.operatorCode();
        networkGamezroid.map((c) => {
          if (c.code == operator) {
            setNetworkName(c.name);
            setmessageCode(c.code_message);
          }
        })
        setTimeout(() => {
          showpopsubscribe();
        }, 3000);
      }
      else {
        setshowwaiting(true)

        console.log("noload");
        setloading(false);
        setenriched(false);
        setnoenriched(true)
        //setcheckenriched(false)
        //setnoenriched(true)
        //document.getElementById('signupcheck').style = "display : none";
      }
    }
    document.body.appendChild(popupscript);
    //document.head.appendChild(popupscript)

  }

  const closeModalverify = () => {
    setvisablemodalverify(false)
  };

  const closeModalSubscribe = () => {
    setvisablemodalSubscribe(false)
  };


  const sendData = async e => {
    e.preventDefault();
    const subscriptionContractId = localStorage.getItem('subscriptionContractId');
    const language = localStorage.getItem("i18nextLng");
    var header;
    (enriched == true) ? header = '1' : header = '0';
    const options = {
      subscriptionContractId,
      language,
      header
    };
    axios.post("http://www.api.playit.mobi/api/v1/resendacount", options)
      .then(res => {
        if (res.data.status == true) {
          setvisablemodalSubscribe(false);
          window.location.href = "/"
        }
        else {
          setvisablemodalSubscribe(false);
          setErrorMesgSubscribe(res.data.message);
          setErrorMesg(true);
        }
        //console.log(res)

      })
      .catch(error => {
        //console.log(error)
        setvisablemodalSubscribe(false);
      }

      )

  }

  useEffect(() => {

    var timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (minutes > 0 && counter == 0 && showtimer == true) {
      setMinutes(minutes - 1)
      setCounter(60)
    }
    if (counter == 0 && minutes == 0 && showtimer == true) {
      document.getElementById("signup").removeAttribute("disabled");
      setShowTimer(false);
      setErrorMesg(false)

    }

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const APImsisdn = {
    bhmsisdn: ''
  }

  const plans = [
    {
      code: "1276",
      name: "daily plan",
      price: 1
    },
    {
      code: "1278",
      name: "weekly plan",
      price: 2
    }
  ];

  const plansGame = [
    {
      code: "1276",
      name: "daily plan",
      price: 2.5
    },
    /*{
      code: "1278",
      name: "weekly plan",
      price: 12
    },*/

  ];

  const networkGamezroid = [
    {
      code: "60202",
      name: "Vodafone",
      code_message: "2303",

    },
    {
      code: "60201",
      name: "Orange",
      code_message: "5030",


    },
    {
      code: "60203",
      name: "Etisalat",
      code_message: "1722",


    },
    {
      code: "60204",
      name: "We",
      code_message: "4041",


    },

  ];

  const [alert, setAlert] = useState(false);


  const checkheaderenriched = (e) => {
    e.preventDefault();
    directLink();
  }

  const Subscribe = async (e) => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData;
      // if (!phon_n.slice(0, 4).includes('244')) {
      //   phon_n = '244' + phon_n;
      // }

      try {
        var initialPaymentproductId = 'prodday';
        if (signupPlan == '1278') {
          initialPaymentproductId = 'prodweek';
        }
        var msisdn = phon_n;
        //console.log(msisdn)

        var countryCode = '02';
        var operatorCode = productCatalog;
        var productCatalogName = '';
        var language = localStorage.getItem("i18nextLng");
        if (operatorCode == "60202") {
          productCatalogName = "vodafonecat"
        }
        if (operatorCode == "60201") {
          productCatalogName = "orangecat"
        }
        if (operatorCode == "60203") {
          productCatalogName = "etisalatcat"
        }
        if (operatorCode == "60204") {
          productCatalogName = "wecat"
        }
        var subscriptionPlanId = signupPlan;


        const url = "http://www.api.playit.mobi/api/v1/tpay/newContractRoid";
        const options = {
          msisdn,
          countryCode,
          operatorCode,
          subscriptionPlanId,
          productCatalogName,
          initialPaymentproductId,
          language,
          SessionToken,
          trackerCompany: localStorage.getItem("trackerCompany"),
          tracker: localStorage.getItem('tracker'),
          affte: localStorage.getItem("affte"),
          _ga: gcookies

        };
        const res = await axios.post(url, options);
        if (res.data) {

          const data = res.data;
          if (res.data.status == true) {
            window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
            setShowverif(true)
            setErrorMesg(false)

          } else {
            console.log("here");
            console.log(res.data);

            if (res.data.status == false && res.data.subscriptionContractId) {
              console.log("here1");
              window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
              setvisablemodalSubscribe(true);

            }
            else {
              console.log("here1");
              setErrorMesgSubscribe(res.data.message)
              setErrorMesg(true);
              setCounter(60)
              setShowTimer(true)
              document.getElementById("signup").setAttribute("disabled", 'disabled')
              setShowverif(false);
            }

          }
        }
      } catch (err) {
        //console.log(err);
        setErrorMesg(true)
        setShowverif(false)
      }

    }

  };

  const TpaySubscribe = async (Msi, headerEnrichmentReferenceCode, sessionToken) => {
    try {
      var initialPaymentproductId = 'prodday';
      if (signupPlan == '1278') {
        initialPaymentproductId = 'prodweek';
      }
      var msisdn = Msi;
      var countryCode = '02';
      var operator = window.TPay.HeaderEnrichment.operatorCode();
      var language = localStorage.getItem("i18nextLng");
      var productCatalogName = '';
      if (operator == "60202") {
        productCatalogName = "vodafonecat"
      }
      if (operator == "60201") {
        productCatalogName = "orangecat"
      }
      if (operator == "60203") {
        productCatalogName = "etisalatcat"
      }
      if (operator == "60204") {
        productCatalogName = "wecat"
      }
      var subscriptionPlanId = signupPlan;
      const url = "http://www.api.playit.mobi/api/v1/tpay/newContractRoid";
      const options = {
        msisdn,
        countryCode,
        operatorCode: operator,
        subscriptionPlanId,
        productCatalogName,
        initialPaymentproductId,
        language,
        SessionToken: sessionToken,
        headerEnrichmentReferenceCode,
        trackerCompany: localStorage.getItem("trackerCompany"),
        tracker: localStorage.getItem('tracker'),
        affte: localStorage.getItem("affte"),
        _ga: gcookies

      };
      const res = await axios.post(url, options);
      if (res.data) {
        if (res.data.status == true) {
          window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
          const GMSISDN = res.data.username;
          setErrorMesg(false);
          trackerEnrichment(GMSISDN, operator);
          setvisablemodalverify(true);
          if (window.location.href.includes('signup6')) {
            localStorage.setItem("routing", "signup6")
          }
          setTimeout(() => {
            setvisablemodalverify(false);
            history.push('/thankyou')
            // window.location.href = "/"
          }, 3000);

        } else {
          if (res.data.status == false && res.data.subscriptionContractId) {

            console.log("here11");
            window.localStorage.setItem('subscriptionContractId', res.data.subscriptionContractId);
            setvisablemodalSubscribe(true);
          }
          setErrorMesgSubscribe(res.data.message)
          setErrorMesg(true);
          setCounter(60)
          setShowTimer(true)
          document.getElementById("signupenriched").setAttribute("disabled", 'disabled')
          setShowverif(false);

        }
      }
    } catch (err) {
      setErrorMesg(true)
      setShowverif(false)
    }

  }

  const showpopsubscribe = () => {
    //e.preventDefault();
    console.log("popup show")
    var initialPaymentproductId = 'prodday';
    if (signupPlan == '1278') {
      initialPaymentproductId = 'prodweek';
    }
    var operator = window.TPay.HeaderEnrichment.operatorCode();
    var productCatalogName = '';
    if (operator == "60202") {
      productCatalogName = "vodafonecat"
    }
    if (operator == "60201") {
      productCatalogName = "orangecat"
    }
    if (operator == "60203") {
      productCatalogName = "etisalatcat"
    }
    if (operator == "60204") {
      productCatalogName = "wecat"

    }
    setshowwaiting(false)

    //console.log("operatorcode", operator, "plancode", planCode, "networkcat", productCatalogName, initialPaymentproductId)

    if (window.TPay.HeaderEnrichment.operatorCode() === operator) {

      window.TPay.HeaderEnrichment.hasSubscription(initialPaymentproductId, function (hasSub, subId) {
        if (!hasSub) {
          window.TPay.HeaderEnrichment.confirm(planCode, productCatalogName, initialPaymentproductId, function (result, refNo) {

            if (result == true) {
              setMsisdn(window.TPay.HeaderEnrichment.msisdn())
              const msisdnFormJs = window.TPay.HeaderEnrichment.msisdn();

              const q = queryString.parse(window.location.search)
              TpaySubscribe(msisdnFormJs, refNo, q.SessionToken)

            } else {
              console.log("error is:" + refNo);
            }
          });
        }
        else {
          var subscriptionContractId = window.TPay.HeaderEnrichment.hasSub();
          window.localStorage.setItem('subscriptionContractId', subscriptionContractId);
          setvisablemodalSubscribe(true);

        }
      });
    }




  }

  const verify = async e => {
    e.preventDefault();
    console.log("verify")
    try {
      var subscriptionContractId = localStorage.subscriptionContractId;
      var pinCode = verifyCode;
      var language = localStorage.getItem("i18nextLng");
      const url = "http://www.api.playit.mobi/api/v1/tpay/verify";
      const options = {
        subscriptionContractId,
        pinCode,
        language
      };
      const res = await axios.post(url, options);
      if (res.data) {

        const data = res.data;
        if (res.data.status == true) {
          const GMSISDN = res.data.username;
          setVerfiErrorMesg(false)
          tracker(GMSISDN);
          setvisablemodalverify(true);
          console.log("message : subscribtion successfully");
          console.log("subscribtion successful");
          if (window.location.href.includes('signup6')) {
            localStorage.setItem("routing", "signup6")
          }
          setTimeout(() => {
            setvisablemodalverify(false);
            history.push('/thankyou')

            // window.location.href = "/"
          }, 3000);

          if (res.data.user_id) {
            setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn, res.data.subscriptionContractId);
          }


        } else {
          setVerfiErrorMesg(true)
        }
      }
    } catch (err) {
      //console.log(err);
      setErrorMesg(true)
      setVerfiErrorMesg(true)
      setShowverif(false)
    }
   
  };

  const resend = async e => {
    e.preventDefault();
    //const subscriptionContractId = 223579649
    const subscriptionContractId = localStorage.getItem('subscriptionContractId');

    const options = {
      subscriptionContractId,

    }
    axios.post("http://www.api.playit.mobi/api/v1/tpay/resend", options)
      .then(res => {
        //console.log(res)

      })
      .catch(error => console.log(error))

  }

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = ""

      if (activeData.startsWith("011") || activeData.startsWith("2011")) {
        //if (operatorCode == '60203') {
        op_id = Operators.Tpayegypt011;
      }
      if (activeData.startsWith("012") || activeData.startsWith("2012")) {
        //if (operatorCode == '60201') {
        op_id = Operators.Tpayegypt012;
      }
      if (activeData.startsWith("015") || activeData.startsWith("2015")) {
        //if (operatorCode == '60204') {
        op_id = Operators.Tpayegypt015;
      }
      if (activeData.startsWith("010") || activeData.startsWith("2010")) {
        //if (operatorCode == '60202') {
        op_id = Operators.Tpayegypt010;
      }

      console.log(GMSISDN)
      /*
            if (activeData.startsWith("011") || activeData.startsWith("2011")) {
              op_id = Operators.Tpayegypt011;
            }
            if (activeData.startsWith("012") || activeData.startsWith("2012")) {
              op_id = Operators.Tpayegypt012;
            }
            if (activeData.startsWith("015") || activeData.startsWith("2015")) {
              op_id = Operators.Tpayegypt015;
            }
            if (activeData.startsWith("010") || activeData.startsWith("2010")) {
              op_id = Operators.Tpayegypt010;
            }
            console.log(GMSISDN)
      */
      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };
      console.log(datag)

    }
    await saveTracker(datag);

  }

  // save Enrichment tracker
  const trackerEnrichment = async (GMSISDN, operatorCode) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = ""
      if (operatorCode == "60201") {
        op_id = Operators.Tpayegypt012;
      }
      if (operatorCode == "60202") {
        op_id = Operators.Tpayegypt010;
      }
      if (operatorCode == "60203") {
        op_id = Operators.Tpayegypt011;
      }
      if (operatorCode == "60204") {
        op_id = Operators.Tpayegypt015;
      }

      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };

    }
    await saveTracker(datag);

  }

  const onInputChange = (name, value) => {
    //console.log("val", value)
    if (name == 'productCatalog') {
      setProductCatalog(value);
      const network = networkGamezroid.map((c) => {
        if (c.code == value) {
          setNetworkName(c.name);
          setmessageCode(c.code_message);
          setShowconditionnetwork(true)
        }
      })
    }
    if (name == 'signupPlan') {
      setSignupPlan(value);
      const price = plansGame.map((c) => {
        if (c.code == value) {
          setPlanPrice(c.price);
          setplanName(c.name + ',')
          setPlanCode(c.code)
          setShowconditionplan(true)
        }
      })

      if (enriched == true) {
        const operator = window.TPay.HeaderEnrichment.operatorCode();
        networkGamezroid.map((c) => {
          if (c.code == operator) {
            setNetworkName(c.name);
            setmessageCode(c.code_message);
          }
        })


      }

    }
    if (name == 'activeData') {
      document.getElementById("phone").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
      if (value.slice(0, 4).includes('011')) {
        console.log("pro")
        setProductCatalog('60203');
        setNetworkName('Etisalat');
        setmessageCode('1722');
        setShowconditionnetwork(true)
      }
      else if (value.slice(0, 4).includes('010')) {
        setProductCatalog('60202');
        setNetworkName('Vodafone');
        setmessageCode('2303');
        setShowconditionnetwork(true)

      }
      else if (value.slice(0, 4).includes('012')) {
        setProductCatalog('60201');
        setNetworkName('Orange');
        setmessageCode('5030');
        setShowconditionnetwork(true)

      }
      else if (value.slice(0, 4).includes('015')) {
        setProductCatalog('60204');
        setNetworkName('We');
        setmessageCode('4041');
        setShowconditionnetwork(true)

      }
      var startphoneGamesroid = value.startsWith('2');
      if (!startphoneGamesroid && value.slice(0, 4).includes('011') || !startphoneGamesroid && value.slice(0, 4).includes('012') || !startphoneGamesroid && value.slice(0, 4).includes('015')
        || !startphoneGamesroid && value.slice(0, 4).includes('010')) {
        value = '2' + value;
      }
      setActiveData(value);
    }

    if (name == 'verifycode') {
      document.getElementById("verifyInput").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
      setVerifyCode(value)
    }
    // this.setState({
    //   [name]: value,
    //   errors: false
    // });
  }

  return (
    <RamdankGamesroidSignupPageDisplay className="" >
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFYERROR</Trans>
        </FloatingAlert>
      ) : null}


      {loading && !startpage && !showwaiting ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div> : ""}



      {showwaiting && enriched && !startpage ?
        <>
          <div className="container-loadaing waiting-loading">
            <div className="loadingLayout">
              <div className="keep-waiting">
                <div>


                  <div className="sk-chase">

                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                  </div>
                </div>
                <div>
                  <p><Trans>waiting</Trans></p>

                  {/* <h1>برجاء الانتظار</h1> */}
                </div>
              </div>


            </div>

          </div>
        </>
        : ""}


      <div className="operatorSignup operatorSignupGamez operatorramdankSignup">
        <div className="formsLayout formsLayoutgamezorid formsLayoutramdankgamezorid">
          <div className="ramdankheader">
            <Link to="/">
              <div className="logogamezroid logo" ></div>
            </Link>
          </div>
          {localStorage.getItem('showdouble') == 'true' ?
            <div className="globalcoins" style={{ top: '19%' }}>
              <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
            </div>
            : ""}



          {startpage ?
            <>
              <div className="links-Section links-SectionGamezorid links-Sectionramdan">

                {showPlay ?
                  <>
                    <div style={{ marginTop: '40px' }}>
                      <img src="./assets/images/HomepagecomGamezroid/r.png" />
                    </div>
                    <div className="ramdankplay">
                      <img
                        src={localStorage.getItem('i18nextLng') == 'ar' ? "./assets/images/HomepagecomGamezroid/Main-Banner.png" : "./assets/images/HomepagecomGamezroid/Main-Banner-en.png"}


                        width="366" height="170" />
                    </div>
                  </>
                  : ""}


                <button
                  id="signup"
                  className="btn gamezroid btn_gamezroid ramdankbtn" onClick={e => {
                    checkheaderenriched(e);
                  }}
                >
                  <Trans>Subscribe_gamezroid</Trans>
                </button>

                <Link to="/login">
                  <p className="textLogin"><Trans>have_account</Trans></p>
                </Link>


                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span><Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>



                <div className="row text-center" >
                  <div className="col-xl-3 col-lg-3 col-3 px-5">
                    <div className="games-info ramdankgames-info">
                      <img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                      <p>98%</p>
                    </div>
                    <p className="ramdankgame_text"><Trans>evaluation</Trans></p>

                  </div>

                  <div className="col-xl-1 col-lg-1 col-1 px-1">
                    <div className="arrow">
                      <img src="/assets/images/subscribegamezriod/arrow.svg" alt="img" className="iconsimg" />
                    </div>
                  </div>


                  <div className="col-xl-3 col-lg-3 col-3 px-5">
                    <div className="games-info ramdankgames-info">
                      <img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                      <p>1000+</p>

                    </div>
                    <p className="ramdankgame_text"><Trans>game</Trans></p>
                  </div>

                  <div className="col-xl-1 col-lg-1 col-1 px-1">
                    <div className="arrow">
                      <img src="/assets/images/subscribegamezriod/arrow.svg" alt="img" className="iconsimg" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3 px-5">
                    <div className="games-info ramdankgames-info">
                      <img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                      <p>500k+</p>

                    </div>
                    <p className="ramdankgame_text"><Trans>user</Trans></p>
                  </div>
                </div>

                <div className="footer" style={{ marginBottom: '25px' }}>
                  <div className="ncellTos">

                    <div>
                      <p className="decilimer">
                        <Trans>part1</Trans> <span className="fixedprice"><Trans>price</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>


                        
                      </p>
                    
                    </div>
                  </div>
                </div>
              </div>
            </>
            : ""}


          {enriched && !showverif ?
            <div className="links-Section links-SectionGamezorid">
              {/* <h3 style={{ color: whiteStyle ? 'black' : '#fff' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3> */}

              {showPlay ?
                <>
                  <div style={{ marginTop: '40px' }}>
                    <img src="./assets/images/HomepagecomGamezroid/r.png" />
                  </div>
                  <div className="ramdankplay">
                    <img
                      src={localStorage.getItem('i18nextLng') == 'ar' ? "./assets/images/HomepagecomGamezroid/Main-Banner.png" : "./assets/images/HomepagecomGamezroid/Main-Banner-en.png"}

                      width="366" height="170" />
                  </div>
                </>
                : ""}

              <p
                style={{ color: whiteStyle ? 'black' : '#fff' }}
                className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span><Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
              <p className="freesubscribe freesubscribbe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup61") || window.location.href.includes("signup51") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>

              <div className="footer" style={{ marginBottom: '25px' }}>
                <div className="ncellTos">

                </div>


                <div>
                  <p
                    style={{ color: whiteStyle ? 'black' : '#fff' }}
                    className="decilimer">
                    <Trans>you will subscribe in gamezroid for</Trans> <span className="fixedprice">{planPrice} <Trans>Egyptian pound</Trans></span><Trans>{planName}</Trans> <Trans>to cancel your subscription for</Trans> <Trans>{networkName}</Trans> <Trans>Egypt subscribers please send 'Unsub GRD' to</Trans> {messageCode} <Trans>for free, To cancel from the site please go to</Trans>
                    <Link to="/unsubscribe" className="decilimer"> <Trans>here</Trans></Link>
                  </p>
                </div>

              </div>



            </div>

            : ""}
          {/* noenriched && !showverif && !enriched && !startpage && !loading */}

          {noenriched && !showverif && !enriched && !startpage && !loading ?
            <div className="links-Section links-SectionGamezorid">
              {/* <h3 style={{ paddingBottom: '1px' }}
                style={{ color: whiteStyle ? 'black' : '#fff' }}
                className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3> */}

              {showPlay ?
                <>
                  <div style={{ marginTop: '40px' }}>
                    <img src="./assets/images/HomepagecomGamezroid/r.png" />
                  </div>
                  <div className="ramdankplay">
                    <img
                      src={localStorage.getItem('i18nextLng') == 'ar' ? "./assets/images/HomepagecomGamezroid/Main-Banner.png" : "./assets/images/HomepagecomGamezroid/Main-Banner-en.png"}

                      width="366" height="170" />
                  </div>
                </>
                : ""}



              <div className="iconInput iconInputGame iconinputphone">
                <i className="icon phone" />
                <input
                  type="number"
                  className="input"
                  id="phone"
                  placeholder={t("enter_phone_number_gamezorid")}
                  style={{ background: whiteStyle ? 'black' : '#fff', color: whiteStyle ? " #fff" : "black" }}
                  onChange={e => onInputChange("activeData", e.target.value)}
                />
              </div>

              <button
                id="signup"
                className="btn gamezroid btn_gamezroid ramdankbtn" onClick={e => {
                  Subscribe(e);
                }}
              >
                <Trans>Subscribe_gamezroid</Trans>
              </button>

              <Link to="/login">
                <p className="textLogin"><Trans>have_account</Trans></p>
              </Link>

              <p
                style={{ color: whiteStyle ? 'black' : '#fff' }}
                className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span><Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
              <p className="freesubscribe freesubscribbe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup51") || window.location.href.includes("signup61") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>



              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info ramdankgames-info">
                    <img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p>98%</p>
                  </div>
                  <p className="ramdankgame_text"><Trans>evaluation</Trans></p>

                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info ramdankgames-info">
                    <img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p>1000+</p>

                  </div>
                  <p className="ramdankgame_text"><Trans>game</Trans></p>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info ramdankgames-info">
                    <img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p>500k+</p>

                  </div>
                  <p className="ramdankgame_text"><Trans>user</Trans></p>
                </div>
              </div>


              {showtimer ?
                <div className="timer">
                  <p><Trans>Please try agin after 2 minutes</Trans></p>

                  <p><Trans>Countdown</Trans>: <p className="Countdown">{minutes} : {counter}</p></p>
                </div> : null}

              <div className="footer" style={{ marginBottom: '25px' }}>
                <div className="ncellTos">

                  {!showconditionnetwork ?
                    <div>
                      <p
                        style={{ color: whiteStyle ? 'black' : '#fff' }}
                        className="decilimer">
                        <Trans>part1</Trans> <span className="fixedprice"><Trans>price</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>
                      </p>


                   




                    </div>
                    : ""}

                  {!activeData && showconditionnetwork ?
                    <div>
                      <p className="decilimer"
                        style={{ color: whiteStyle ? 'black' : '#fff' }}>
                        <Trans>part1</Trans> <span className="fixedprice"><Trans>price</Trans></span>. <Trans>part2</Trans> <Trans>part3</Trans>


                      </p>

                    </div>
                    : ""}


                  {showconditionnetwork && activeData ?
                    <div>
                      <p className="decilimer"
                        style={{ color: whiteStyle ? 'black' : '#fff' }}>
                        <Trans>you will subscribe in gamezroid for</Trans> <span className="fixedprice">{planPrice} <Trans>Egyptian pound</Trans></span> <Trans>{planName}</Trans> <Trans>to cancel your subscription for</Trans> <Trans>{networkName}</Trans> <Trans>Egypt subscribers please send 'Unsub GRD' to</Trans> {messageCode} <Trans>for free, To cancel from the site please go to</Trans>
                        <Link to="/unsubscribe" className="decilimer"> <Trans>here</Trans></Link>
                      </p>
                    </div>
                    : null}



                </div>
              </div>

            </div>

            : ""}




          <div className="center">
            {showverif ?
              <div className="">
                <>
                  <div style={{ marginTop: '40px' }}>
                    <img src="./assets/images/HomepagecomGamezroid/r.png" />
                  </div>
                  <div className="ramdankplay">
                    <img
                      src={localStorage.getItem('i18nextLng') == 'ar' ? "./assets/images/HomepagecomGamezroid/Main-Banner.png" : "./assets/images/HomepagecomGamezroid/Main-Banner-en.png"}

                      width="366" height="170" />
                  </div>
                </>

                <p><Trans>verifycode_signup</Trans></p>
                <input
                  style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup61") || window.location.href.includes("signup51") ? "black" : "white" }}
                  type="number"
                  className="input ramdanverify"
                  value={verifyCode}
                  id="verifyInput"
                  maxLength='6'
                  placeholder={t("Enter_verifycode")}
                  onChange={e => onInputChange("verifycode", e.target.value)}
                />
                <br></br>
                <p className="freesubscribe"><Trans>SUBSCRIBEGAMEZORIDE.FREE</Trans> <span><Trans>SUBSCRIBEGAMEZORIDE.PRICE</Trans></span> <Trans>SUBSCRIBEGAMEZORIDE.AUTO</Trans></p>
                <p className="freesubscribe" style={{ color: window.location.href.includes("signup21") || window.location.href.includes("signup51") || window.location.href.includes("signup61") ? "black" : "white" }}><Trans>SUBSCRIBEGAMEZORIDE.INTERNET</Trans></p>
                <button
                  className="btn gamezroidverify"
                  disabled={verifyCode ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }} >
                  {t("Verify Subscribtion")}
                </button>
                <br></br>
                <button
                  className="btn resend gamezroidverify"

                  onClick={(e) => {
                    resend(e);
                  }} >
                  {t("Resend PIN code")}
                </button>

              </div> : null
            }


          </div>

          <div>

          </div>





          <Modal
            visible={visablemodalSubscribe}
            width="450"
            height="150"
            effect="fadeInUp"
            padding="0"
            onClickAway={() => closeModalSubscribe()}
          >
            <div className="subscribemodal">
              <h3>
                <Trans>Already subscribed</Trans>
              </h3>
              <p><Trans>You want to send data</Trans> ?</p>
              <button
                className="btn"
                onClick={(e) => {
                  sendData(e);
                }} >
                {t("Send")}
              </button>
            </div>


          </Modal>


          <Modal
            visible={visablemodalverify}
            width="450"
            height="150"
            effect="fadeInUp"
            padding="0"
            onClickAway={() => closeModalverify()}
          >
            <div className="verifymodal">
              <h3>
                <Trans>Successful Subscribtion ramdank</Trans>
              </h3>
              <div className="congrut"></div>
            </div>


          </Modal>



        </div>

      </div>
    </RamdankGamesroidSignupPageDisplay>
  );

}
export default withTranslation()(RamdankGamesroidSignupPage)
