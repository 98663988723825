import React, { useEffect, useState, setState } from "react";
import { Link } from "react-router-dom";
import { setLoginData } from "../../../helpers/helpers";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, callTrackerCompany, saveTracker, logtracker } from "../../../helpers/agencyTracker";
import "./EthSignupPage.scss";
import EthSignupPageDisplay from "./EthSignupPage.styled";
import { getHeaderethio, getPhoneByHeader, signup } from "./services";
import Operators from "../../operators.json";
import queryString from "query-string";
import Modal from 'react-awesome-modal';
import { Trans, withTranslation } from "react-i18next";


const randomBg = bgSliders.getRandomBg();

export default function EthSignupPage(props) {
  const [activeData, setActiveData] = useState("");
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [signedUp, setSignedUp] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [checkSend, setCheckSend] = useState(false);
  const [theme, setTheme] = useState(false);


  const [lang, setLang] = useState("");
  const url = window.location.href
  if (props.location.search !== "") {
    setAgencyData(props.location.search);
  }
  const openModal = () => { setVisible(true) };
  const closeModal = () => { setVisible(false) };
  useEffect(() => {

    if (window.location.href.includes("signup2")) {
      console.log("task")
      setTheme(true);
    }

    async function fetchit() {
      var linkheader = "http://ethio.playit.mobi/checkheaderdemo.php";
      if (props.location.search !== "") {
        var curr_search = props.location.search;
        const data = queryString.parse(curr_search);
        if (data.test_grand == "yousry" || data.test_grand == "najeeb") {
          linkheader = "http://ethio.playit.mobi/checkheader3.php";
        }
        if (data.test_grand == "ethiot") {
          linkheader = "http://ethio.playit.mobi/checkheaderdemo.php?testy=ethiot";
        }

        if (localStorage.getItem('Modal2') === '1') {
        } else if (url.includes('ethio') && data.camp === "true") {
          openModal()
          setTimeout(localStorage.setItem('Modal2', 1), 300)
        }

      }
      const num = await getPhoneByHeader(linkheader);
      var phone_h = num.msisdn;
      var count_h = num.count;
      var services_h = num.services;

      console.log('resdata', phone_h);
      console.log('resdata', count_h);
      console.log('resdata', services_h);


      if (phone_h == "") {
        setAlert(true);
      } else if (count_h >= 2 && !services_h.includes("playit")) {
        //        console.log("000000000000");
        toggleAlert("info", true, "This Service Not available now");
      }
      else if ((phone_h != "" && count_h < 2) || (count_h >= 2 && services_h.includes("playit"))) {
        setActiveData(phone_h);
        //        console.log("11111111111");
        console.log(phone_h);
        console.log(activeData);
        var bodyFormData = new FormData();
        //aya
        bodyFormData.set('phone', phone_h);
        const trackerCompany = localStorage.getItem("trackerCompany");
        const tracker = localStorage.getItem("tracker");

        if (trackerCompany !== "Propellar" && trackerCompany !== "Rich" && trackerCompany !== "Mobidea") {
          bodyFormData.set('trackerCompany', trackerCompany);
          bodyFormData.set('tracker', tracker);
          await getHeaderethio(bodyFormData);
        }
        //await delay(1000);
      } else {
        setAlert(true);
        console.log(phone_h);
        console.log(activeData);
      }

      // const trackerCompany = localStorage.getItem("trackerCompany");
      // const tracker = localStorage.getItem("tracker");
      // await getPostBack(activeData, trackerCompany, tracker);
    }
    fetchit();
  }, []);

  const clicked = e => {
    e.preventDefault();
    //   setSignedUp(true);
    var trackerCompany = localStorage.getItem('trackerCompany');
    var tracker = localStorage.getItem('tracker');
    logtracker(trackerCompany, tracker);
    console.log(activeData);
    if (activeData == "") {
      trackerfn('251999999');
    } else {
      trackerfn(activeData);
    }

    setCheckSend(true);
    window.location.href = 'sms:6040;?&body=Ok';

  };

  // save tracker
  const trackerfn = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: Operators.ethio,
        phoneNum: GMSISDN
      };
      console.log(datag)

    }
    await saveTracker(datag);

  }

  const toggleAlert = (type, status, msg) => {
    setAlertMsg(msg);
    setAlertType(type);
    setAlertStatus(status);
  };

  const signMeUp = async e => {
    e.preventDefault();
    var cp_var = false;
    if (props.location.search !== "") {
      var q_str = queryString.parse(props.location.search);
      if (q_str.cp) {
        console.log(q_str.cp);
        console.log(typeof q_str.cp);

        if (q_str.cp == "true") {
          cp_var = true;
        }

      }

    }
    const trackerCom = localStorage.getItem("trackerCompany");
    const trackerValue = localStorage.getItem("tracker");
    if (trackerCom && trackerCom != "" && trackerCom != "undefined") {
      if (trackerValue && trackerValue != "" && trackerValue != "undefined") {
        cp_var = true;
      }
    }
    const res = await signup(activeData, cp_var);
    if (res) {
      if (localStorage.getItem('tracker')) {
        switch (localStorage.getItem("trackerCompany")) {
          case 'OlimobCPA':
            var data = {
              key: 'eoliclick',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'digiTantra':
            var data = {
              key: 'erid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'krayons':
            var data = {
              key: 'kcclick',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'youmi':
            var data = {
              key: 'yerid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'trafficCompany':

            var data = {
              'key': 'etracker',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'ArmorAds':
            var data = {
              key: 'earmclick_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          case 'MOBIPIUM':
            var data = {
              key: 'merid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };

            break;
          case 'Propellar':
            var data = {
              key: 'visitor_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };

            break;
          case 'Mobidea':
            var data = {
              key: 'click_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };

            break;
          case 'Rich':
            var data = {
              key: 'key',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: Operators.ethio,
              phoneNum: localStorage.getItem('phoneNum')
            };
            break;
          default:
            break;
        }

        await saveTracker(data);

        setWelcome(true)
        //props.history.push("/");    
      } else {
        // props.history.push("/");
        setWelcome(true)
      }
    }

  };


  const toggleLang = async e => {
    e.preventDefault();
    let langBtn = document.querySelector(".lang").innerHTML;
    langBtn === "English" ? setLang("en") : setLang("amh");
  };
  return (
    <EthSignupPageDisplay className="" bgImg={theme ? "" : randomBg}>

      {welcome ? (
        <>
          <div className="welcomePage">
            <div className="logo"></div>
            <div className="LetsGo" />
            <p className="t1">THANK YOU</p>
            <p className="t2">SUBSCRIPTION COMPLETED</p>
            <Link to="/">  <p className="t3">LET'S PLAY</p> </Link>
          </div>
        </>
      ) : null}

      <Modal
        visible={visible}
        width="300px"
        height="300px"
        effect="fadeInUp"
        padding="0"
        onClickAway={() => closeModal()}>
        <div className="homeModel">
          <a href="javascript:void(0);" onClick={() => closeModal()}> X </a>
        </div>
      </Modal>


      {/* {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn on your mobile data.
        </FloatingAlert>
      )} */}


      {!welcome && !theme ? (
        < div className="operatorSignup">
          <div className="formsLayout">
            {lang === "en" ?
              <a className="lang" onClick={e => toggleLang(e)} href=" ">
                አም
            </a>
              :
              <a className="lang" onClick={e => toggleLang(e)} href=" ">
                English
            </a>
            }
            <div className="header">
              <Link to="/">
                <div className="logo" />
              </Link>
            </div>
            <div className="center">
              {signedUp ? (
                <>

                  <button
                    className="btn"
                    onClick={e => {
                      signMeUp(e);
                    }}
                  >
                    Confirm
                </button>

                </>
              ) : (
                <form>
                  {/* <div className="inputs">
                    <select className="select">
                      <option value="">ethio (+251)</option>
                    </select>
                    <input
                      type="text"
                      className="input"
                      placeholder="enter your phone number"
                      value={activeData}
                      disabled= "disabled"
                      onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                    />
                  </div>
                   */}

                  <div>
                    {lang === "en" ?
                      <h3 className="feature"> በ 3 ቀን ነጻ ኣገልግሎት ይዝናኑ </h3>

                      :
                      <h3 className="feature"> Enjoy 3 Days Free</h3>
                    }

                    {lang === "en" ?
                      <h3 className="feature"> Enjoy 3 Days Free</h3>
                      :
                      <h3 className="feature"> በ 3 ቀን ነጻ ኣገልግሎት ይዝናኑ </h3>
                    }


                    {/* <button 
                      className="btn"
                      disabled={activeData ? "" : "disabled"}
                      onClick={e => {
                        clicked(e);
                      }}
                    > */}
                    < button
                      className="btn"
                      disabled={checkSend == true}
                      onClick={e => {
                        clicked(e);
                      }}
                    >
                      {lang === "en" ?
                        'Signup'
                        :
                        'ይመዝገቡ '
                      }

                      {lang === "en" ?
                        '   ይመዝገቡ '
                        :
                        '   Signup'
                      }

                    </button>


                    {lang === "en" ?
                      <>
                        <h4>
                          ከዚህ በፊት ተመዝግበዋል ? <Link to="login">ይግቡ</Link>
                        </h4>
                        <h4>
                          if you already subscribe? <Link to="login">Sign in</Link>
                        </h4>

                      </>

                      : (
                        <>
                          <h4>
                            if you already subscribe? <Link to="login">Sign in</Link>
                          </h4>
                          <h4>
                            ከዚህ በፊት ተመዝግበዋል ? <Link to="login">ይግቡ</Link>
                          </h4>

                        </>
                      )
                    }


                  </div>

                  {/* {
                lang === "en" ?
                  < div className="ethioTos">
                                       መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div> :

                  <div className="ethioTos">
                     PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div>
              } */}
                  {/* {
                lang === "en" ?
                  < div className="ethioTos">
                    PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div> :

                  <div className="ethioTos">
                    መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
              } */}
                </form>
              )}
            </div>
            <div id="camp_ethio_plyait" ></div>
            <br></br>
            <div className="footer" style={{ width: '100%' }}>
              {lang === "en" ?
                <>
                  <div className="ethioTos">
                    መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
                  <p>መመሪያ እና ኣተገባበር </p>
                  <div className="list">
                    {feats.map((i, k) => {
                      return (
                        <div key={`feat-${k}`} className="elm">
                          <i className="feature-icon"></i>
                          <span>{i.amh}</span>
                        </div>
                      );
                    })}

                  </div>
                  < div className="ethioTos">
                    PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div>
                  <p>Terms and Conditions</p>
                  <div className="list">
                    {feats.map((i, k) => {
                      return (
                        <div key={`feat-${k}`} className="elm">
                          <i className="feature-icon"></i>
                          <span>{i.en}</span>
                        </div>

                      );
                    })}

                  </div>
                </>
                :
                <>
                  < div className="ethioTos">
                    PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div>
                  <p>Terms and Conditions</p>
                  <div className="list">
                    {feats.map((i, k) => {
                      return (
                        <div key={`feat-${k}`} className="elm">
                          <i className="feature-icon"></i>
                          <span>{i.en}</span>
                        </div>
                      );
                    })}

                  </div>
                  <div className="ethioTos">
                    መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
                  <p>መመሪያ እና ኣተገባበር </p>
                  <div className="list">
                    {feats.map((i, k) => {
                      return (
                        <div key={`feat-${k}`} className="elm">
                          <i className="feature-icon"></i>
                          <span>{i.amh}</span>
                        </div>
                      );
                    })}

                  </div>
                </>
              }
              {/* <div className="list">
              {feats.map((i, k) => {
                return (
                  <div key={`feat-${k}`} className="elm">

                    {lang === "en" ?
                      <>
                        <i className="feature-icon"></i>
                        <span>{i.en}</span>
                      </> :
                      <>
                        {i.amh === '' ? false : <i className="feature-icon"></i>}
                        <span>{i.amh}</span>
                      </>
                    }
                  </div>
                );
              })}
              {feats.map((i, k) => {
                return (
                  <div key={`feat-${k}`} className="elm">

                    {lang === "en" ?
                      <>
                    {i.amh === '' ? false : <i className="feature-icon"></i>}
                        <span>{i.amh}</span>
                      </> :
                      <>
                      <i className="feature-icon"></i>
                      <span>{i.en=='' ? false : i.en}</span>

                      </>
                    }
                  </div>
                );
              })}
            </div> */}

            </div>

          </div>

          {alertStatus && (
            <FloatingAlert type={alertType}>{alertMsg}</FloatingAlert>
          )}
        </div>
      ) : null
      }

      {!welcome && theme ? (
        <>

          <div className="operatorSignupehtio">
            <div className="formsLayout formsLayoutethio">
              <div className="header header_ethio">
                <Link to="/">
                  <div className="logogamezroid logo" ></div>
                </Link>
              </div>

              <div className="links-Section links-SectionEthio">
                <div className="Contentlang">
                  {lang === "en" ?
                    <a className="lang" onClick={e => toggleLang(e)} href=" ">
                      አም
            </a>
                    :
                    <a className="lang" onClick={e => toggleLang(e)} href=" ">
                      English
            </a>
                  }

                </div>



                <div style={{ paddingBottom: '1px' , direction: 'ltr'}} className="gamezoridcontent" id="gamezoridcon">
                  {lang === "en" ?
                    <>
                      <h3>"የእርስዎ ይዘት ዝግጁ ነው!"</h3>
                      <h3> "Your content is ready!" </h3>

                    </>
                    :
                    <>
                      <h3> "Your content is ready!" </h3>
                      <h3>"የእርስዎ ይዘት ዝግጁ ነው!"</h3>

                    </>
                  }
                </div>

                <div className="play"></div>

                <div className="center">
                  {signedUp ? (
                    <>

                      <button
                        className="btn"
                        onClick={e => {
                          signMeUp(e);
                        }}
                      >
                        Confirm
                </button>

                    </>
                  ) : (
                    <form>
                     

                      <div>
                        {lang === "en" ?
                          <h3 className="feature"> በ 3 ቀን ነጻ ኣገልግሎት ይዝናኑ </h3>

                          :
                          <h3 className="feature"> Enjoy 3 Days Free</h3>
                        }

                        {lang === "en" ?
                          <h3 className="feature"> Enjoy 3 Days Free</h3>
                          :
                          <h3 className="feature"> በ 3 ቀን ነጻ ኣገልግሎት ይዝናኑ </h3>
                        }


                  
                        < button
                          className="btn"
                          disabled={checkSend == true}
                          onClick={e => {
                            clicked(e);
                          }}
                        >
                          {lang === "en" ?
                            'Signup'
                            :
                            'ይመዝገቡ '
                          }

                          {lang === "en" ?
                            '   ይመዝገቡ '
                            :
                            '   Signup'
                          }

                        </button>


                        {lang === "en" ?
                          <>
                            <h4>
                              ከዚህ በፊት ተመዝግበዋል ? <Link to="login">ይግቡ</Link>
                            </h4>
                            <h4>
                              if you already subscribe? <Link to="login">Sign in</Link>
                            </h4>

                          </>

                          : (
                            <>
                              <h4>
                                if you already subscribe? <Link to="login">Sign in</Link>
                              </h4>
                              <h4>
                                ከዚህ በፊት ተመዝግበዋል ? <Link to="login">ይግቡ</Link>
                              </h4>

                            </>
                          )
                        }


                      </div>

                      {/* {
                lang === "en" ?
                  < div className="ethioTos">
                                       መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div> :

                  <div className="ethioTos">
                     PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div>
              } */}
                      {/* {
                lang === "en" ?
                  < div className="ethioTos">
                    PlayIT gaming club is daily subscription service
                    with-automatic renewal , for unlimited access for more than
                    1000+ games, subscribe now for 2 birr per day.By signing up,
                    you agree with our Terms & Conditions.
                      </div> :

                  <div className="ethioTos">
                    መመሪያ እና ኣተገባበር
                    ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
              } */}
                    </form>
                  )}
                </div>
                <div id="camp_ethio_plyait" ></div>
                <br></br>
                <div className="footer" style={{ width: '100%' }}>
                  {lang === "en" ?
                    <>
                      <div className="ethioTos">
                        መመሪያ እና ኣተገባበር
                        ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
                      <p>መመሪያ እና ኣተገባበር </p>
                      <div className="list">
                        {feats.map((i, k) => {
                          return (
                            <div key={`feat-${k}`} className="elm">
                              <i className="feature-icon"></i>
                              <span>{i.amh}</span>
                            </div>
                          );
                        })}

                      </div>
                      < div className="ethioTos">
                        PlayIT gaming club is daily subscription service
                        with-automatic renewal , for unlimited access for more than
                        1000+ games, subscribe now for 2 birr per day.By signing up,
                        you agree with our Terms & Conditions.
                      </div>
                      <p>Terms and Conditions</p>
                      <div className="list">
                        {feats.map((i, k) => {
                          return (
                            <div key={`feat-${k}`} className="elm">
                              <i className="feature-icon"></i>
                              <span>{i.en}</span>
                            </div>

                          );
                        })}

                      </div>
                    </>
                    :
                    <>
                      < div className="ethioTos">
                        PlayIT gaming club is daily subscription service
                        with-automatic renewal , for unlimited access for more than
                        1000+ games, subscribe now for 2 birr per day.By signing up,
                        you agree with our Terms & Conditions.
                      </div>
                      <p>Terms and Conditions</p>
                      <div className="list">
                        {feats.map((i, k) => {
                          return (
                            <div key={`feat-${k}`} className="elm">
                              <i className="feature-icon"></i>
                              <span>{i.en}</span>
                            </div>
                          );
                        })}

                      </div>
                      <div className="ethioTos">
                        መመሪያ እና ኣተገባበር
                        ፕለይኢት ጌሚንግ ክለብ በየቀኑ ምዝገባ የሚያስፈልገው፣ ኣዉቶማቲክ እደሳ ያለው ኣገልግሎት ነው። ከ1000 በላይ የሆኑ ጌሞችን ያለ ገደብ ለመጫወት በቀን ሁለት ብር በመክፈል ይመዝገቡ። ኣገልግሎቱን በመጠቀም በመመሪያ እና ኣተገባበሩ ተስማምተዋል።
                      </div>
                      <p>መመሪያ እና ኣተገባበር </p>
                      <div className="list">
                        {feats.map((i, k) => {
                          return (
                            <div key={`feat-${k}`} className="elm">
                              <i className="feature-icon"></i>
                              <span>{i.amh}</span>
                            </div>
                          );
                        })}

                      </div>
                    </>
                  }
                  {/* <div className="list">
              {feats.map((i, k) => {
                return (
                  <div key={`feat-${k}`} className="elm">

                    {lang === "en" ?
                      <>
                        <i className="feature-icon"></i>
                        <span>{i.en}</span>
                      </> :
                      <>
                        {i.amh === '' ? false : <i className="feature-icon"></i>}
                        <span>{i.amh}</span>
                      </>
                    }
                  </div>
                );
              })}
              {feats.map((i, k) => {
                return (
                  <div key={`feat-${k}`} className="elm">

                    {lang === "en" ?
                      <>
                    {i.amh === '' ? false : <i className="feature-icon"></i>}
                        <span>{i.amh}</span>
                      </> :
                      <>
                      <i className="feature-icon"></i>
                      <span>{i.en=='' ? false : i.en}</span>

                      </>
                    }
                  </div>
                );
              })}
            </div> */}

                </div>

              </div>



            </div>
          </div>




        </>
      ) : null}

    </EthSignupPageDisplay >

  );
}
const feats = [
  {
    en: "No Ads",
    amh: "ሁሉንም መጫወት ይችላሉ"
  },
  {
    en: "Best casual games",
    amh: "ማስታወቂያ የላቸዉም"
  },
  {
    en: "New Mobile games",
    amh: "የሁልጊዜ ምርጥ ጌሞች"
  },
  {
    en: "Full-version games",
    amh: "ኣስተማማኝ መዝናኛ"
  },
  {
    en: "All you can play",
    amh: "ኣዳዲስ ጌሞች"
  },
  {
    en: "Premium games",
    amh: "የተሟሉ ጌሞች"
  },
  {
    en: "Guaranteed fun",
    amh: "የተረጋገጠ ደስታ"
  },
  {
    en: "Enjoy 3 free days",
    amh: "በ 3 ቀን ነጻ ኣገልግሎት ይዝናኑ"
  },
  {
    en: "No in-app purchases",
    amh: "የውስጠ-መተግበሪያ ግዢዎች የሉም"
  }
];
