import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import JawwalSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getRedirectUrl, subscribe } from "./services";
const plans = [
  {
    plan: "d",
    name: "Daily"
  },
  {
    plan: "w",
    name: "Weekly"
  },
  {
    plan: "m",
    name: "Monthly"
  }
];

const randomBg = bgSliders.getRandomBg();
function JawwalSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [signupPlan, setSignupPlan] = useState("d");
  const urljawwal = window.location.hostname.includes("jawwal");

  const APImsisdn = {
    bhmsisdn: ''
  }

  const [alert, setAlert] = useState(false);
  useEffect(() => {
  }, []);


 
  const signup = async e => {
    e.preventDefault();
    if (activeData) {
      axios.get(`http://www.api.playit.mobi/api/v2/jawwal/sendPincode?msisdn=${activeData}`)
        .then(res => {
          //console.log(res);
          if (res.data.status == "Success") {
            setShowverif(true)
            setErrorMesg(false)
          }else{
            setErrorMesg(true)
            setShowverif(false)  
          }

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
          console.log('complete');
        });


    }
  };
  const verify = async e => {
    e.preventDefault();
    if (activeData) {
      axios.get(`http://www.api.playit.mobi/api/v2/jawwal/verifyPincode?msisdn=${activeData}&pincode=${verifyCode}`)
        .then(res => {
          //console.log(res);
          //console.log(res['status']);

          if (res.data.status == "Success") {
            setVerfiErrorMesg(false)
            // if (res.data.userId) {
            //   setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            //   window.location.href = "/";
            // }
            window.location.href = "/";
          } else {
            setVerfiErrorMesg(true)
          }
        })
        .catch(function (error) {
          console.log(error);
          setVerfiErrorMesg(true)
        })
        .finally(function () {
          console.log('complete');
        });
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
       {c.name}
      </option>
    );
  });

  return (
    <JawwalSignupPageDisplay className="" bgImg={randomBg}>
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup">
        <div className="formsLayout">
          <div className="header">
            <Link to="/">
              <div className="logo" />
            </Link>
          </div>

              <div className="center">
                <form>
                  {!showverif ? 
                  <div>
                  <div className="inputs">
                    <select className="select">
                      <option value="">{t("Palestine")} </option>
                    </select>
                    <input
                      type="text"
                      className="input"
                      placeholder={t("Enter_your_phone_number")}
                      value={activeData}
                      onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                    />
                  </div>
                     </div> : null}
               

                  {showverif ?
                    <div className="">
                      <p><Trans>verifycode</Trans></p>
                      <input
                        type="text"
                        className="input"
                        placeholder={t("Enter_verifycode")}
                        onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

                      />
                    </div> : null
                  }

                </form>
              </div>
              <div className="footer">
                {!showverif ? <div>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      signup(e);
                    }}
                  >
                    {t("Signup")}
                  </button>
                </div> : null}
                {showverif ? <div>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      verify(e);
                    }}
                  >
                    {t("Verify")}
                  </button>
                </div> : null}

                <div className="ncellTos">
                  <h3><Trans>terms_and_conditions</Trans></h3>

                  <p><Trans>JAWWALSUBSCRIBE_DAILY</Trans></p>
                  <p> <Trans>JAWWALSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>JAWWALUNSUBSCRIBE</Trans> </p>


                </div>
              </div>

        </div>
      </div>

    </JawwalSignupPageDisplay>
  );
}
export default withTranslation()(JawwalSignupPage)
