import axios from "axios";
export async function checkdouble(){ 
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)
  
    const options = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkdoublepoints';
    try {
      const res = await axios.post(apiUrl, options);
      if (res.data) {
        const data = res.data;
        if (data.status == false) 
        {
          return false;
        }
        else
        {
        return true;
        }
      }
    
    } catch (err) {
      console.log(err)
  
    }
  }

  export async function checkRenewal(){ 
    let timestamp = new Date().getTime();
    localStorage.setItem("day" , new Date().getDate());
    localStorage.setItem("month" , new Date().getMonth() + 1);
    console.log("data" , new Date().getDate() , new Date().getMonth() + 1)
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password);
    //localStorage.setItem("showrenewal" , 'true')

  
    const options = {
      userId: localStorage.getItem('userId'),
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkRenewal';
    try {
      const res = await axios.post(apiUrl, options);
      console.log(res)
      if (res.data) {
        const data = res.data;
        if (data.status == false) 
        {
          return false;
        }
        else
        {
          return true;
        }

      }

    
    } catch (err) {
      console.log(err)
  
    }
  }

