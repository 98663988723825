import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Trans, withTranslation } from "react-i18next";
import "./WinnersPage.scss";
import WinnerssDisplay, { Rank } from "./WinnersPage.styled";
import { useHistory } from "react-router";
import { client } from "../../auth/Client";


class WinnersPage extends Component {
  constructor() {
    super();
    this.state = {
      ranks: [],
      loading: false,
      noRanking: false,
      currentUser: {},
			showUser: false,
      errors: false,
      errorMsg: ""
    };
  }

  componentDidMount() {
    this.checkAuth();

  }

  checkAuth = async () => {

    const authorized = await client.auth();
    if (!authorized) {
      this.props.history.push("/login");

    }
    else {
      this.getAnonLeaderboard();
    }

  }
  async getAnonLeaderboard() {

    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)
    const options = {
      userId: localStorage.getItem('userId'),
      //userId: "H1iTe38fEF",
      timestamp,
      authcode
    };
    const apiUrl = "http://api.playit.mobi/api/v1/tpay/pointsLeaderboard"
    try {
      this.setState({ loading: true });
      const res = await axios.post(apiUrl, options);
      console.log(res.data.leaderBoard.currentuser)
      const data = res.data.leaderBoard;
      const currentUser = res.data.leaderBoard.currentuserstatus;
      console.log("current", currentUser)
      if (data.users.length >= 1 && currentUser == false) {
        console.log("current")
        return this.setState({
          noRanking: false,
          loading: false,
          showUser: true,
          ranks: data.users,
          currentUser: data.currentuser,
          loading: false,
          imdef: "/assets/images/leaderboard/avatar.png"
        });
      }
      else {
        console.log("no current")
        return this.setState({
          noRanking: false,
          loading: false,
          showUser: false,
          ranks: data.users,
          currentUser: {},
          loading: false,
          imdef: "/assets/images/leaderboard/avatar.png"
        });
      }
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        this.setState({
          errors: true,
          errorMsg: this.props.t("network_failed")
        });
      }

    }
  }



  render() {
    const { ranks, loading, noRanking, errors, errorMsg, currentUser , showUser} = this.state;
    const rank = ranks.map((i, k) => {
      return (
        <Rank
          key={`rank-${k}`}
        >
          <div className={i.currentuser ? "winner-details userwinner-details":"winner-details"}>
            <div className="winner_number">
              <p>{i.userRank}</p>

            </div>
            <div className="winner_info">

              <img src={i.photo ? i.photo: "./assets/images/leaderboard/avatar.png"} />
              <p>{i.userName}</p>
            </div>
            <div className="winner_point">
              <p>{i.userPoints}</p>
            </div>
          </div>

        </Rank>

      );
    });


    if(showUser)
		{
      console.log("user" ,currentUser )
      var current = 
      <Rank>
        <div className="winner-details userwinner-details">
            <div className="winner_number">
              <p>{currentUser.userRank}</p>

            </div>
            <div className="winner_info">

            <img src={currentUser.photo ? currentUser.photo: "./assets/images/leaderboard/avatar.png"} />
              <p>{currentUser.userName}</p>
            </div>
            <div className="winner_point">
              <p>{currentUser.userPoints}</p>
            </div>
          </div>
      
    </Rank>
      
		

		}


    if (noRanking) {
      return (
        <WinnerssDisplay>
          <div className="noResults"><Trans>no_results</Trans><a href="/"><Trans>back_home</Trans></a></div>

        </WinnerssDisplay>

      )
    }
    else {
      return (<WinnerssDisplay>
        <div className="bannerwinners">
          {/* <h2><Trans>today's winner</Trans></h2> */}
          <img  width="280" height="250"
          src={localStorage.getItem('i18nextLng') == 'ar' ? "/assets/images/HomepagecomGamezroid/winner.png" : "/assets/images/HomepagecomGamezroid/winner-en.png"}
          />


        </div>
        {localStorage.getItem('showdouble') == 'true' ?
								<div className="globalcoins" style={{top:'22%'}}>
									<img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
								</div>
								: ""}
        <div className="winnerscontent">
        <div className="winners">
          {rank}
          {current}
        </div>
        </div>


      </WinnerssDisplay>)
    }



    // return(

    // );
  }
}


export default withTranslation()(WinnersPage)










//////////////////










