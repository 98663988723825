import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./NigeriaSignupPage.scss";
import NigeriaSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getPhoneByHeader, getRedirectUrl, subscribe } from "./services";


const randomBg = bgSliders.getRandomBg();
function NigeriaSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [showLoading, setshowLoading] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");


  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    },
    {
      plan: "m",
      name: "Monthly"
    }
  ];

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    async function fetchit() {
      var linkheader = "http://mtnng.playit.mobi/checkheader.php";
      const num = await getPhoneByHeader(linkheader);
      var phone_h = num;
      setActiveData(phone_h);
      if (phone_h != false) {
        setCheckPhone(true)
      }
    }

    const url = window.location.search;
    if (url !== "") {
      console.log("tracker")
      setAgencyData(url);
    }

    fetchit();

  }, []);

  const Subscribe = async e => {
    e.preventDefault();
    var data = (new Date().getUTCFullYear()).toString() + (new Date().getMonth()).toString() + (new Date().getDay()).toString() + (new Date().getHours()).toString() + (new Date().getMinutes()).toString() + (new Date().getSeconds()).toString() + (new Date().getMilliseconds()).toString()
   // console.log(data)
    tracker(data);
    // window.location.href = `http://ng-app.com/Mindray/PlayIt-168-No-23401220000029389-Web?trxId=${data}`
    // if (activeData) {
    //   var phon_n = activeData.toString();
    //   var url = ""
    //   if (checkPhone == true) {
    //     url = `http://api.playit.mobi/api/v2/nigeria/subscribe?msisdn=${phon_n}&plan=${signupPlan}&headerenrich=yes`;
    //     document.getElementById("subsc").setAttribute('disabled', 'disabled')
    //     axios.get(url)
    //       .then(res => {
    //         console.log(res);
    //         if (res.data.status == true) {
    //           if (res.data.user_id) {
    //             tracker(phon_n);
    //             setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
    //             window.location.href = "/";
    //           }
    //           else {
    //             window.location.href = "/";
    //           }
    //         } else {
    //           if (res.data.message != null) {
    //             setshowLoading(false);
    //             setErrorMesgSubscribe(res.data.message);
    //             setErrorMesg(true);

    //           }
    //           else {
    //             setshowLoading(false);
    //             setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
    //             setErrorMesg(true);


    //           }
    //         }

    //       })
    //       .catch(function (error) {
    //         setErrorMesg(true)
    //         setShowverif(false)
    //       })
    //       .finally(function () {
    //         console.log('complete');
    //       });
    //   }
    //   else {
    //     url = `http://api.playit.mobi/api/v2/nigeria/subscribe?msisdn=${phon_n}&plan=${signupPlan}&headerenrich=no`;
    //     document.getElementById("subsc").setAttribute('disabled', 'disabled');
    //     setshowLoading(true);
    //     axios.get(url)
    //       .then(res => {
    //         console.log(res)
    //         if (res.data.status == "true") {
    //           setshowLoading(false);
    //           setShowverif(true)
    //           setErrorMesg(false)
    //           setShowSubscribe(false)
    //         } else {
    //           if (res.data.message != null) {
    //             setshowLoading(false);
    //             setErrorMesgSubscribe(res.data.message);
    //             setErrorMesg(true);

    //           }
    //           else {
    //             setshowLoading(false);
    //             setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
    //             setErrorMesg(true);


    //           }
              
    //         }

    //       })
    //       .catch(function (error) {
    //         setErrorMesg(true)
    //       })
    //       .finally(function () {
    //         console.log('complete');
    //       });
    //   }
    // }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      // if (!phon_n.slice(0, 4).includes('244')) {
      //   phon_n = '244' + phon_n;
      // }
      // http://api.playit.mobi/api/v2/jawwal/gamezroid/verify?msisdn=962789100130&pin=1234&pin=11
      axios.get(`http://api.playit.mobi/api/v2/nigeria/verify?msisdn=${phon_n}&plan=${signupPlan}&pin=${verifyCode}`)
        .then(res => {
          console.log(res);
          if (res.data.status == true) {
            setVerfiErrorMesg(false)
            tracker(phon_n);
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              window.location.href = "/";
            }
            window.location.href = "/";
          } else {
            setVerfiErrorMesg(true)
          }
        })
        .catch(function (error) {
          console.log(error);
          setVerfiErrorMesg(true)
        })
        .finally(function () {

          console.log('complete');
        });
    }
  };


  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  // save tracker
  const tracker = async (GMSISDN) => {
    console.log(GMSISDN)
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = '647ytD5OsT';
      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };
      console.log(datag)

    }
    await saveTracker(datag);
    window.location.href = `http://ng-app.com/Mindray/PlayIt-168-No-23401220000029389-Web?trxId=${GMSISDN}`


  }

  return (
    <NigeriaSignupPageDisplay className="" >
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}


      {showLoading ?
        <div className="load">
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : null}

      <div className='operatorSignup operatorSignupGamez'>
        <div className="formsLayout formsLayoutniger">

          <div className='header'>
            <Link to="/">
              <div className='logogamezroid logo' />
            </Link>
          </div>


            <>
              <div className="links-Section links-SectionGamezorid">

                <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                  <Trans>Your content is ready!</Trans>
                </h3>


                <div className="play"></div>
                <form>
                  <div>
                    {/* <select
                      className="select"
                      defaultValue={signupPlan}
                      onChange={e => setSignupPlan(e.target.value)}
                    >

                      <Trans>{plansOptions}</Trans>
                    </select>
                    <input
                      type="number"
                      className="input"
                      placeholder={t("Enter_your_phone_number")}
                      value={activeData}
                      disabled={checkPhone == true}
                      onChange={e => setActiveData(e.target.value)}
                    />
                    <br></br> */}
                    <br></br>
                    <button
                      id="subsc"
                      className="btn gamezroid btn_gamezroid"
                      // disabled={activeData ? "" : "disabled"}
                      onClick={(e) => {
                        Subscribe(e);
                      }} >
                      {t("Subscribe")}
                    </button>

                  </div>
                </form>

                <div className="row text-center" >
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                      <p><Trans>evaluation</Trans></p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                      <p><Trans>game</Trans></p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                      <p><Trans>user</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </>
     



{/* 
          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycode</Trans></p>
              <input
                type="number"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={e => setVerifyCode(e.target.value)}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          } */}


          <div className="footer">
            {!showverif ? <div>

            </div> : null}
            <div className="ncellTos">
              <h3><Trans>terms_and_conditions</Trans></h3>

            </div>
          </div>

        </div>
      </div>

    </NigeriaSignupPageDisplay>
  );
}
export default withTranslation()(NigeriaSignupPage)
