/* eslint-disable jsx-a11y/alt-text */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./DuSubscribePage.scss"
import DuSubscribePageDisplay from "./DuSubscribePage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import * as localLang from "../../i18n/i18n"
import { setAgencyData} from "../../helpers/agencyTracker";

const randomBg = bgSliders.getRandomBg();
function DuSubscribePage({ t }) {
  const [showagin, setshowagin] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [agree, setagree] = useState(false);
  const [showterms, setshowterms] = useState(true);
  const [subtype, setSubtype] = useState("daily");
  var lang = localStorage.getItem('i18nextLng')
  useEffect(() => {
    

    const params = queryString.parse(window.location.search);
    
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      console.log("tracker")
      setAgencyData(urlsearch);
    }

    console.log(params.language);
    if (params.cbd == 1 || params.cbd == true || params.cbd == 'true') {
      //setagree(true);
    }
    if (params.language) {
      console.log("task");
      localStorage.setItem("i18nextLng", params.language);
      localLang.checklocalLanguage();

    }
    if (params.subtype) {
      setSubtype(params.subtype)
    }

  }, []);






  const handlclick = e => {
    e.preventDefault();
    setshowbutton(true);
    setshowterms(false);
  }
  // const Subscribe = async e => {
  //   e.preventDefault();
  //   var url = ""
  //   url = `http://api.playit.mobi/api/v2/du/maxSubscribersPolicy`;
  //   setshowLoading(true);
  //   axios.get(url)
  //     .then(res => {
  //       console.log(res);
  //       if (res.data.status == true) {
  //         setshowLoading(false);
  //         window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';

  //       } else {
  //         setshowagin(true)
  //         setshowLoading(false);
  //       }

  //     })
  //     .catch(function (error) {
  //       setErrorMesg(true)
  //     })
  //     .finally(function () {
  //       console.log('complete');
  //     });
  // };


  const Subscribe = async e => {
    e.preventDefault();
    var url = `http://api.playit.mobi/api/v2/du/preparePurchas`;
    setshowLoading(true);
    axios.get(url)
      .then(res => {
        console.log(res.data.status);
        if (res.data.status == true) {
          window.location.href=res.data.url
         // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';

        } else {
          setshowagin(true)
        }

        setshowLoading(false);


      })
      .catch(function (error) {
        setErrorMesg(true)
      })
      .finally(function () {
        console.log('complete');
      });
  };


  return (
    <DuSubscribePageDisplay>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}



      <div className='operatorSignup  duoperatorSignup'>
        <div className="formsLayout formsLayoutjawal formsLayoutmondia">
          <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia links-SectionDu">


            <div className="mondia du-cover">
              <Link to="/">
                <img src="./assets/images/mondia/du-bg.jpg" width="100%" />
              </Link>

            </div>

            <h2 className="mondiacontent ducontent" id="gamezoridcon">
              <Trans>downloadgame</Trans>
            </h2>

            <button
                id="signup"
                className="btn btngamezone btnmondia btn_du"
                style={{ backgroundColor: !agree ? "" : "#8ce6e6" }}
                onClick={(e) => { Subscribe(e) }}
              >
                {/* {showbutton ? t("Verifyagree_mondia") :t("Verify_mondia") } */}
                {t("download.confirm")}
              </button>



            {/* {showbutton ?
              <button
                id="signup"
                className="btn btngamezone btnmondia"
                disabled={agree == true ? "" : "disabled"}
                style={{ backgroundColor: !agree ? "" : "#8ce6e6" }}
                onClick={(e) => { Subscribe(e) }}
              >
                {t("Verifyagree_mondia")}
              </button>
              :
              <button
                id="signup"
                className="btn btngamezone btnmondia"
                disabled={agree == true ? "" : "disabled"}
                style={{ backgroundColor: !agree ? "" : "#8ce6e6" }}
                onClick={(e) => { handlclick(e) }}
              >
                {t("Verify_mondia")}
              </button>

            } */}




            <div className="footer jawwalgamezoridFooter mondiaFooter duFooter">

              <div className="ncellTos jawwalgamezoridncellTos mondiancellTos">
                {/* {showterms ?
                  <h4>
                    <input type="checkbox" style={{ margin: '4px 9px 0' }} onChange={(e) => { setagree(!agree) }} checked={agree} />
                    <Trans>du_conditions.accept_condition</Trans></h4>
                  : ""}
                {showagin ?
                  <h4><Trans>try_again_mondia</Trans></h4> : ""} */}

                {subtype == 'daily' ?

                  <h4><Trans>du_conditions.condition_dailyprice</Trans></h4>
                  :
                  <h4><Trans>du_conditions.condition_weeklyprice</Trans></h4>
                }

                <br></br>

                <div className="row text-center" >
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info du-info">
                      <img src="/assets/images/subscribegamezriod/help.png" alt="img" className="iconsimg" />
                      <p><Trans>du_conditions.help</Trans></p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info du-info">
                      <img src="/assets/images/subscribegamezriod/Privacy.png" alt="img" className="iconsimg" />
                      <p><Trans>du_conditions.Privacy</Trans></p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info du-info du-info3" >
                      <img src="/assets/images/subscribegamezriod/PlayITSmall.png" alt="img" className="iconsimg" />
                      <p><Trans>du_conditions.PlayIT</Trans><br></br><Trans>du_conditions.Rights</Trans></p>
                    </div>
                  </div>
                </div>
                {/* <div style={{ padding: '0 10px', textAlign: lang == 'ar' ? "right": "left" , direction : lang == 'ar' ? "rtl": "ltr"}}>
                  <h3><Trans>du_conditions.term</Trans></h3>
                  <p><Trans>du_conditions.condition1</Trans></p>
                  <p> <Trans>du_conditions.condition2</Trans> <Trans>{subtype}</Trans> <Trans>du_conditions.condition21</Trans></p>
                  <p> <Trans>du_conditions.condition3</Trans> <Trans>{subtype}</Trans> <Trans>du_conditions.condition31</Trans> </p>
                </div> */}

              </div>
            </div>
          </div>



          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

    </DuSubscribePageDisplay>
  );
}
export default withTranslation()(DuSubscribePage)
