import React, { Component } from "react";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";




class UmniahCatchPage extends Component {
  constructor() {
    super();
  }

  
  async componentDidMount() {

    axios.get(`http://www.api.playit.mobi/api/v2/omantel/decryptMsisdn2?msisdn=${convertPass}`)
    .then( res => {
      if ( res.data.msisdn) {
        
        setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
        window.location.href = "/";
      }
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "/";
    })
    .finally(function () {
      console.log('complete');
      window.location.href = "/";
    });
   
    const checkHeader = ()=> {
      const phoneNum = queryString.parse(window.location.search);
      if(phoneNum.msisdn){
        convertPass(phoneNum.msisdn);
      }else if (phoneNum.redirected) {
        console.log('No phone number');
      }else{
        getMsisdn();
      }
    }
    checkHeader()
   
    const convertPass = (msisdn) => {
      const headers ={
        headers:{ 'bhmsisdn' : msisdn}
      }
      return axios.get(`http://www.api.playit.mobi/api/v1/getMSIDN${headers}`)
      .then( res => {
        if ( res.data.msisdn) {
          setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          window.location.href = "/";
        }
      } )
      .catch(function (error) {
        console.log(error);
        window.location.href = "/";
      })
      .finally(function () {
        console.log('complete');
        window.location.href = "/";
      });
  
    }
    const getMsisdn = () => {
      window.location.replace(`http://helm.tekmob.com/pim/vivabhrhe?redirectURL=http://omantel.playit.mobi/omantel-catch?redirected=true&user=grandit&pass=gt66698`);     
       }
  }

  




  render() {

    return (
       <div>
          <p>Umniah Catch Page</p>
        </div>

    
    );
  }
}
export default UmniahCatchPage;
