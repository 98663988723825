import React from "react";
import  "../../../components/Slider/Slider.scss";

import { Trans } from "react-i18next";
import { withRouter } from 'react-router-dom';

import h from "../../../helpers/helpers";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const hostName = window.location.hostname;
const iconsTab = h.getIconByHostName(hostName);
export default function SliderCompation({
  mainTabsInfo,
  games,
  activeMainTab,
  setActiveMainTab

}) { 
  var games2 = Object.values(games);
  let gamesall = Object.values(games2[0]);
  var gamesSliderList = gamesall.map((i, k) => {
      return<div><img src={i.game_photo} /></div>
      
  });
  console.log("slider" , gamesall)
  return (

    <Carousel autoPlay >
      {gamesSliderList}
    </Carousel>
  );

}