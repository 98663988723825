import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import {Provider} from "react-redux";
import {createStore} from "redux";
import "./assets/scss/main.scss";
import "./i18n/i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./theming/themes.scss";
import  reducer  from "./components/reducers/countReducer";
 
const store = createStore(reducer)
ReactDOM.render(<Provider store={store} ><App /></Provider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
