/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link  , useHistory} from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./NewEgySubscribePage.scss";
import NewEgySubscribePageDisplay from "./NewEgySubscribePage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import * as localLang from "../../i18n/i18n";
import { setAgencyData } from "../../helpers/agencyTracker";

const randomBg = bgSliders.newBgegypt();
function NewEgySubscribePage({ t }) {
  const [showagin, setshowagin] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [agree, setagree] = useState(false);
  const [showterms, setshowterms] = useState(true);
  const [subtype, setSubtype] = useState("daily");
  const [signupPlan, setSignupPlan] = useState("d");
  const [signupCompany, setsignupCompany] = useState("vodafone");
  const [codeCompany, setcodeCompany] = useState("7785");
  const history = useHistory();
  
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));




  // var lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    // localLang.setlangSubscribe();
    const params = queryString.parse(window.location.search);

    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      console.log("tracker");
      setAgencyData(urlsearch);
    }

    console.log(params.language);
    if (params.cbd == 1 || params.cbd == true || params.cbd == "true") {
      //setagree(true);
    }
    if (params.language) {
      localStorage.setItem("i18nextLng", params.language);
      localLang.setlangSubscribe();
     
    }
    if (params.subtype) {
      setSubtype(params.subtype);
    }
  }, []);




  const plans = [
    {
      plan: "d",
      name: "Daily",
    },
    {
      plan: "w",
      name: "Weekly",
    },
    {
      plan: "m",
      name: "Monthly",
    },
  ];

  const companies = [
    {
      name: "Vodafone",
      value: "vodafone",
      shortCode:"7785"
    },
    {
      name: "Etisalat",
      value: "etisalat",
      shortCode:"7786"
    },
    {
      name: "Orange",
      value: "orange",
      shortCode:"7810"
    },
    {
      name: "WE",
      value: "we",
      shortCode:"4036"
    },
  ];

  const handlclick = (e) => {
    e.preventDefault();
    setshowbutton(true);
    setshowterms(false);
  };
  // const Subscribe = async e => {
  //   e.preventDefault();
  //   var url = ""
  //   url = `http://api.playit.mobi/api/v2/egypt/maxSubscribersPolicy`;
  //   setshowLoading(true);
  //   axios.get(url)
  //     .then(res => {
  //       console.log(res);
  //       if (res.data.status == true) {
  //         setshowLoading(false);
  //         window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';

  //       } else {
  //         setshowagin(true)
  //         setshowLoading(false);
  //       }

  //     })
  //     .catch(function (error) {
  //       setErrorMesg(true)
  //     })
  //     .finally(function () {
  //       console.log('complete');
  //     });
  // };

  const Subscribe = async (e, operator) => {
    e.preventDefault();
    console.log('operator' , operator , signupPlan)
    if (!signupPlan) {
      setErrorMesg(true);
      setErrorMesgSubscribe("Please select your plan");
    } else {
      var url = `http://api.playit.mobi/api/v2/egypt/preparePurchas?plan=${signupPlan}&operator=${operator}`;
      setshowLoading(true);
      axios
        .get(url)
        .then((res) => {
          console.log(res.data.status);
          if (res.data.status == true) {
            window.location.href = res.data.url;
            // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
          } else {
            setshowagin(true);
          }

          setshowLoading(false);
        })
        .catch(function (error) {
          setErrorMesg(true);
        })
        .finally(function () {
          console.log("complete");
        });
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  const companiesOptions = companies.map((c, k) => {
    return (
      <option key={`company-${k}`} value={c.value}>
       {c.name}
      </option>
    );
  });

  function handleCompany(value)
  {
    console.log(value)
    setsignupCompany(value);
    // console.log(signupCompany);
    companies.filter(item => {
      if(item.value === value)
      {
        setcodeCompany(item.shortCode)
      }
    })
  }


  return (
    <NewEgySubscribePageDisplay bgImg={randomBg}>
      {errorMesg && !signupPlan ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup  EgyoperatorSignup">
        <div className="formsLayout formsLayoutjawal formsLayoutmondia formsLayoutEgypt">
          <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia links-SectionEgy">
            <div className="mondia du-cover">
              <Link to="/">
                <img src="./assets/images/Egypt/games.png" width="90%" />
              </Link>
            </div>

            <h2
              className="mondiacontent ducontent egycontent"
              id="gamezoridcon"
            >
              <Trans>downloadgame</Trans>
            </h2>

            <div className="iconSelect EgyiconSelect">
              {/* <Trans>Subscribtion_Plan</Trans>
                     <i className="icon money" /> */}
              <select
                style={{}}
                className="select"
                onChange={(e) => setSignupPlan(e.target.value)}
              >
                {/* <option>{t('Please select your plan')}</option> */}
                <Trans>{plansOptions}</Trans>
              </select>

<br />
              <select
              style={{ marginTop:'30px'}}
              className="select"
              onChange={(e) =>  handleCompany(e.target.value)}
            >
            {/* <option>{t('Please select Your Operator')}</option> */}
              <Trans>{companiesOptions}</Trans>
            </select>


            </div>

            <button
            id="signup"
            className="btn btngamezone btnmondia btn-egypt"
            onClick={(e) => { Subscribe(e , signupCompany) }}
          >
            {/* {showbutton ? t("Verifyagree_mondia") :t("Verify_mondia") } */}
            {t("Subscribe")}
          </button>

            {/*  <p>{t("Please select Your Operator")}</p> */}
            {/* 
            <button
                id="signup"
                className="btn btngamezone btnmondia btn_du"
                style={{ backgroundColor: !agree ? "" : "#8ce6e6" }}
                onClick={(e) => { Subscribe(e) }}
              >
                {t("download.confirm")}
              </button> */}

            <div className="footer jawwalgamezoridFooter mondiaFooter duFooter">
              <div
                className="ncellTos jawwalgamezoridncellTos mondiancellTos"
                style={{ marginTop: "0" }}
              >
                {/* {subtype == 'daily' ?

                  <h4><Trans>du_conditions.condition_dailyprice</Trans></h4>
                  :
                  <h4><Trans>du_conditions.condition_weeklyprice</Trans></h4>
                } */}



               {/*<div className="row text-center content-row">
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "vodafone");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/vod2.png"
                          alt="img"
                          className="iconsimg"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "etisalat");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/ets.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "orange");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/orange.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a href=""
                        onClick={(e) => {
                          Subscribe(e, "we");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/we.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "2.4em" }}
                        />
                      </a>
                    </div>
                  </div>
                      </div> */}

                <br />

                <div className="condtion-list">
                  {signupPlan == "d" ? (
                    <div>
                      <p><Trans>EgyptConditions.playit-daily</Trans></p>
                      <p><Trans>EgyptConditions.price-daily</Trans></p>
                    </div>
                  ) : signupPlan == "w" ? (
                    <div>
                      <p><Trans>EgyptConditions.playit-weakly</Trans></p>
                      <p><Trans>EgyptConditions.price-weakly</Trans></p>
                    </div>
                  ) : (
                    <div>
                      <p><Trans>EgyptConditions.playit-monthly</Trans></p>
                      <p><Trans>EgyptConditions.price-monthly</Trans></p>
                    </div>
                  )}

                  <ul>
                    <li>
                      <Trans>EgyptConditions.part1</Trans>
                    </li>
                    <li>
                      <Trans>EgyptConditions.part2</Trans>
                    </li>
                    <li>
                      <Trans>EgyptConditions.part3</Trans>

                      {signupPlan == "d" ? (
                        <Trans>STOP PLAYITD</Trans>
                       
                      ) : signupPlan == "w" ? (
                        <Trans>STOP PLAYITW</Trans>

                      ) : (
                        <Trans>STOP PLAYITM</Trans>

                      )}
                      
                     <Trans> to</Trans> {codeCompany }.
                    </li>
                    <li>
                      <Trans>EgyptConditions.part4</Trans>
                    </li>
                    <li>
                      <Trans>EgyptConditions.part5</Trans>
                    </li>
                  </ul>
                </div>

                <div style={{ paddingTop: "4vh" }}>
                  <h3 style={{ color: "#fff" }}>
                    <Trans>EgyConditions.part1</Trans>{" "}
                    <a href="#" className="text-cond">
                      <Trans>EgyConditions.part2</Trans>
                    </a>
                  </h3>
                  {/* <p><Trans>du_conditions.condition1</Trans></p>
                  <p> <Trans>du_conditions.condition2</Trans> <Trans>{subtype}</Trans> <Trans>du_conditions.condition21</Trans></p>
                  <p> <Trans>du_conditions.condition3</Trans> <Trans>{subtype}</Trans> <Trans>du_conditions.condition31</Trans> </p> */}
                </div>
              </div>
            </div>
          </div>

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </NewEgySubscribePageDisplay>
  );
}
export default withTranslation()(NewEgySubscribePage);
