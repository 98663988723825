import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Navbar } from "../components";
import { client } from "./Client";
import { FullScreen, useFullScreenHandle } from "react-full-screen";


export default function SafeRoute({
  hasNav = true,
  locked = false,
  component: Component,
  ...rest
}) {
  // auth numbers meaning
  // 0 = not authorized
  // 1 = checking
  // 2 = authorized
  const handle = useFullScreenHandle();

  const [authorized, setAuthorized] = useState(1);
  useEffect(() => {
    async function checkAuth() {
      const res = await client.auth();
      if (res === true) {
        return setAuthorized(2);
      }
      console.log('authorized',authorized)
      return setAuthorized();
      // return setAuthorized(0);
    }
    checkAuth();
  }, authorized);
  return (
    
      <Route
      {...rest}
      render={props => (
        <>
          {hasNav && <Navbar history={props.history}/>}
          {locked ? (
            <>
              {authorized === 2 && <Component {...props} />}
              {authorized === 0 && <Redirect to="/login" />}
              {authorized === 1 && (
                <div>
                  <div className="spinerC">
                    <div className="spinner"></div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    />
      
  
  );
}
