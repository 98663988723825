
import axios from "axios";
import init from './libr';

import React, { Component , PropTypes  } from "react";
import { Trans, withTranslation } from "react-i18next";




const token = localStorage.getItem("token");
const userId = localStorage.getItem("userId");

class PlayCompetition extends Component {
  constructor(props) {
    super(props);
    console.log("props" , props)
    this.state = {
      flag: true,
    }
  }

  componentWillReceiveProps(props) {
    console.log("receive props" , props)
    if(props.gameFreeIframe && props.gameID)
    {
        console.log("yes")
        localStorage.setItem("gameID" ,props.gameID)
        let url = `${props.gameFreeIframe}/?user_id=${userId}&game_id=${props.gameID + ""}&user_token=${token}`;
        init(url)
    }
    else 
    {
        console.log("empty")

    }
}

  componentDidMount() {
    /*const { gameFreeIframe, gameID } = this.props;
    console.log(gameID, gameFreeIframe)
    let url = `${gameFreeIframe}/?user_id=${userId}&game_id=${gameID + ""}&user_token=${token}`;*/
    localStorage.setItem("close", 'false')
   /* console.log(">>>>>>>>");
    console.log(url);
    console.log(">>>>>>>>");*/
    
   // init(url)


  }
 
  componentWillUnmount() {
    const { gameFreeIframe, gameID } = this.props;
    const url = `${gameFreeIframe}/?user_id=${userId}&game_id=${gameID + ""}&user_token=${token}`;
    localStorage.setItem("close", 'true')
    init(url);

  }

  render() {
    

    return (
      <div id="scoreBox">
        <p id="text"></p>
        {/*  */}
      </div>
     

    )
}
}
export default withTranslation()(PlayCompetition)





