import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./DuVerifyPage.scss";

import DuverifyPageDisplay from "./DuVerifyPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router";
import { saveTracker } from "../../helpers/agencyTracker";

import { client } from "../../auth/Client";




const randomBg = bgSliders.randomBgooredoo();
function DuVerifyPage({ t }) {
  const [isUserSubscribed, setisUserSubscribed] = useState(false);
  const [isErrorExist, setisErrorExist] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const location = useLocation();
  const history = useHistory();

  //const useParams = useParams();

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'ZijMZyHHxh';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
        console.log(datag)
  
      }
      await saveTracker(datag);
  
    }

    async function login(code , sendtracker=false) {
      if (code) {
        var url = `http://www.api.playit.mobi/api/v2/du/fetchSubDetails?code=${code}`;
        axios.get(url)
          .then(res => {
           console.log(res.data.status)
            if (res.data.status == true) {
              if (res.data.data.username && res.data.data.password) {
                if(sendtracker){
                  tracker(res.data.data.username);
                }
                const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
                if (hasAccess) {
                  history.push("/");
                }
              }
            }
            else {
              seterrorMessage(res.data.data)
              setErrorMesgSubscribe(res.data.data)
              setisErrorExist(true);
              setErrorMesg(true);
            
              // seterrors(false)
              // seterrsub(true)
              // seterrsubMsg(res.data.data);
            }
           // setloading(false)
  
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(function () {
            console.log('complete');
          });
  
  
      }
      
    }
    async function confirmLogin(username,password) {

      const hasAccess = await client.login(username,password);
      return hasAccess;
    }
  

    
  useEffect(() => {
    const params = queryString.parse(window.location.search);
 
    var queryParams;
    if (params.status == "SUCCESS") {
      console.log("SUCCESS")
      var packageCode = location.pathname.split('/')[2];
      var userNamePhone = params.customerId;
      console.log("packageCode" , packageCode);
      queryParams = window.location.search.split('?');
      queryParams = queryParams[1];
      console.log('queryParams' ,queryParams);
      queryParams += "&PackageCode=" + packageCode;
      console.log(queryParams);
      var url = ""
      url = `http://www.api.playit.mobi/api/v2/du/subscribeUser?${queryParams}`;
      axios.get(url)
        .then(res => {
          console.log(res);
          if (res.data.status == true) {
            tracker(userNamePhone);
            setisUserSubscribed(true);
            //make login
          history.push("/");
          }
          else
          {
            setisUserSubscribed(false);
          history.push("/");
          }
        })
        .catch(function (error) {
          setErrorMesg(true)
        })
        .finally(function () {
          console.log('complete');
        });


     
    }
    else if (params.status == "ERROR" && params.errorCode == '3001') {
      console.log("ERROR")

      var url2 = "";
      url2 = `http://www.api.playit.mobi/api/v1/getToken/${params.customerId}/1`;
      axios.get(url2)
        .then(res => {
          console.log(res.data.code);
          if (res.data.code == 200) {
            //make login
           history.push("/");

          }
          else {
            history.push("/login");

          }
          //console.log('mondia catch ');
        })
        .catch(function (error) {
          setErrorMesg(true)
        })
        .finally(function () {
          console.log('complete');
        });


    }
    else if(params.code)
    {
      console.log("done")
      // setvalidcode(true)
      if(params.status == 200){
        login(params.code,true);
      }else{
        login(params.code,false);
      }
      
      
      
       
    }
    else {
      // queryParams = window.location.search.replace('/duverify', '');
      queryParams = window.location.pathname + window.location.search;
      queryParams = queryParams.replace('/duverify', '');

      console.log("URL",queryParams );

      setisErrorExist(true);
      console.log(params.errorDesc)
      seterrorMessage(params.errorDesc);
    
    }

  }, []);
  
  


  return (
    <DuverifyPageDisplay  bgImg={randomBg}>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

  

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal formsLayoutduverify">
        {/* <div className="header" className="header">
                <Link to="/">
                  <div className="logoo" />
                </Link>
              </div> */}
          {/* <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia">
          </div> */}

          <div className="Permission">
            {isErrorExist ?     
            <p>{errorMessage}</p>
            : 
            <p><Trans>Thanksubscribing</Trans></p>
            }
          </div>

       

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

    </DuverifyPageDisplay>
  );
}
export default withTranslation()(DuVerifyPage)
