import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import bgSliders from "../../helpers/bgSliders";
import "./ForgetPasswordPage.scss";
import ForgetPasswordPageDisplay from "./ForgetPasswordPage.styled";
import { client } from './../../auth/Client';
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import { Link } from 'react-router-dom';

class ForgetPasswordPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      errors: false,
      errorMsg: ""
    };
  }
  componentDidMount() {
    bgSliders.randomBg();
  }
  componentWillUnmount() {
    bgSliders.removeBg();
  }

  async forgetPassword(e) {
    e.preventDefault();
    const { phone } = this.state;
    if (!this.isValid(phone)) {
      this.setState({
        errors: true,
        errorMsg: this.props.t("Empty Phone")
      });
      return;
    }

    const sentPassword = await client.forgetPassword(phone);
    if (!sentPassword || sentPassword === 404) {
      return this.setState({
        errors: true,
        errorMsg: this.props.t("not_correct_phone")
      });
    }
    this.props.history.push("/login");
  }

  isValid(phone) {
    return phone ? true : false;
  }

  onInputChange(name, value) {
    this.setState({
      [name]: value,
      errors: false
    });
  }
  render() {
    const { errors, errorMsg } = this.state;
    const { t } = this.props;

    return (
      <ForgetPasswordPageDisplay className="formPage">
        <form onSubmit={e => this.forgetPassword(e)}>
          <div className="formsLayout">
            <div className="header">
              <Link to="/">
              <div className="logo" />
              </Link>
            </div>
            <div className="center">
              <div className="form">
                <div className="iconInput">
                  <i className="icon phone" />
                  <input
                    type="number"
                    className="input"
                    placeholder={t("enter_phone_number")}
                    onChange={e => this.onInputChange("phone", e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="footer">
              <p className="txt">
                <Trans>resetPassward.reset_message</Trans>
              </p>
              <div className="normBtn">
                <button className="btn" type="submit">
                  <Trans>resetPassward.reset</Trans>
                </button>
              </div>
            </div>
          </div>
        </form>
        {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
      </ForgetPasswordPageDisplay>
    );
  }
}
export default withTranslation()(ForgetPasswordPage);
