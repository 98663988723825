import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import UnitelSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getPhoneByHeader, getRedirectUrl, subscribe } from "./services";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";
import "./UnitelSignupPage.scss";
import { useHistory } from "react-router";




const randomBg = bgSliders.getRandomBg();
function UnitelSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [encryptedPhone, setEncryptedPhone] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [signup, setSignup] = useState("signup");


  

  const urljawwal = window.location.hostname.includes("jawwal");
  const url = window.location.href;
  const history = useHistory();


  const APImsisdn = {
    bhmsisdn: ''
  }

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (url.includes('signup2')) {
      setSignup("signup2")
    }

    async function fetchit() {
      //var linkheader = "http://gamezone.playit.mobi/checkheadergamezone.php";
      var linkheader = "./checkheaderunitel.php";
      
      const num = await getPhoneByHeader(linkheader);
      var phone_h = num;
      console.log("phone" , phone_h)
      setActiveData(phone_h);
      setEncryptedPhone(phone_h);
      if (phone_h != false) {
        const  decryptMsisdUrl = "http://api.playit.mobi/api/v2/Unitel/decryptMsisdnunitel"
        var senddata ={
          phone : phone_h
        }
        try {
          const res = await axios.post(decryptMsisdUrl, senddata);
          if (res.data) {
            // console.log("res" , res.data.status)
            const data = res.data;
            if (data.status == true) 
            {
              setActiveData(data.msisdn)
            }
          
          }
        
        } catch (err) {
          console.log(err)
      
        }

        setCheckPhone(true);

      }
    }
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      console.log("tracker")
      setAgencyData(urlsearch);
    }

    fetchit();
  }, []);

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = '467x6F5iHl';


      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: op_id,
        phoneNum: GMSISDN
      };
      console.log(datag)

    }
    await saveTracker(datag);

  }


  const Subscribe = async e => {
    e.preventDefault();
    setshowLoading(true);
    if (activeData) {
      if (checkPhone == true) {
        var phon_n = encryptedPhone.toString();
      }else{
        var phon_n = activeData.toString();
        if (!phon_n.slice(0, 4).includes('244')) {   
          phon_n = '244' + phon_n;
        }
      }
      console.log("activeData", activeData) ;
      console.log("encryptedPhone", encryptedPhone) ;
      var url = ""
      /*if (!phon_n.slice(0, 4).includes('244')) {   
        phon_n = '244' + phon_n;
      }*/
      if (checkPhone == true) {
        url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=yes`

        var company_value = localStorage.getItem("trackerCompany");
        var tracker_value = localStorage.getItem('tracker');
        if (tracker_value != "") {
          url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=yes&company_value=${company_value}&tracker_value=${tracker_value}`
        }
        axios.get(url)
          .then(res => {
            console.log(res);
            if (res.data.status == true) {
              if (res.data.userId) {
                tracker(phon_n);
                setLoginData(res.data.loginData.user_id, res.data.loginData.token, res.data.loginData.user_name, res.data.loginData.photo, res.data.loginData.operator_id, res.data.loginData.user_email, res.data.loginData.msisdn);
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');
              }
              else {
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');

              }
            } else {
              setErrorMesgSubscribe(res.data.message);
              setErrorMesg(true)
              //window.location.href ="/login"
            }

            setshowLoading(false);

          })
          .catch(function (error) {
            setErrorMesg(true)
            setShowverif(false)
            setshowLoading(false);

          })
          .finally(function () {
            console.log('complete');
          });

      }
      else {
        url = `http://api.playit.mobi/api/v2/Unitel/subscribe?msisdn=${phon_n}&headerenrich=no`
        axios.get(url)
          .then(res => {
            console.log(res.data.status);
            if (res.data.status == true) {
              setShowverif(true)
              setErrorMesg(false)
            } else {
              setErrorMesgSubscribe(res.data.message);
              setErrorMesg(true)
              //setShowverif(true);
              //window.location.href = "/login"
            }
            setshowLoading(false);



          })
          .catch(function (error) {
            setErrorMesg(true)
            setShowverif(false)
           setshowLoading(false);

          })
          .finally(function () {
            console.log('complete');
          });


      }


    }
  };
  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      //var phon_n = activeData;
      //var phon_n = encryptedPhone.toString();
      if (checkPhone == true) {
        var phon_n = encryptedPhone.toString();
      }else{
        var phon_n = activeData.toString();
        if (!phon_n.slice(0, 4).includes('244')) {   
          phon_n = '244' + phon_n;
        }
      }
      /*if (!phon_n.slice(0, 4).includes('244')) {
        phon_n = '244' + phon_n;
      }*/
      var verify_url = "";

      verify_url = `http://api.playit.mobi/api/v2/Unitel/verify?msisdn=${phon_n}&pin=${verifyCode}`;

      var company_value = localStorage.getItem("trackerCompany");
      var tracker_value = localStorage.getItem('tracker');
      if (tracker_value !== "") {
        verify_url = `http://api.playit.mobi/api/v2/Unitel/verify?msisdn=${phon_n}&pin=${verifyCode}&company_value=${company_value}&tracker_value=${tracker_value}`;
      }

      if(checkPhone == true){
        verify_url+="&headerenrich=yes"; 
      }else{
        verify_url+="&headerenrich=no";
      }
      

      axios.get(verify_url)
        .then(res => {
          console.log(res);
          console.log("res.data.status",res.data.status);

          if (res.data.status == true) {
            setVerfiErrorMesg(false)
            console.log("res.data.status",res.data.status);
            if (res.data.userId) {
              tracker(phon_n);
              setLoginData(res.data.loginData.user_id, res.data.loginData.token, res.data.loginData.user_name, res.data.loginData.photo, res.data.loginData.operator_id, res.data.loginData.user_email, res.data.loginData.msisdn);
              setTimeout(() => {
                window.location.href = "/";
              }, 5000);
              //history.push('/thank-gamezone');


            } else {
              //history.push('/thank-gamezone');

              setTimeout(() => {
                window.location.href = "/";
              }, 5000);
            }

          } else {
            setVerfiErrorMesg(true)
          }
        })
        .catch(function (error) {
          console.log(error);
          setVerfiErrorMesg(true)
        })
        .finally(function () {

          console.log('complete');
        });
    }
  };



  return (
    <UnitelSignupPageDisplay className="" bgImg={signup == 'signup' ? '' : randomBg}>
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {/* {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null} */}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFY_ERROR</Trans>
        </FloatingAlert>
      ) : null}


      {showLoading ?
        <div className="load">
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : null}

      <div className={signup == 'signup2' ? 'operatorSignup' : 'operatorSignup gamezoneSignup'}>
        <div className="formsLayout">

          <div className={signup == 'signup2' ? 'header' : 'header header_gamezone'}>
            <Link to="/">
              <div className={signup == 'signup2' ? 'logo' : 'logo gamezoneLogo'} />
            </Link>
          </div>

          {!showverif && signup == "signup2" ?
            <div className="center">
              <form>
                <div>
                  <input
                    type="text"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    disabled={checkPhone == true}
                    onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                  />
                  <br></br>
                  <br></br> <br></br>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      Subscribe(e);
                    }} >
                    {t("Subscribe")}
                  </button>

                </div>
              </form>
            </div>

            : null}

          {!showverif && signup == "signup" ?
            <div className="links-Section links-SectionGamezone">

              <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3>
              <div className="play"></div>

              <input
                type="text"
                className="input gamezoneInput"
                placeholder={t("Enter_your_phone_number")}
                value={activeData}
                disabled={checkPhone == true}
                onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <button
                id="signup"
                className="btn btngamezone"
                disabled={activeData ? "" : "disabled"}
                onClick={(e) => { Subscribe(e) }}
              >
                {t("Subscribe")}
              </button>

              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


            </div>

            : null}

          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycode</Trans></p>
              <input
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn btngamezone"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }


          <div className="footer" style={{ marginBottom: '0px' }}>
            {!showverif ? <div>

            </div> : null}
            <div className="ncellTos">
              <h3><Trans>terms_and_conditions</Trans></h3>
            </div>


          </div>


        </div>
        {errorMesg ? (
          <FloatingAlert type={"error"}>
            <Trans>{errorMesgSubscribe}</Trans>
          </FloatingAlert>
        ) : null}
      </div>

    </UnitelSignupPageDisplay>
  );
}
export default withTranslation()(UnitelSignupPage)
