import styled from "styled-components";
const KsaSignupPageDisplay = styled.div`
background: ${p => p.bgImg};
  border-radius: 25px;
.header-ksa
{
  margin-top:25px!important;
}

  .header_mobily
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid #009edd;
  }
  .header_stc
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid #613bff
  }
  .header_zain
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid #3ef886
  }
  .links-Section{
    position: absolute;
      top: 30%;
      width: 75%;


      
     
  }
  .links-Section a {
    color:white
  }
  .links-Section h3 {
    color:white;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important
    font-size:14px;
    font-weight:400;
    text-transform: capitalize;
    text-align:center;


  }
  .links-Section button{
    
    display:block;
   
    margin: 40px auto;
    min-width: 190px;

  }
.btn_model
  {
    margin: 24px auto!important
  }

  .formsLayout {
    margin: 0 20px;
    min-height: 122vh;
  }
  .formPage
  {
      background:rgba(0, 0, 0, 0.78)
  }
  .selectGame
  {
    width: 60%;
    border-bottom: 1px solid #868282;
    margin: 15px 10px;

  }
  .btn_org,.btn_ooredoo,.btn_zainjordan,.btn_gamezroid,.btn_stckwuit,.btn_sabafon
  {
    margin: 130px auto!important;
  }
  .mob-Ksa , .stc-Ksa , .zain-Ksa
  {
    display: flex!important;
    justify-content: space-around!important;
    min-width: 290px!important;
    border-radius: 40px;
    padding:6px!important
  }
  .stc-Ksa
  {
    background-color: #fff !important;
  }
  .zain-Ksa
  {
    background-color: #1EFD02 !important;

    
  }

  .iconInputGame
  {
    width: 60%!important;
  }


  .links-Section-ksa
  {
    top:4%!important
  }
  

`
export default KsaSignupPageDisplay;
