import styled from "styled-components";
const NavbarDisplay = styled.div`
  .nav {

    background: var(--secondaryBgColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding: 20px 25px 15px 25px;
  // padding: 20px 19px 15px 19px;
    position: ;
    position: fixed;
    top: 0;
    width: 88%;
    z-index: 4;
    height: 23px;
    margin : var(--marginNav);
   maxWidth:1150
    }
    .nav_scroll
    {
      background-color: var(--BgNavScroll);
      opacity: var(--opacityScroll);
      margin:var(--marginScroll);
    }
  @media(min-width: 450px){
    .nav {
      width: 92%;
    }
  }
  @media(min-width: 700px){
    .nav {
      width: 95%;
    }
  }
  @media(min-width: 600px){
    .nav {
      width: 93%;
    }
  }
  .navHight{
    height:61px
  }
`;
export default NavbarDisplay;
