import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;

function setData(userId, token, userName, userPhoto) {
    localStorage.setItem("userId", userId);
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userPhoto", userPhoto);
    return true;
}

//http://api.playit.mobi/api/v2/umniah/verify?msisdn=962789100130&plan=d

//axios.get(`http://api.playit.mobi/api/v2/umniah/sendpin?msisdn=${msisdn}`)

async function verifyCode(msisdn, plan, codePin) {
    // console.log("ver" , codePin)
    try {
        const res = await axios.get(`${apiUrl}/umniah/verify?msisdn=${msisdn}&plan=${plan}&pin=${codePin}`);
        if (!res.data) return false;
        const data = res.data;
        if (data.status === true) {
            setData(data.user_id, data.token, data.user_name, data.photo);
        }
        return data;
    } catch (err) {
        console.log(err);
        return false;
    }
}
export default verifyCode;