import React, { Component, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import subscribe from "../../auth/subscribe";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import { setAgencyData, logtracker } from "../../helpers/agencyTracker";
import countreis from "./countries.json";
import "./SignupPage.scss";
import Modal from 'react-awesome-modal';
import queryString from "query-string";
import axios from "axios";


import SignupPageDisplay from "./SignupPage.styled";


const urlegy = (window.location.hostname.includes("egypt") && !window.location.hostname.includes("orange"));
const urlOrangeEgy = window.location.hostname.includes("orangeegypt");
const urlooredoo = window.location.hostname.includes("ooredoooman");
const urlzainjordan = window.location.hostname.includes("zainjordan");
const urlstckwuit = window.location.hostname.includes("stckuwait");
const urlsabafon = window.location.hostname.includes("sabafon");
const urlafricell = window.location.hostname.includes("africell");
const urlVodafone = window.location.hostname.includes("vodafoneegypt");
const urlksa = window.location.hostname.includes("ksa");



//const urlegy = window.location.hostname.includes("local");

var randomBg = bgSliders.getRandomBg();
if (urlegy || urlooredoo || urlOrangeEgy || urlzainjordan || urlsabafon || urlafricell || urlksa) {
  randomBg = bgSliders.randomBgegypt();
}

if (urlstckwuit) {
  randomBg = ""
}

const localCountryCode = localStorage.getItem("countryCode");
const countryCode = localCountryCode ? localCountryCode : "EG";

const plans = [
  {
    code: "m",
    name: "Monthly Plan",
    price: 3
  }
];

//const [instVisible, setInstVisible] = useState(false);
// show ads after 6 secound from int home page
// model open and close functions

class SignupPage extends Component {


  constructor() {
    super();
    this.state = {
      countryCode: countryCode,
      phone: "",
      password: "",
      plan: "m",
      network: "",
      error: false,
      errorMsg: "",
      windowlocation: "",
      visibleModalStc: false,
      visibleModalZain: false,

    };
  }

  openModalStc() {
    this.setState({
      visibleModalStc: true
    });
  }

  closeModalStc() {
    this.setState({
      visibleModalStc: false
    });
  }
  openModalZain() {
    this.setState({
      visibleModalZain: true
    });
  }

  closeModalZain() {
    this.setState({
      visibleModalZain: false
    });
  }


  componentDidMount() {
    if (this.props.location.search !== "") {
      setAgencyData(this.props.location.search);
    }
  }

  activeData() {
    const { phone, network, plan } = this.state
    return (phone && network && plan) ? true : false
  }

  async signup(e, countryButton = "") {

    this.setState({
      error: false,
      errorMsg: ""
    });
    e.preventDefault();
    var trackerCompany = localStorage.getItem('trackerCompany');
    var tracker = localStorage.getItem('tracker');
    console.log(trackerCompany);
    console.log(tracker);
    
    if (urlstckwuit) {
      console.log("urlstckwuit ",window.location.hostname);
      const url = window.location.search;
      
      //var stckuwitlink = "http://cg.mobi-mind.net/?ID=806,00eef37b,6,8049,3,PlayIT,http%3A%2F%2Fstckuwait.playit.mobi%2Fcatch-stckuwait";
      //var stckuwitlink = "http://galaxylp.idextelecom.com/?Id=257,Playit,965,8769,840,http%3A%2F%2Fstckuwait.playit.mobi%2Fcatch-stckuwait,0,41904,11733";
      var stckuwitlink = "http://digitalcg.net/46/WnnML/?Id=257,Playit,965,8769,840,http%3A%2F%2Fstckuwait.playit.mobi%2Fcatch-stckuwait,0,41904,11733";
        
      if (url !== "") {
        setAgencyData(url);
        if (localStorage.getItem('tracker')) {
          //const urlSearch = "?ID=806,00eef37b,6,8049,3,PlayIT,http%3A%2F%2Fstckuwait.playit.mobi%2Fcatch-stckuwait"
          //setAgencyData(urlSearch);
          var trackerCompanyt = localStorage.getItem('trackerCompany');
          var trackert = localStorage.getItem('tracker');
          //console.log(trackerCompanyt);
          //console.log(trackert);
          stckuwitlink = stckuwitlink + "&clickid=" + trackert + "&clickcompany=" + trackerCompanyt;
          logtracker(trackerCompany, tracker,stckuwitlink);
        }
      }else{
        logtracker(trackerCompany, tracker, stckuwitlink);
        window.location.href = stckuwitlink
      }
     
    }
    else if (urlegy) {
      if (countryButton === 'vod') {
        //window.location.href = "http://galaxylp.mobi-mind.net/Prelanding?Id=918,b,20,6546,705,http://egypt.playit.mobi/egy-login,0,60202,11260";
        var vodurl = "http://galaxylp.mobi-mind.net/Prelanding?Id=918,b,20,6546,568,http://egypt.playit.mobi/egy-login,0,60202,11260";
        logtracker(trackerCompany, tracker,vodurl);

      }
      else if (countryButton === 'org') {
        //window.location.href = "http://galaxylp.mobi-mind.net/?Id=918,b,20,6546,705,http://egypt.playit.mobi/egy-login,0,60201,11259";
        var orgurl = "http://galaxylp.mobi-mind.net/?Id=918,b,20,6546,705,http://egypt.playit.mobi/egy-login,0,60201,11259";
        logtracker(trackerCompany, tracker,orgurl);
      }
      else if (countryButton === 'ets') {
        //window.location.href = "http://galaxylp.mobi-mind.net/?Id=918,b,20,6546,705,http://egypt.playit.mobi/egy-login,0,60203,11258";
        var etsurl = "http://galaxylp.mobi-mind.net/?Id=918,b,20,6546,705,http://egypt.playit.mobi/egy-login,0,60203,11258";
        logtracker(trackerCompany, tracker,etsurl);
      }
    }
    else if (urlVodafone) {
      window.location.href = ""
    }
    else if (urlOrangeEgy) {
      window.location.href = "http://galaxylp.mobi-mind.net/?Id=918,b,20,6546,568,http%3A%2F%2Fegypt.playit.mobi%2Fegy-login%3Ftrxid=%3D1%26campaignid%3D1,0,60201,11259"
    }


    if (!urlVodafone && !urlegy && !urlOrangeEgy && !urlooredoo && !urlzainjordan && !urlsabafon && !urlafricell && !urlstckwuit) {
      const { phone, password, plan, countryCode, name, email } = this.state;
      const res = await subscribe(phone, password,name,email, plan, countryCode);
      console.log(res)
      if (res.status === true) {
        //if (!res.alreadySubscribed) {
          localStorage.setItem("registerUserId", res.userId);
          localStorage.setItem("registerUserPhone", phone);
          this.props.history.push("/payment");
       // }
        // return this.setState({
        //   error: true,
        //   errorMsg: "Already subscribed"
        // });
      }
      return this.setState({
        error: true,
        errorMsg: res.message
      });
    }
  }


  onInputChange(name, value) {
    this.setState({
      [name]: value,
      errors: false
    });
  }




  render() {

    const { t } = this.props;

    const { countryCode, error, errorMsg } = this.state;
    const countriesOptions = countreis.map(c => {
      return (
        <option key={`iso2${c.ISO2}`} value={c.ISO2}>
          (+{c.code}) {c.name}
        </option>
      );
    });
    const plansOptions = plans.map((c) => {
      return (
        <option value={c.code}>
          {c.name}
        </option>
      );
    });

    return (

      <SignupPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
            onSubmit={e => {
              this.signup(e);

            }}
          >
            <div className="formsLayout">
              {!urlstckwuit ? <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div> : ""}


              {urlstckwuit ?
                <div className="header  header_stckwuit">

                  <Link to="/">
                    <div className="logo" />
                  </Link>
                </div> : ""

              }
              {!urlegy && !urlOrangeEgy && !urlVodafone && !urlooredoo && !urlzainjordan && !urlstckwuit && !urlsabafon && !urlafricell ? (
                <>
                  <div className="center">
                    <select
                      className="select"
                      defaultValue={countryCode}
                      onChange={e =>
                        this.onInputChange("countryCode", e.target.value)
                      }
                    >
                      {countriesOptions}
                    </select>
                    <div className="iconInput">
                      <i className="icon phone" />
                      <input
                        type="text"
                        className="input"
                        placeholder={t("enter_phone_number")}
                        onChange={e => this.onInputChange("phone", e.target.value)}
                      />
                    </div>

                    <div className="iconInput">
                      <i className="icon username" />
                      <input
                        type="text"
                        className="input"
                        placeholder={t("enter_name")}
                        onChange={e => this.onInputChange("name", e.target.value)}
                      />
                    </div>
                    <div className="iconInput">
                      <i className="icon email" />
                      <input
                        type="text"
                        className="input"
                        placeholder={t("enter_email")}
                        onChange={e => this.onInputChange("email", e.target.value)}
                      />
                    </div>

                    <div className="iconInput">
                      <i className="icon lock" />
                      <input
                        type="password"
                        className="input"
                        placeholder={t("enter_password")}
                        onChange={e =>
                          this.onInputChange("password", e.target.value)
                        }
                      />
                    </div>
                    <div className="iconSelect">
                      <i className="icon money" />

                      <select
                        className="select"
                        onChange={e => this.onInputChange("plan", e.target.value)}
                      >
                        {/* <option>Subscribtion Plan</option> */}
                        {plansOptions}
                      </select>
                    </div>
                  </div>
                  <div className="footer">

                    <div className="normBtn">
                      <button className="btn" type="submit">
                        <Trans>sign_up</Trans>
                      </button>
                    </div>
                    <p>
                      <Trans>accept_subscribe</Trans>{" "}
                      <Link to="tos">
                        <Trans>terms_and_conditions</Trans>
                      </Link>
                    </p>
                  </div>
                </>
              ) : urlegy ? (
                <>
                  <div className="links-Section">
                    <h3>
                      <Trans>SIM_service_provider</Trans>
                    </h3>

                    <button className="btn vod"
                      onClick={e => { this.signup(e, "vod") }}>
                      <Trans>Vodafon</Trans>
                    </button>
                    <button className="btn org"
                      onClick={e => { this.signup(e, "org") }}>
                      <Trans>Orange</Trans>
                    </button>

                    <button className="btn ets"
                      onClick={e => { this.signup(e, "ets") }}
                    >
                      <Trans>Etisalat</Trans>
                    </button>
                  </div>
                </>

              ) : urlVodafone ? (
                <>
                  <div className="links-Section">
                    <h3>
                      <Trans>SIM_service_provider</Trans>
                    </h3>

                    <button className="btn vod"
                      onClick={e => { this.signup(e) }}>
                      <Trans>Vodafon</Trans>
                    </button>
                  </div>
                </>

              ) : urlOrangeEgy ? (
                <>
                  <div className="links-Section">
                    <button className="btn org btn_org"
                      onClick={e => { this.signup(e) }}>
                      <Trans>sign_up</Trans>
                    </button>
                  </div>
                </>

              ) : urlsabafon ? (
                <>
                  <div className="links-Section">
                    <button className="btn sabafon btn_sabafon">
                      <Trans>sign_up</Trans>
                    </button>
                  </div>
                </>
              ) : urlooredoo ? (
                <>
                  <div className="links-Section">
                    <button className="btn ooredoo btn_ooredoo">
                      <Trans>sign_up</Trans>
                    </button>
                  </div>
                </>
              ) : urlzainjordan ? (
                <>
                  <div className="links-Section">
                    <button className="btn zainjordan btn_zainjordan">
                      <Trans>sign_up</Trans>
                    </button>
                  </div>
                </>

              ) : urlstckwuit ? (
                <>
                  <div className="links-Section links-Sectionksa">

                    <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                      <Trans>Your content is ready!</Trans>
                    </h3>
                    <div className="play"></div>

                    <button className="btn stckwuit" onClick={e => this.signup(e)}> <Trans>sign_up</Trans> </button>


                    <div className="row text-center" >
                      <div className="col-xl-4 col-lg-4 col-4 px-5">
                        <div className="games-info">
                          <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                          <p><Trans>evaluation</Trans></p>
                        </div>
                      </div>

                      <div className="col-xl-4 col-lg-4 col-4 px-5">
                        <div className="games-info">
                          <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                          <p><Trans>game</Trans></p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-4 px-5">
                        <div className="games-info">
                          <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                          <p><Trans>user</Trans></p>
                        </div>
                      </div>
                    </div>


                    <p className="freesubscribe"><Trans>subscribstckwuit.FREE</Trans> <span><Trans>subscribstckwuit.PRICE</Trans></span> <Trans>subscribstckwuit.AUTO</Trans></p>
                  </div>
                </>

              ) : urlafricell ? (
                <>
                  <div className="links-Section">
                    <button className="btn org btn_org">
                      <Trans>sign_up</Trans>
                    </button>
                  </div>
                </>

              ) : (<></>)
              }

            </div>
          </form>
          {error ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
        </div>
      </SignupPageDisplay >
    );
  }
}
export default withTranslation()(SignupPage);
