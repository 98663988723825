//import img1 from "../assets/images/bg-banners/lq-banner-1.jpg";
//import img2 from "../assets/images/bg-banners/lq-banner-2.jpg";
import img3 from "../assets/images/bg-banners/lq-banner-3.jpg";

/*import img3 from "../assets/images/bg-banners/bg-full.jpg";*/
import img2 from "../assets/images/bg-banners/lq-banner-3.jpg";
import img5 from "../assets/images/bg-banners/lq-banner-1.jpg";

import img1 from "../assets/images/bg-banners/bg-full.png";
import img4 from "../assets/images/HomepagecomGamezroid/bgLogin.png";

import img6 from "../assets/images/Egypt/banner.svg";



const bgs = [img1, img2, img3];
const gamesCompetition =[
  // {id:"SysDCLUyVl" ,url:"/competition/assets/images/GameIcon/100x100/pizzaninjamania.png" , name :"Pizza Ninja Mania" , bg:"/competition/assets/images/compGames/Pizza-Ninja-menia.png"},
  // {id:"uXmQGKbNBU" ,url:"/competition/assets/images/GameIcon/100x100/Farm-10x10.png" , name :"Farming 10 x 10", bg:"/competition/assets/images/compGames/Farm-10x10.png"},
  // {id:"uXmQGKbNBU" ,url:"/competition/assets/images/GameIcon/100x100/Farm-10x10.png" , name :"Farming 10 x 10", bg:"/competition/assets/images/compGames/Farm-10x10.png"},
  // {id:"uXmQGKbNBU" ,url:"/competition/assets/images/GameIcon/100x100/Farm-10x10.png" , name :"Farming 10 x 10", bg:"/competition/assets/images/compGames/Farm-10x10.png"},
  // {id:"uXmQGKbNBU" ,url:"/competition/assets/images/GameIcon/100x100/Farm-10x10.png" , name :"Farming 10 x 10", bg:"/competition/assets/images/compGames/Farm-10x10.png"},
  // {id:"uXmQGKbNBU" ,url:"/competition/assets/images/GameIcon/100x100/Farm-10x10.png" , name :"Farming 10 x 10", bg:"/competition/assets/images/compGames/Farm-10x10.png"},

  {id:"SysDCLUyVl" ,img:"/assets/images/Games/pizzaninja.png" , name :"Pizza Ninja" , bg:"/assets/images/Games/bg/pizzaninja.png"},
  {id:"ULF45wrvsk" ,img:"/assets/images/Games/basketballmaster.png" , name :"Basketball Master 2" , bg:"/assets/images/Games/bg/basketballmaster.png"},
  {id:"5fbe536883a00c651d04e563" ,img:"/assets/images/Games/blackthrone.png" , name :"Black Thrones" , bg:"/assets/images/Games/bg/blackthrone.png"},
  {id:"uXmQGKbNBU" ,img:"/assets/images/Games/farm.png" , name :"Farming 10 x 10" , bg:"/assets/images/Games/bg/farm.png"},
  {id:"6haOrvntZJ" ,img:"/assets/images/Games/farry.png" , name :"Road Fury" , bg:"/assets/images/Games/bg/farry.png"},
  {id:"5fbe571983a00c651d04e564" ,img:"/assets/images/Games/towerboxer.png" , name :"tower boxer" , bg:"/assets/images/Games/bg/towerboxer.png"},
  // {id:"fSXaXs7q5m" ,img:"/assets/images/Games/game1.png" , name :"knife smash" , bg:"/assets/images/Games/bg/game1.png"},
  // {id:"r9H9tm62jE" ,img:"/assets/images/Games/Jewel-Blocks.png" , name :"Jewel Blocks" , bg:"/assets/images/Games/bg/Jewel-Blocks.png"},
  // {id:"5fbe554601655c7ad411fe03" ,img:"/assets/images/Games/santa.png" , name :"santa rush" , bg:"/assets/images/Games/bg/santa.png"},

]

const Bgcompetition = () => {
  //return `url(${bgcom}) center center / cover no-repeat`;
};

const randomBgegypt = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img1}) center center / cover no-repeat`;
};

const newBgegypt = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img6}) center center / cover no-repeat`;
};

const randomBgooredoo = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img5}) center center / cover no-repeat`;
};

const randomBgksa = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img1}) center center / cover no-repeat`;
};

const randomBgGamezroidcom = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img4}) center center / cover no-repeat`;
};

const randomBg = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  //document.body.style.background = `url(${img2}) center center no-repeat`;
  return `url(${img2}) center center / cover no-repeat`;
};
const getRandomBg = () => {
  const random = bgs[Math.floor(Math.random() * bgs.length)];
  return `url(${img2}) center center / cover no-repeat`;
};
const removeBg = () => {
  document.body.style.background = "";
};
const getAllGames = () => {
  return gamesCompetition;
};
export default { randomBg, removeBg, getRandomBg ,newBgegypt , randomBgooredoo,randomBgegypt , randomBgksa,getAllGames ,randomBgGamezroidcom};
