import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;
//const apiUrl = "http://localhost/playitmaster_backend/API/public/api/v2";

async function subscribe(msisdn, password,name,email, subscriptionPlan, country) {
  //phone, password,name,email, plan, countryCode
  const options = { msisdn, password,name,email, subscriptionPlan, country };
  try {
    const res = await axios.post(`${apiUrl}/playit/subscribe`, options);
    if (!res.data) return false;
    const data = res.data;
    // if (data.status !== true) {
    //   return false;
    // }
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

function log()
{
  const apiUrl="http://api.playit.mobi/api/v2";
  const options = { 
    operatorId:localStorage.getItem("operatorId"),
    tracker : localStorage.getItem("tracker"),
    trackerCompany : localStorage.getItem("trackerCompany")
   };
  const res = axios.post(`${apiUrl}/playit/agency/log`, options);
}

/*async function checktracker()
{
  const api_url=""
  const options = { 
    operatorId:localStorage.getItem("operatorId"),
    tracker : localStorage.getItem("tracker"),
    trackerCompany : localStorage.getItem("trackerCompany")
   };
  const res = axios.post(`${api_url}/playit/subscribe`, options);
}*/
export default subscribe;
