/* eslint-disable no-sparse-arrays */
import axios from "axios";
import envo from "../environments/environment";
import Operators from "../operators/operators.json";
const hostNamesInfo = [{
        hostName: "expresso.playit.mobi",
        theme: "expressoSenegal",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "competition.playit.mobi",
        theme: "playFree",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.competition.playit.mobi",
        theme: "playFree",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.mtnng.playit.mobi",
        theme: "nigeria",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "mtnng.playit.mobi",
        theme: "nigeria",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "www.newmtnng.playit.mobi",
        theme: "nigeria",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "newmtnng.playit.mobi",
        theme: "nigeria",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

   

    {
        hostName: "www.jawwal.gamezroid.com",
        theme: "jawwalgamezroid",
        category: 'category',
        iconTabs: 'black_iconTabs'
    },
    {
        hostName: "jawwal.gamezroid.com",
        theme: "jawwalgamezroid",
        category: 'category',
        iconTabs: 'black_iconTabs'
    },

    {
        hostName: "zainiq.gamezroid.com",
        theme: "zainiq",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.zainiq.gamezroid.com",
        theme: "zainiq",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "mtnsyria.playit.mobi",
        theme: "mtnSyria",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "mtnye.playit.mobi",
        theme: "mtnYemen",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "gamesstore.you-tele.com",
        theme: "mtn",
        category: 'category_mtn',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.gamesstore.you-tele.com",
        theme: "mtn",
        category: 'category_mtn',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "gamesstore.you.com.ye",
        theme: "mtn",
        category: 'category_mtn',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.gamesstore.you.com.ye",
        theme: "mtn",
        category: 'category_mtn',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "zainiq.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "zainsd.playit.mobi",
        theme: "zainSudan",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "playit.sd.zain.com",
        theme: "zainSudan",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "d7u.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "playit77.com",
        theme: "playit77",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "orangeegypt.playit.mobi",
        theme: "orange-egypt",
        category: 'orange_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.orangeegypt.playit.mobi",
        theme: "orange-egypt",
        category: 'orange_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "ooredoooman.playit.mobi",
        theme: "ooredoo",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.ooredoooman.playit.mobi",
        theme: "ooredoo",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "ooredoops.playit.mobi",
        theme: "ooredoops",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.ooredoops.playit.mobi",
        theme: "ooredoops",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "dzo.playit.mobi",
        theme: "ooredooz",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.dzo.playit.mobi",
        theme: "ooredooz",
        category: 'ooredoo_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "du.playit.mobi",
        theme: "duwifi",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.du.playit.mobi",
        theme: "duwifi",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "zainjordan.playit.mobi",
        theme: "zainjordan",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.zainjordan.playit.mobi",
        theme: "zainjordan",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "http://mauritania.playit.mobi",
        theme: "mauritania",
        category: 'category_mauritania',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "mauritania.playit.mobi",
        theme: "mauritania",
        category: 'category_mauritania',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "www.mauritania.playit.mobi",
        theme: "mauritania",
        category: 'category_mauritania',
        iconTabs: 'white_iconTabs'
    },


    {
        hostName: "http://telkomsel.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "telkomsel.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "www.telkomsel.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "http://gamezroid.com",
        theme: "gamezroid",
        category: 'gamezroid_category',
        iconTabs: 'black_iconTabs'
    }, {
        hostName: "gamezroid.com",
        theme: "gamezroid",
        category: 'gamezroid_category',
        iconTabs: 'black_iconTabs'
    }, {
        hostName: "www.gamezroid.com",
        theme: "gamezroid",
        category: 'gamezroid_category',
        iconTabs: 'black_iconTabs'
    },
     {
        hostName: "ethio.gamezroid.com",
        theme: "ethioGamezroid",
        category: 'category_ethioGamezroid',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "www.ethio.gamezroid.com",
        theme: "ethioGamezroid",
        category: 'category_ethioGamezroid',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "http://ramdank.gamezroid.com",
        theme: "gamezroidRamadan",
        category: 'gamezroidCompetition_category',
        iconTabs: 'gamezroid_iconTabs'
    }, {
        hostName: "ramdank.gamezroid.com",
        theme: "gamezroidRamadan",
        category: 'gamezroidCompetition_category',
        iconTabs: 'gamezroid_iconTabs'
    }, {
        hostName: "www.ramdank.gamezroid.com",
        theme: "gamezroidRamadan",
        category: 'gamezroidCompetition_category',
        iconTabs: 'gamezroid_iconTabs'
    }

    , {
        hostName: "http://asiacell.gamezroid.com",
        theme: "asiacell",
        category: 'category_vodafone',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "korekiq.playit.mobi",
        theme: "korekiq",
        category: 'category_korekiq',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "www.korekiq.playit.mobi",
        theme: "korekiq",
        category: 'category_korekiq',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "demo.gamezroid.com",
        theme: "gamezroid",
        category: 'gamezroid_category',
        iconTabs: 'black_iconTabs'
    }, {
        hostName: "http://demo.gamezroid.com",
        theme: "gamezroid",
        category: 'gamezroid_category',
        iconTabs: 'black_iconTabs'
    }

    , {
        hostName: "asiacell.gamezroid.com",
        theme: "asiacell",
        category: 'category_vodafone',
        iconTabs: 'white_iconTabs'
    }


    , {
        hostName: "stckuwait.playit.mobi",
        theme: "stcKuwit",
        category: 'stcKuwit_category',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "www.stckuwait.playit.mobi",
        theme: "stcKuwit",
        category: 'stcKuwit_category',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "sabafon.playit.mobi",
        theme: "sabafon",
        category: 'category_sabafon',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "www.sabafon.playit.mobi",
        theme: "sabafon",
        category: 'category_sabafon',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "ao.playit.mobi",
        theme: "gamezone",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "www.ao.playit.mobi",
        theme: "gamezone",
        category: 'category',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "ethio.gamezones.mobi",
        theme: "ethioGamezone",
        category: 'category_ethioGamezroid',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "www.ethio.gamezones.mobi",
        theme: "ethioGamezone",
        category: 'category_ethioGamezroid',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "http://ethio.playit.mobi",
        theme: "gamezoneplayit",
        category: 'category_gamezoneplayit',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "ethio.playit.mobi",
        theme: "gamezoneplayit",
        category: 'category_gamezoneplayit',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "www.ethio.playit.mobi",
        theme: "gamezoneplayit",
        category: 'category_gamezoneplayit',
        iconTabs: 'white_iconTabs'
    },
    {
        hostName: "ethio.playit.mobi",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    },

    {
        hostName: "africell.playit.mobi",
        theme: "africell",
        category: 'category_africell',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "www.africell.playit.mobi",
        theme: "africell",
        category: 'category_africell',
        iconTabs: 'white_iconTabs'
    }, {
        hostName: "vodafoneegypt.playit.mobi",
        theme: "vodafone",
        category: 'category_vodafone',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "www.vodafoneegypt.playit.mobi",
        theme: "vodafone",
        category: 'category_vodafone',
        iconTabs: 'white_iconTabs'
    }

    , {
        hostName: "http://vodafoneegypt.playit.mobi",
        theme: "vodafone",
        category: 'category_vodafone',
        iconTabs: 'white_iconTabs'
    },
    // {
    //   hostName: "ethio.playit.mobi",
    //   theme: "default",
    //   category:'category',
    //   iconTabs : 'white_iconTabs'
    // },
    // {
    //   hostName: "www.ethio.playit.mobi",
    //   theme: "default",
    //   category:'category',
    //   iconTabs : 'white_iconTabs'
    // },
    {
        hostName: "new",
        theme: "default",
        category: 'category',
        iconTabs: 'white_iconTabs'
    }

];

const countryThemes = [{
        code: "SN",
        theme: "expressoSenegal"
    },
    {
        code: "SD",
        theme: "zainSudan"
    },
    {
        code: "SY",
        theme: "mtnSyria"
    },
    {
        code: "YE",
        theme: "mtnYemen"
    },
    {
        code: "ET",
        theme: "ethio"
    },

];

const getThemeByHostName = hostName => {
    const hostNameInfo = hostNamesInfo.find(i => i.hostName === hostName);
    if (hostNameInfo) {
        console.log('theme', hostNameInfo.theme, hostNameInfo.hostName)
        return hostNameInfo.theme;
    }
    return false;
};
const getCategoryByHostName = hostName => {
    const hostNameInfo = hostNamesInfo.find(i => i.hostName === hostName);
    if (hostNameInfo) {
        return hostNameInfo.category;
    }
     return "category";
//    return  "category_mauritania";
};

const getIconByHostName = hostName => {
    const hostNameInfo = hostNamesInfo.find(i => i.hostName === hostName);
    if (hostNameInfo) {
        return hostNameInfo.iconTabs;
    }
    return "white_iconTabs";
};

const getThemeByCountryCode = code => {
    const countryTheme = countryThemes.find(i => i.code === code);
    if (countryTheme) {
        return countryTheme.theme;
    }
    return "default";
};

function getCountryCode() {
    //console.log(window.location.href);
    if (window.location.href.includes("ethio")) {
        localStorage.setItem("countryCode", 'ET');
        localStorage.setItem("country_name", 'ethiopia');
        localStorage.setItem("operator_name", Operators.ethio);
        return "ET"
    } else if (window.location.href.includes("egypt")) {
        localStorage.setItem("countryCode", 'EG');
        localStorage.setItem("country_name", 'egypt');
        localStorage.setItem("operator_name", Operators.egypt010);
        return "EG"
    } else if (window.location.href.includes("kuwait")) {
        localStorage.setItem("countryCode", 'KW');
        localStorage.setItem("country_name", 'kuwait');
        localStorage.setItem("operator_name", Operators.kuwait);
        return "KW"
    } else if (window.location.href.includes("ksa")) {
        localStorage.setItem("countryCode", 'SA');
        localStorage.setItem("country_name", 'saudi arabia');
        localStorage.setItem("operator_name", Operators.ksa);
        return "SA"
    } else if (window.location.href.includes("umniah")) {
        localStorage.setItem("countryCode", 'JO');
        localStorage.setItem("country_name", 'jordan');
        localStorage.setItem("operator_name", Operators.umniah);
        return "JO"
    } else if (window.location.href.includes("omantel")) {
        localStorage.setItem("countryCode", 'OM');
        localStorage.setItem("country_name", 'oman');
        localStorage.setItem("operator_name", Operators.omantel);
        return "OM"
    } else if (window.location.href.includes("ao.playit")) {
        localStorage.setItem("countryCode", 'AO');
        localStorage.setItem("country_name", 'angola');
        localStorage.setItem("operator_name", Operators.angola);
        return "AO"
    } else if (window.location.href.includes("competition")) {
        localStorage.setItem("countryCode", 'EG');
        localStorage.setItem("country_name", 'egypt');
        localStorage.setItem("operator_name", Operators.grand);
        return "EG"
    } else if (window.location.href.includes("zain-iq")) {
        localStorage.setItem("countryCode", 'IQ');
        localStorage.setItem("country_name", 'iraq');
        return "IQ"
    } else if (window.location.href.includes("asiacell")) {
        localStorage.setItem("countryCode", 'IQ');
        localStorage.setItem("country_name", 'iraq');
        localStorage.setItem("operator_name", Operators.asiacell);
        return "IQ"
    } else if (window.location.href.includes("demo.gamezroid")) {
        localStorage.setItem("countryCode", 'IQ');
        localStorage.setItem("country_name", 'iraq');
        localStorage.setItem("operator_name", Operators.asiacell);
        return "IQ"
    } else if (window.location.href.includes("gamezroid")) {
        localStorage.setItem("countryCode", 'EG');
        localStorage.setItem("country_name", 'egypt');
        localStorage.setItem("operator_name", Operators.Tpayegypt010);
        return "EG"
    } else if (window.location.href.includes("jawwal")) {
        localStorage.setItem("countryCode", 'PS');
        localStorage.setItem("country_name", 'palestine');
        localStorage.setItem("operator_name", Operators.jawwal);
        return "PS"
    } else if (window.location.href.includes("mtnng")) {
        localStorage.setItem("countryCode", 'NG');
        localStorage.setItem("country_name", 'nigeria');
        localStorage.setItem("operator_name", Operators.nigeria);
        return "NG"
    } else if (window.location.href.includes("stcbh")) {
        localStorage.setItem("countryCode", 'BH');
        localStorage.setItem("country_name", 'bahrain');
        return "BH"
    } else if (window.location.href.includes("batelco")) {
        localStorage.setItem("countryCode", 'BH');
        localStorage.setItem("country_name", 'bahrain');
        return "BH"
    } else {
        if (localStorage.getItem("countryCode")) {
            return localStorage.getItem("countryCode");
        }
        try {
            localStorage.setItem("countryCode", 'EG');
            localStorage.setItem("country_name", 'egypt');
            return "EG";
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}
const wait = async timeout =>
    new Promise(resolve => setTimeout(resolve, timeout));
const timeDifference = (current, previous) => {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
        return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
        return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
    } else {
        return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
    }
};

function slugifyReverse(slug) {
    return slug.split("_").join(" ");
}
const currentLanguage = () => {
    const lang = localStorage.getItem("i18nextLng");
    return lang;
};
const isRTL = () => {
    const lang = currentLanguage();
    if (lang == "") {
        lang = "ar";
    }
    return lang === "en" || lang === "fr" || lang === "pt" ? false : true;
};
const isValideCodeNumbers = (codeNumbers, codeLength) => {
    const invalidValues = codeNumbers.filter(i => !i && i !== 0);

    if (invalidValues.length > 0) {
        return false;
    }
    const numericValues = codeNumbers.map(i => parseInt(i));
    const hasNonNumeric = numericValues.filter(i => isNaN(i));
    if (hasNonNumeric.length > 0) {
        return false;
    }
    if (codeNumbers.length < codeLength) {
        return false;
    }
    return true;
};
const getNameByLang = (en, ar, fr, pt) => {
    const langs = { en, ar, fr, pt };
    const lang = currentLanguage();
    // console.log(lang , langs);
    // console.log(langs[lang]);
    // console.log(langs['ar']);
    // console.log(langs['en']);
    // console.log(langs['pt']);

    return langs[lang] ? langs[lang] : en;
};
export function setLoginData(
    userId,
    token,
    userName,
    userPhoto,
    operatorId,
    email,
    msisdn,
    subscriptionContractId
) {
    function v(i) {
        return i && i !== null ? i : "";
    }

    localStorage.setItem("userId", v(userId));
    localStorage.setItem("token", v(token));
    localStorage.setItem("userName", v(userName));
    localStorage.setItem("userPhoto", v(userPhoto));
    localStorage.setItem("operatorId", v(operatorId));
    localStorage.setItem("email", v(email));
    localStorage.setItem("phoneNum", v(msisdn));
    localStorage.setItem("subscriptionContractId", v(subscriptionContractId));


    //debugger;
    return true;
}

export function clearLoginData() {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userPhoto");
    localStorage.removeItem("operatorId");
    localStorage.removeItem("email");
    localStorage.removeItem("showdouble");
    localStorage.removeItem("subscriptionContractId");


    return true;
}

export async function checkdouble() {
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)

    const options = {
        subscriptionContractId: localStorage.getItem('subscriptionContractId'),
        timestamp,
        authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkdoublepoints';
    try {
        const res = await axios.post(apiUrl, options);
        if (res.data) {
            const data = res.data;
            if (data.status == false) {
                return false;
            } else {
                return true;
            }
        }

    } catch (err) {
        console.log(err)

    }
}

export function getLoginData() {
    const data = {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
        userName: localStorage.getItem("userName"),
        userPhoto: localStorage.getItem("userPhoto"),
        operatorId: localStorage.getItem("operatorId"),
        email: localStorage.getItem("email"),
        subscriptionContractId: localStorage.getItem("subscriptionContractId")

    };
    return data;
}


export async function getPhoneByHeader(linkheader = "") {
    try {
        const res = await axios.get(linkheader);

        if (res.data && res.data !== "") {
            return res.data;
        }
        return false;
    } catch (err) {
        console.log(err);
        return false;
    }
}
export default {
    getThemeByHostName,
    getThemeByCountryCode,
    getCountryCode,
    wait,
    timeDifference,
    slugifyReverse,
    isRTL,
    isValideCodeNumbers,
    currentLanguage,
    getNameByLang,
    getCategoryByHostName,
    getIconByHostName,
    getPhoneByHeader
};