import axios from "axios";
import envo from "../../environments/environmentProd";
import helpers from "../../helpers/helpers";


const localName = "playit_home_games";
const localSliderName = "playit_slider_games";

const createTimeName = "createdAt";
const expiredAfter = 1000 * 60 * 60; //1 hour
const timeNow = new Date().getTime();
const api = `${envo.apiUrl}/playit/all/games`;
const apiSlider = "http://www.api.playit.mobi/api/v1/getSlider";

const retriesNum = 5;




const existsOffline = () => {
  return localStorage.getItem(localName) ? true : false;
};


const isExpiredData = dataSavedTime => {
  return timeNow - expiredAfter > dataSavedTime ? true : false;
};
const validateData = data => {
  if (!data.online || !data.android) {
    return false;
  }
  if (
    !data.online.sliders ||
    !data.online.categories ||
    !data.android.sliders ||
    !data.android.categories
  ) {
    return false;
  }
  return true;
};



const saveDataLocally = data => {
  const localObj = {
    [createTimeName]: timeNow,
    data
  };
  localStorage.setItem(localName, JSON.stringify(localObj));
};
const getLocalData = () => {
  const local = localStorage.getItem(localName);
  const parsed = JSON.parse(local);
  // console.log(
  //   "data fetched ",
  //   helpers.timeDifference(timeNow, parsed.createdAt)
  // );
  return parsed;
};
const fetch_retry = async retries => {
  try {
    const countryName = localStorage.getItem('country_name');
    const operatorName = localStorage.getItem('operator_name');

    const res = await axios.get(api + `?country=${countryName}&operator=${operatorName}`);
    return res.data;
  } catch (err) {
    if (retries !== 1) {
      await helpers.wait(1000);
      await fetch_retry(retries - 1);
    } else if (err.response && err.response.status !== 200) {
      //catch error
    }

    console.log(err);
    return false;
  }
};
const getHomeGames = async () => {
  const start = new Date().getTime();
  if (existsOffline()) {
    const localData = getLocalData();

    if (!isExpiredData(localData.createdAt)) {
      const isValid = validateData(localData.data);
      const end = new Date().getTime();
      timeCost(start, end, "Cost of load locally");
      return isValid ? localData.data : false;
    }
  }

  const fetchedData = await fetch_retry(retriesNum);
  if (fetchedData) {
    const isValid = validateData(fetchedData);
    if (isValid) {
      saveDataLocally(fetchedData);
      const end = new Date().getTime();
      timeCost(start, end, "Cost of fetch new");
      return fetchedData;
    }
    return false;
  }

  return false;
};

const timeCost = (start, end, msg) => {
  //uncomment this code to get the time of loading data
  // console.log("end: ", end);
  // console.log("start: ", start);
  // console.log(msg, end - start);
};


export default getHomeGames;
