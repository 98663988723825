import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import "./PaymentPage.scss";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import Modal from 'react-awesome-modal';
import PaymentPageDisplay, { Card, Payment } from "./PaymentPage.styled";
import axios from "axios";
import { isWindows } from "react-device-detect";


class PaymentPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      plan: "",
      creditNumber:"",
      year:"",
      month:"",
      cvv:"",
      user_id:"",
      totalDeposit:"",
      itemId:"",
      item_description:"",
      errors: false,
      errorMsg: "",
      show: true,
      showform: false,
      showformcard:false,
      visibleModalpayment: false,
      referenceNumber: "",
      expirationTime: "",
      price:"",
      t:""

    };

  }


  componentDidMount = () =>{
    console.log("test")

    this.setState({
      t : this.props.t
    })


  }




  plans = [
    {
      code: "d",
      name: "daily plan",

    },
    {
      code: "w",
      name: "weekly plan",

    },
    {
      code: "m",
      name: "Monthly plan",

    }
  ];

  plansOptions = this.plans.map((c) => {
    //const { t } = props;
    //const { t } = this.props;
    //console.log(t)
    return (
      <option value={c.code} key={c.code}>
        {(c.name)}
      </option>
    );
  });


  onInputChange = (name, value) => {
    if (name == "plan") {
      this.setState({ plan: value })

    }
    if (name == "phone") {
      document.getElementById("phone").addEventListener("keypress", function (evt) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
      this.setState({ phone: value })

    }

    if (name == "creditNumber") {
      this.setState({ creditNumber: value })
    }
    if (name == "year") {
      this.setState({ year: value })
    }
    if (name == "month") {
      this.setState({ month: value })
    }
    if (name == "cvv") {
      this.setState({ cvv: value })
    }
    if (name == "user_id") {
      this.setState({ user_id: value })
    }
    if (name == "totalDeposit") {
      this.setState({ totalDeposit: value })
    }
    if (name == "itemId") {
      this.setState({ itemId: value })
    }
    if (name == "item_description") {
      this.setState({ item_description: value })
    }
    //console.log(value)

  }
  showpaymentform(e) {
    e.preventDefault();

    this.setState({ showform: true })
    this.setState({ showformcard: false })

  }

  showpaymentformcard(e) {
    e.preventDefault();
    this.setState({ showform: false })
    this.setState({ showformcard: true })
  }

  closeModalfawry(e){
    e.preventDefault();

    this.setState({ showform: false })
  }

  closeModalfawrycard(e){
    e.preventDefault();

    this.setState({ showformcard: false })
  }

  closeModalpayment(e)
  {
    e.preventDefault();
    this.setState({
      visibleModalpayment: false
    })
  }

  payment = async e => {
    e.preventDefault();
    console.log("submit");
    const url = "http://api.playit.mobi/api/v2/fawry";
    const customerMobile = this.state.phone;
    const plan = this.state.plan
    const options = {
      customerMobile,
      plan
    }
    const res = await axios.post(url, options);

    if (res.data.status == true) {
      this.setState({
        visibleModalpayment: true,
        referenceNumber: res.data.body.referenceNumber,
        expirationTime: res.data.body.expirationTime,
        price : res.data.body.price
      })

    }
    console.log("res", this.state.referenceNumber, this.state.expirationTime)




  }

  paymentcard = async e => {
    e.preventDefault();
    console.log("submit");
    const url = "https://api.playit.mobi/api/v2/payfawrytoken";
    const customerMobile = this.state.phone;
    const plan = this.state.plan;
    const creditNumber = this.state.creditNumber;
    const year = this.state.year;
    const month = this.state.month;
    const cvv = this.state.cvv;
    const user_id = localStorage.getItem("registerUserId");
    const totalDeposit = "20.00";
    const itemId = 'd';
    const item_description = "Monthly Plan";
    
    const options = {
      creditNumber,
      year,
      month,
      cvv,
      user_id,
      totalDeposit,
      itemId,
      item_description
    }
    const res = await axios.post(url, options);
    console.log("res", res)
    console.log("res", res.data.redirectUrl)
    console.log("res", res.url)

    if (res.data.status == true) {
      window.location.href = res.data.redirectUrl;
    }


  }

  render() {
    const { errors, errorMsg } = this.state;
    const { t } = this.props;
    return (
      <PaymentPageDisplay>
        <Payment>
          <div className="header">
            <p className="title">
              <Trans>payment_methods</Trans>
            </p>
            <p className="describe">
              <Trans>
                In order to complete your subscription please select one of
                following methods
              </Trans>
            </p>
          </div>

          {!this.state.showform &&  !this.state.showformcard ?
            <div className="flex">
              <Card
                onClick={(e) => this.showpaymentform(e)}
              >

                <p><Trans>payment.pay_with_fawry</Trans></p>
                <div className="paymentImg fawry" />

              </Card>
              {/* <Card>
                <Link to="">
                  <p><Trans>pay_with_SMS</Trans></p>
                  <div className="paymentImg sms" />
                </Link>
              </Card> */}
              <Card onClick={(e) => this.showpaymentformcard(e)}>
                {/* <Link to=""> */}
                  <p><Trans>payment.pay_with_credit</Trans></p>
                  <div className="paymentImg credit" />
                {/* </Link> */}
              </Card>
              {/* <Card>
                <Link to="/add-voucher">
                  <p><Trans>pay_with_voucher</Trans></p>
                  <div className="paymentImg voucher" />
                </Link>
              </Card> */}
            </div>
            : ""}


          {this.state.showform ?
            <div className="formPage"  style={this.state.show ? {} : { display: 'none' }} >
              <form
                onSubmit={e => {
                  this.payment(e);
                }}
              >
                <div className="formsLayout_payment" >
                <button className="closemodalfawry" onClick= {(e) => this.closeModalfawry(e)}>X</button>

                  <div className="header">
                    <Link to="/">
                      <div className="paymentlogo" />
                    </Link>
                  </div>

                  <div className="center">
                    <div className="form" style={this.state.show ? {} : { display: 'none' }}>
                      <select
                        className="select selectpayment"

                        onChange={e => this.onInputChange("plan", e.target.value)}
                      >
                        <option key="plan" value="Plan"
                          disabled
                          selected>
                          {t("Please select your plan")}</option>
                        {this.plansOptions}
                      </select>
                      <div className="iconInput iconInputpayment">
                       <i className="icon phone" />

                      <input
                        type="text"
                        className="input"
                        id="phone"
                        autoComplete="phone"
                        id="phone"
                        placeholder={t("Please Enter your phone number")}
                        onChange={e =>
                          this.onInputChange("phone", e.target.value)
                        }/>
                        </div>
                          
                      
                      
                    </div>
                  </div>

                  <div className="paymentfooter" style={this.state.show ? {} : { display: 'none' }}>
                    <div className="normBtn">
                      <button className="btn"
                        disabled={this.state.phone && this.state.plan ? "" : "disabled"}

                      >
                        <Trans>Submit</Trans>
                      </button>
                    </div>
                  </div>

                  <Modal
                      visible={this.state.visibleModalpayment}
                      width="348px"
                      height="482px"
                      effect="fadeInUp"
                      padding="0"
                      position="relative"
                      top="53px"
                     
                    >
                        <button 
                        className="closemodal"
                        onClick= {(e) => this.closeModalpayment(e)}
                        >X</button>

                      <div className="paymentModal">
                        <div className="header">
                          <Link to="/">
                            <div className="paymentlogo" />
                          </Link>
                        </div>
                        <div className="center">
                          <h2><Trans>Thank You for Chossing FawryPay</Trans></h2>
                          <div className="success"> </div>
                          <div className="fawrydata">
                            <p><Trans>Number</Trans> : {this.state.referenceNumber}</p>
                            <p><Trans>Total</Trans> : {this.state.price} L.E</p>
                            <p><Trans>Exp Date</Trans> : {this.state.expirationTime}</p>
                          </div>
                        </div>


                      </div>
                    </Modal>
                </div>
              </form>
              {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
            </div>
            : ""}
            







            {this.state.showformcard ?
            <div className="formPage"  style={this.state.show ? {} : { display: 'none' }} >
              <form
                onSubmit={e => {
                  this.paymentcard(e);
                }}
              >
                <div className="formsLayout_payment" >
                <button 
                        className="closemodalfawry"
                        onClick= {(e) => this.closeModalfawrycard(e)}
                        >X</button>

                  <div className="header">
                    <Link to="/">
                      <div className="paymentlogo" />
                    </Link>
                  </div>

                  <div className="center">
                    <div className="form" style={this.state.show ? {} : { display: 'none' }}>

                      <div className="iconInput iconInputpayment">
                       <i className="icon creditcard" />

                       <input
                        type="text"
                        className="input"
                        id="creditcard"
                        autoComplete="creditcard"
                        placeholder={t("Please Enter your CreditCard Number")}
                        onChange={e =>
                          this.onInputChange("creditNumber", e.target.value)
                        }/>
                      
                      <input
                        type="text"
                        className="input"
                        id="yy"
                        autoComplete="yy"
                        placeholder={t("YY")}
                        onChange={e =>
                          this.onInputChange("year", e.target.value)
                        }/>

                    <input
                        type="text"
                        className="input"
                        id="mm"
                        autoComplete="mm"
                        placeholder={t("mm")}
                        onChange={e =>
                          this.onInputChange("month", e.target.value)
                        }/>


                      <input
                        type="text"
                        className="input"
                        id="cvv"
                        autoComplete="cvv"
                        placeholder={t("CVV")}
                        onChange={e =>
                          this.onInputChange("cvv", e.target.value)
                        }/>


                        </div>
                          
                      
                      
                    </div>
                  </div>

                  <div className="paymentfooter" style={this.state.show ? {} : { display: 'none' }}>
                    <div className="normBtn">
                      <button className="btn">
                        <Trans>Submit</Trans>
                      </button>
                    </div>
                  </div>

                  <Modal
                      visible={this.state.visibleModalpayment}
                      width="348px"
                      height="482px"
                      effect="fadeInUp"
                      padding="0"
                      position="relative"
                      top="53px"
                     
                    >
                        <button 
                        className="closemodal"
                        onClick= {(e) => this.closeModalpayment(e)}
                        >X</button>

                      <div className="paymentModal">
                        <div className="header">
                          <Link to="/">
                            <div className="paymentlogo" />
                          </Link>
                        </div>
                        <div className="center">
                          <h2><Trans>Thank You for Chossing FawryPay</Trans></h2>
                          <div className="success"> </div>
                          <div className="fawrydata">
                            <p><Trans>Number</Trans> : {this.state.referenceNumber}</p>
                            <p><Trans>Total</Trans> : {this.state.price} L.E</p>
                            <p><Trans>Exp Date</Trans> : {this.state.expirationTime}</p>
                          </div>
                        </div>


                      </div>
                    </Modal>
                </div>
              </form>
              {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
            </div>
            : ""}





        </Payment >
      </PaymentPageDisplay >
    );
  }
}
export default withTranslation()(PaymentPage);
