import axios from "axios";
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import './RankingPage.scss';
import RankingPageDisplay, { Header, Img, Rank, Headergames } from './RankingPage.styled';
const apiUrl = envo.apiUrl;

let gameTabsInfo = [
	{
		main: "cut",
		id: "PSFSPvcZMj",
		name: "Cut The Rope",
		image: "http://devapi.playit.mobi/games-photos/40071_360x250.jpg"
	},
	{
		main: "get",
		id: "fMl9CZNY9y",
		name: "Get 10 Ultimate",
		image: "http://www.api.playit.mobi/games-photos/img-20200416-5e9844887d96cget10ultimate-html5_banner_800x600.png"
	}
];
const obj = {
	main: "cut",
	id: "PSFSPvcZMj",

};
if (!localStorage.getItem('activeGameLocally')) {
	localStorage.setItem("activeGameLocally", JSON.stringify(obj))

}

const getActiveLocally = () => {
	const locally = localStorage.getItem(`activeGameLocally`);
	return locally
		? JSON.parse(locally)
		: {
			main: "cut",
			id: "PSFSPvcZMj",

		}
};

let initActive = getActiveLocally();
let active = initActive;



class RankingPagCompetition extends Component {
	constructor() {
		super();
		this.state = {
			ranks: [],
			topThree: [],
			currentUser: {},
			showUser: false,
			bg1st: "/assets/images/leaderboard/1st-frame.svg",
			bg2nd: "/assets/images/leaderboard/2nd-frame.svgg",
			bg3rd: "/assets/images/leaderboard/3rd-frame.svg",
			imdef: "/assets/images/leaderboard/avatar.png",
			loading: false,
			noRanking: false,
			errors: false,
			errorMsg: ""
		};
	}

	componentDidMount() {
		this.init();
		// if (!localStorage.userId) {
		// 	this.getAnonLeaderboard();
		// 	return;
		// }
		//this.getLeaderboard(localStorage.operatorId, localStorage.userId);
	}
	init() {
		console.log(active.id, "id", active.main);
		this.getLeaderboard(localStorage.operatorId, localStorage.userId, active.id);

	}
	async getAnonLeaderboard() {
		try {
			this.setState({ loading: true });
			const res = await axios.get(`${apiUrl}/playit/leaderboard`);
			//const res = await axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/getTopPlayers?operatorId=&userId=guest`);
			console.log(res + "res")
			if (!res.data) return false;
			const data = res.data;
			if (data.status !== true) {
				return false;
			}
			return this.setState({
				ranks: data.data.splice(3),
				topThree: data.data.slice(0, 3),
				loading: false,
				imdef: "/assets/images/leaderboard/avatar.png"


			});
		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			// console.log(err);
			return false;
		}
	}

	async getLeaderboard(operatorId, userId, gameId) {

		const params = { "operatorId": operatorId, "userId": userId, "gameId": gameId };
		try {
			this.setState({ loading: true });
			//http://api.playit.mobi/api/v2/playit/leaderboardcomptition
			const res = await axios.get(`${apiUrl}/playit/leaderboardcomptition`, { params });
			if (!res.data) return false;

			const data = res.data;
			const currentUser = res.data.currentuser;
			this.setState({
				currentUser: currentUser,
			});
			if (currentUser) {
				if(currentUser.gamename)
				{
				this.setState({
					currentUser: currentUser,
					showUser: true
				});
			}
				// for (var i = 0; i < data.data.length; i++) {
				// 	let userinData = data.data[i].name == currentUser.name  && data.data[i].count == currentUser.count
				// 	console.log("ee" , userinData)
				// 	if(userinData) 
				// 		{
				// 			this.setState({
				// 				currentUser: {},
				// 				showUser:false
				// 			});
				// 			break;

				// 		}

				// 		else
				// 		{
				// 			this.setState({
				// 				currentUser: currentUser,
				// 				showUser:true
				// 			});

				// 		}


				//   }

				console.log("show", this.state.showUser)

			}
			if (data.status !== true) {
				return false;
			}
			if (data.data.length >= 1) {
				return this.setState({
					noRanking: false,
					loading: false,
					ranks: data.data.splice(3),
					topThree: data.data.slice(0, 3),
					loading: false,
					imdef: "/assets/images/leaderboard/avatar.png"
				});
			}

		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			console.log(err);
			return false;
		}

	}

	setActiveMainTab(main, gameId) {
		active = {
			main: main,
			id: gameId
		}
		localStorage.setItem("activeGameLocally", JSON.stringify(active))

		this.init()
		console.log(active)
	}





	render() {
		const { ranks, currentUser, showUser, topThree, loading, noRanking, errors, errorMsg, imdef } = this.state;

		const rank = ranks.map((i, k) => {
			return (
				<Rank
					className={i.currentuser == '1' ? "rank rankBorder" : "rank"}
					key={`rank-${k}`}>
					<div className="number">{i.rank}</div>
					<div className="thumb"><Img className={i.photo === null ? 'xsAvatar' : 'rankImg'} img={encodeURI(i.photo === null ? "/assets/images/leaderboard/avatar.png" : i.photo)}></Img></div>
					<div className="name">{i.name}</div>
					<div className="game_name">{i.gamename}</div>
					<div className="points">{i.count} pt</div>
				</Rank>

			);


		});
		const user =
			<Rank
				className="rank rankBorder">
				<div className="number" style={{color: '#003f64'}}>.</div>
				<div className="thumb"><Img className={currentUser.photo === null ? 'xsAvatar' : 'rankImg'} img={encodeURI(currentUser.photo === null ? "/assets/images/leaderboard/avatar.png" : currentUser.photo)}></Img></div>
				<div className="name">{currentUser.name}</div>
				<div className="game_name">{currentUser.gamename}</div>
				<div className="points">{currentUser.count} pt</div>
			</Rank>






		let mainTabs = gameTabsInfo.map((i, k) => {
			return (
				<div
					key={`mainTab-${k}`}
					className={active.main === i.main ? "isActive mainTab" : "mainTab"}
					onClick={() => {
						this.setActiveMainTab(i.main, i.id);
					}}
				>
					<img src={i.image} alt={i.name} />
					<p>{i.name}</p>
				</div>
			);

		});

		if (noRanking) {
			return (
				<RankingPageDisplay>
					<div className="noResults"><Trans>no_results</Trans><a href="/"><Trans>back_home</Trans></a></div>
				</RankingPageDisplay>)
		} else {
			return (
				<RankingPageDisplay>
					{loading ? (
						<Loader type="ranking" />
					) : (
							<>
								
								<Headergames>
									<div className="headercomgames">
										<div className="compGames">

											<Link to='game/fMl9CZNY9y'>
												<div className="gamecomp1"></div>

											</Link>

											<Link to='game/PSFSPvcZMj'>
												<div className="gamecomp2"></div>
											</Link>
										</div>




									</div>
								</Headergames>
								<div className="gamemainTabs container">
									{mainTabs}
								</div>

								<div className="rankingHeader rankingCompetitionHeader">
									<Header className="headerComp">
										<div className="second second_competition">
											<div className="name name2">{topThree[1] !== undefined ? topThree[1].name : null}</div>
											<div className="secondFrame CompetitionsecondFrame"
												style={{ backgroundImage: topThree[1] !== undefined ? `url(${topThree[1].photo})` : `url(${imdef})` }}
											></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'xsAvatar' : 'rankImg'}></div>

											<div className="mdPlate points">{topThree[1] !== undefined ? topThree[1].count : null} pt</div>
										</div>
										<div className="first first_competition">
											<div className="name name1">{topThree[0] !== undefined ? topThree[0].name : null}</div>

											<div className="firstFrame CompetitionfirstFrame"
												style={{ backgroundImage: topThree[0] !== undefined ? `url(${topThree[0].photo})` : 'url("/assets/images/leaderboard/avatar.png")' }}
											></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'smAvatar' : 'rankImg'}></div>
											<div className="bigPlate bgPlate points">{topThree[0] !== undefined ? topThree[0].count : null} pt</div>
										</div>
										<div className="third third_competition">
											<div className="name name3">{topThree[2] !== undefined ? topThree[2].name : null}</div>

											<div className="thirdFrame CompetitionthirdFrame"
												style={{ backgroundImage: topThree[2] !== undefined ? `url(${topThree[2].photo})` : 'url("/assets/images/leaderboard/avatar.png")' }}
											></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'xsAvatar' : 'rankImg'}></div>

											<div className="smallPlate smPlate points">{topThree[2] !== undefined ? topThree[2].count : null} pt</div>
										</div>
									</Header>
									{rank}
									{showUser ?
										user :
										null}

								</div>
							</>
						)}
					{errors ? <FloatingAlert type="error">{errorMsg} <a className="reload" href=" "><Trans>try_again</Trans></a></FloatingAlert> : ""}
				</RankingPageDisplay>
			)
		}
	}

}

export default withTranslation()(RankingPagCompetition)










/* function leaderboard   */

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Trans, withTranslation } from 'react-i18next';
// import { Link } from "react-router-dom";
// import Loader from "../../components/Loader/Loader";
// import envo from "../../environments/environmentProd";
// import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
// import './RankingPage.scss';
// import RankingPageDisplay, { Header, Img, Rank ,Headergames} from './RankingPage.styled';
// const apiUrl = envo.apiUrl;

// let gameTabsInfo = [
// 	{ 
// 	 main: "cut",
// 	 id: "PSFSPvcZMj",
// 	 name: "Cut The Rope" ,
// 	 image: "http://devapi.playit.mobi/games-photos/40071_360x250.jpg"
// 	},
// 	{ 
// 	 main: "get",
// 	 id: "fMl9CZNY9y",
// 	 name: "Get 10 Ultimate" ,
// 	 image: "http://www.api.playit.mobi/games-photos/img-20200416-5e9844887d96cget10ultimate-html5_banner_800x600.png"
// 	}
//   ];
// const obj = {
// 	main: "cut",
// };
// localStorage.setItem("activeGameLocally", JSON.stringify(obj));

// async function getAnonLeaderboard() {
// 	try {
// 		//this.setState({ loading: true });
// 		setLoading(true)
// 		const res = await axios.get(`${apiUrl}/playit/leaderboard`);
// 		//const res = await axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/getTopPlayers?operatorId=&userId=guest`);
// 		console.log(res + "res")
// 		if (!res.data) return false;
// 		const data = res.data;
// 		if (data.status !== true) {
// 			return false;
// 		}
// 		return this.setState({
// 			ranks: data.data.splice(3),
// 			topThree: data.data.slice(0, 3),
// 			loading: false,
// 			imdef: "/assets/images/leaderboard/avatar.png"


// 		});
// 	} catch (err) {
// 		if (err.response && err.response.status !== 200) {
// 			this.setState({
// 				errors: true,
// 				errorMsg: this.props.t("network_failed")
// 			});
// 		}
// 		// console.log(err);
// 		return false;
// 	}
// }

// async function getLeaderboard(operatorId, userId) {

// 	const params = { "operatorId": operatorId, "userId": userId };
// 	try {
// 		this.setState({ loading: true });
// 		//http://api.playit.mobi/api/v2/playit/leaderboardcomptition
// 		const res = await axios.get(`${apiUrl}/playit/leaderboardcomptition`, { params });
// 		if (!res.data) return false;

// 		const data = res.data;

// 		if (data.status !== true) {
// 			return false;
// 		}
// 		if (data.data.length >= 1) {
// 			console.log("task", data.data.slice(0, 3))
// 			return this.setState({
// 				noRanking: false,
// 				loading: false,
// 				ranks: data.data.splice(3),
// 				topThree: data.data.slice(0, 3),
// 				loading: false,
// 				imdef: "/assets/images/leaderboard/avatar.png"


// 			});
// 		}

// 	} catch (err) {
// 		if (err.response && err.response.status !== 200) {
// 			this.setState({
// 				errors: true,
// 				errorMsg: this.props.t("network_failed")
// 			});
// 		}
// 		console.log(err);
// 		return false;
// 	}

// }
// function RankingPagCompetition() {

// 	const [ranks, setranks] = useState([]);
// 	const [topThree, setTopThree] = useState([]);
// 	const [loading, setLoading] = useState(false);
// 	const [noRanking, setNoRanking] = useState(false);
// 	const [errors, setErrors] = useState(false);
// 	const [errorMsg, setErrorMsg] = useState("");


// 	useEffect(() => {
// 		if (!localStorage.userId) {
// 			this.getAnonLeaderboard();
// 			return;
// 		}
// 		this.getLeaderboard(localStorage.operatorId, localStorage.userId);

// 	  }, []);

















// }
// export default withTranslation()(RankingPagCompetition);
