import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import h from "../../helpers/helpers";
import SingleGame from "./../../components/SingleGame/SingleGame";
import "./ExclusiveGame.scss";
import ExclusiveGameDisplay from "./ExclusiveGame.styled";
class ExclusiveGame extends Component {
    render() {

        const hostName = window.location.hostname;
        // const category = h.getCategoryByHostName(hostName);
        // const urlooredoo = window.location.hostname.includes("ooredoooman");
        // const urlorange = window.location.hostname.includes("orange");
        // const urlzainjordan = window.location.hostname.includes("zainjordan");
        // const urlgamezroid = window.location.hostname.includes("gamezroid");
        // const urlstckwuit = window.location.hostname.includes("stckuwait");
        // const urlgamezone = window.location.hostname.includes("gamezone");
        // const urlsabafon = window.location.hostname.includes("sabafon");
        // const urlafricell = window.location.hostname.includes("africell");
        // const urlksa = window.location.hostname.includes("ksa");
        // const urlumniah = window.location.hostname.includes("umniah");
        // const urlEgy = (window.location.hostname.includes("egypt") && !window.location.hostname.includes("orange"));
        // const urlDemo = window.location.hostname.includes("demo");
        // const urlVodafone = window.location.hostname.includes("vodafoneegypt");

        const { games, name_ar, name_fr, name_pr, slug } = this.props;
        let { name } = this.props;

        const sliderName = h.getNameByLang(name, name_ar, name_fr, name_pr);
        console.log("slider" , sliderName)
        const gamesr = games.map((i, k) => {
           // if (i.worldCub === true) {

                return (
                    <SingleGame
                        key={`games-${k}`}
                        img={i.game_thumb}
                        name={i.game_name}
                        namrAr={i.game_name_ar}
                        gameID={i._id}
                    ></SingleGame>
                );
            //}

        });
        return (
            <ExclusiveGameDisplay>

                <div className="exculsiveBanner">
                    <div className="clusivelogo" >
                        <h1 className="exculsiveText">
                            <Trans>{sliderName}</Trans></h1>

                    </div>
                    
                    <div className="games clusgame">{gamesr}</div>
                </div>


            </ExclusiveGameDisplay>
        );
    }
}
export default withTranslation()(ExclusiveGame);
