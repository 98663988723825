import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;
  
async function Termscheck(phone)
{
    try {
      const res = await axios.get(`${apiUrl}/Unitel/terms/check?msisdn=${phone}`);
      if (res) {
          console.log(res.data.terms)
          return res.data.terms;
        }
    } catch (error) {
        console.log(error)
        
    }

    

}
  
export default Termscheck;