// import axios from "axios";
// import React, { useEffect, useState } from "react";

// const screenfull = require('screenfull');
// function toggleFullScreen() {
//   if (screenfull.isEnabled) {
//     screenfull.toggle();
//   }

// }
// async function updateleaderboard(userId, gameID) {

//   try {
//     const options = {
//       headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
//     };
  
//     axios.get(`http://api.playit.mobi//api/v1/playnow/${gameID}/${userId}`,options);
//   } catch (err) {
//     console.log(err);
//   }
//   let timer1 = setTimeout(() => {
//     console.log("settime")
//     try {
//       axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/updateUserLBPoints?userId=${userId}`);
//       console.log("api 2");
  
//     } catch (err) {
//       console.log(err);
//     }
//     try {
//       axios.get(`http://testing.playit.mobi/api/v2/UserPoints/updateUserPoints?userId=${userId}`);
//       console.log("api 3");
  
//     } catch (err) {
//       console.log(err);
//     }
  
//   },60000);

//   return () => {
//     clearTimeout(timer1);
//   };

//   // setTimeout(function () {
//   //   try {
//   //     axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/updateUserLBPoints?userId=${userId}`);
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   //   try {
//   //     axios.get(`http://testing.playit.mobi/api/v2/UserPoints/updateUserPoints?userId=${userId}`);
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // }, 60000);

// }

// const back = ()=>{
//   const url2 = window.location.href
//   console.log(url2 + "  url2")
//   alert("f")

// }

// window.onbeforeunload = function() {  
//   alert("f2")
//   back();

// }

// export default function Play({ gameIframe, gameID }) {
//   // console.log("play" ,gameIframe)
//   // const token = localStorage.getItem("token");
//   // const userId = localStorage.getItem("userId");
//   // updateleaderboard(userId, gameID)
//   // const url = `${gameIframe}/?user_id=${userId}&game_id=${gameID+""}&user_token=${token}`;
//   // var url2 =`${url+"/0"}`

//   //var url = ""

// useEffect(() => {
//   console.log("play" ,gameIframe)
//   const token = localStorage.getItem("token");
//   const userId = localStorage.getItem("userId");
//   updateleaderboard(userId, gameID)
//   url = `${gameIframe}/?user_id=${userId}&game_id=${gameID+""}&user_token=${token}`;

// } ,[])


//   return (
//     <div class="iframe-container">
//        <button
//                   id="btn"
//                   className="full"
//                   onClick={() => {
//                     toggleFullScreen()
//                   }}
//                 >
//                   </button>
//       <object type="text/html" data={url} scrolling="no" tabindex="0" id="object" allowFullScreen></object>
//     </div>

//   );
// }










///////////////////////////


import axios from "axios";
import init from './libr';

import React, { Component , PropTypes  } from "react";
import { Trans, withTranslation } from "react-i18next";




const token = localStorage.getItem("token");
const userId = localStorage.getItem("userId");
const screenfull = require('screenfull');


class Play extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      url:""
    }
  }

  componentWillReceiveProps(props) {
    console.log("receive props" , props)
    if(props.gameIframe && props.gameID)
    {
        //this.updateleaderboard(userId, props.gameID)
        let url = `${props.gameIframe}/?user_id=${userId}&game_id=${props.gameID+""}&user_token=${token}`;
        this.setState({
          url : url
        })
    }
    else 
    {
        console.log("empty")

    }
}

  componentWillUnmount() {
    console.log("my tasl api ")
    const {gameID } = this.props;
    this.updateleaderboard(userId,gameID)


  }


toggleFullScreen() {
  if (screenfull.isEnabled) {
    screenfull.toggle();
  }

}

updateleaderboard = async function updateleaderboard(userId, gameID) {

  console.log("update")
  try {
    const options = {
      headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
    };
    axios.get(`http://api.playit.mobi//api/v1/playnow/${gameID}/${userId}`,options);
    console.log("api 1");
  } catch (err) {
    console.log(err);
  }

 setTimeout(() => {
  if(this.state.flag == true)
  {
   this.update(userId,gameID)
  }
  },60000);
}

update = async function update(userId,gameID) {
  console.log("up")
    try {
      axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/updateUserLBPoints?userId=${userId}&product=playonline&gameId=${gameID}`);
      console.log("api 2");
  
    } catch (err) {
      console.log(err);
    }
    // try {
    //   axios.get(`http://testing.playit.mobi/api/v2/UserPoints/updateUserPoints?userId=${userId}&product=playonline`);
    //   console.log("api 3");
  
    // } catch (err) {
    //   console.log(err);
    // }

    this.setState({
      flag : false
    })

}
  render() {
    const {url} = this.state;
    

    return (
      <div class="iframe-container">
       <button
                  id="btn"
                  className="full"
                  onClick={() => {
                    this.toggleFullScreen()
                  }}
                >
                  </button>
      <object type="text/html" data={url} scrolling="no" tabindex="0" id="object" allowFullScreen></object>
    </div>
     

    )
}
}
export default withTranslation()(Play)








