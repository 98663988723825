import React, { Component, Suspense } from "react";
import "./App.scss";
import h from "./helpers/helpers";
import { ethLogin } from "./operators/pages/EthSignupPage/services";
import {ncellLogin} from "./operators/pages/NcellSignupPage/services";
import {bhrainLogin} from "./operators/pages/VivaSignupPage/services";


import Routes from "./Routes";
import changeTheme from "./theming/themes";


class App extends Component {
  async componentDidMount() {
   
  
   
// 
    rtlSetting();
    await setTheme();
    await autoLogin();
    
  }

  render() {
    
    return (
      <div className="App">
        <Suspense
          fallback={
            <div>
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          }
        >
          <Routes />
        </Suspense>
      </div>
    );
  }
}
async function setTheme() {
  // check hostname
  const hostName = window.location.hostname;
  //const hostName = 'ao.playit.mobi';
  let theme = h.getThemeByHostName(hostName);
  const countryCode = await h.getCountryCode();
  if (theme) {
    return changeTheme(theme);
  }
  
 
  theme = h.getThemeByCountryCode(countryCode);
  if (theme) {
    return changeTheme(theme);
  }
  
  return changeTheme("default");
}
function rtlSetting() {
  if (localStorage.getItem("i18nextLng")) {
    const lang = localStorage.getItem("i18nextLng");
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
    //document.body.dir = lang === "en" ? "ltr" : "rtl";
    if(lang === "en" || lang === "fr" || lang === "pt" || lang === "indo"){
      document.body.dir = "ltr";
    } else{
      document.body.dir = "rtl";
    }
    
  }
}
async function autoLogin() {
  const hostname = document.location.host;
  if (hostname === "ethio.playit.mobi") {
    const res = await ethLogin();
    if (res) {
      // window.location.reload();
    }
  } else if(hostname === "ncell.playit.mobi") {
    const res = await ncellLogin();
    if(res) {

    }
  }
  else if(hostname === "ncell.playit.mobi") {
    const res = await bhrainLogin();
    if(res) {

    }
  }
  
}
export default App;




