import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import UmniahSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getRedirectUrl, subscribe } from "./services";
import { client } from "../../../auth/Client";
import "./UmniahSignupPage.scss"
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";



const plans = [
  {
    plan: "d",
    name: "0.15 JOD/Day"
  },
  {
    plan: "w",
    name: "0.60 JOD/week"
  },
  {
    plan: "m",
    name: "2.0 JOD/Month"
  }
];

const randomBg = bgSliders.getRandomBg();
function UmniahSignupPage({ props, t }) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verify_code, setVerifyCode] = useState('');
  const [verify_msisdn, setVerify_msisdn] = useState('');
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [signupPlan, setSignupPlan] = useState("w");
  const [errMesg, setErrMesg] = useState('');
  const [waitingMesg, setWaitingMesg] = useState('');
  const [showLoading, setshowLoading] = useState(false);
  const [subErrorMesg, setsubErrorMesg] = useState("");






  const urlBh = window.location.hostname.includes("omantel");

  const APImsisdn = {
    bhmsisdn: ''
  }

  const [alert, setAlert] = useState(false);
  useEffect(() => {

    async function subscribeUser() {
      if (props.location.search) {
        const values = queryString.parse(props.location.search);
        if (values.cpTransactionID) {
          const phone = values.phone ? values.phone : activeData;
          const subscribed = await subscribe(phone, values.cpTransactionID);
          if (subscribed) props.history.push("/");
        }
      }
    }
    //checkHeader()
    //subscribeUser();
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
      console.log("tracker")
      setAgencyData(urlsearch);
    }
  }, []);


  // const checkHeader = () => {
  //   const phoneNum = queryString.parse(props.location.search);
  //   if (phoneNum.msisdn) {
  //     APImsisdn.bhmsisdn = phoneNum.msisdn;
  //     convertPass(phoneNum.msisdn)
  //   } else if (phoneNum.redirected) {
  //     console.log('No phone number');
  //   } else {
  //     //getMsisdn();
  //   }
  // }

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'FwtXQHcg4d';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
        console.log(datag)
  
      }
      await saveTracker(datag);
  
    }

  const signup = async e => {
    e.preventDefault();
    if (activeData) {
      let msisdn = activeData;

      if (msisdn.startsWith('0')) {
        msisdn = msisdn.substring(1);
      }

      if (!msisdn.startsWith('962')) {
        msisdn = '962' + msisdn;
      }
      setVerify_msisdn(msisdn);
      document.getElementById("btnsub").setAttribute('disabled', 'disabled');
      setWaitingMesg(true);
      axios.get(`http://api.playit.mobi/api/v2/umniah/sendpin?msisdn=${msisdn}`)
        .then(res => {
          console.log(res.data.status)
          if (res.data.status) {
            document.getElementById("btnsub").setAttribute('disabled', '');
            setWaitingMesg(false);
            setShowverif(true);
            setErrorMesg(false);
          }
          else {
            document.getElementById("btnsub").setAttribute('disabled', '');
            setWaitingMesg(false);
            setShowverif(false);
            setErrorMesg(true);
            setsubErrorMesg(res.data.msg);
          }

        })
        .catch(function (error) {
          console.log("catch")
          document.getElementById("btnsub").setAttribute('disabled', '');
          setWaitingMesg(false);
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
          console.log('complete');
        });


    }
  };

  const onInputChange = (value) => {


    document.getElementById("verifyInput").addEventListener("keypress", function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    });
    setVerifyCode(value)
  }


  const verify = async e => {
    e.preventDefault();
    // console.log("task" , verify_msisdn)
    if (verify_code) {
      setshowLoading(true);
      const res = await client.verifyCode(verify_msisdn, signupPlan, verify_code);
      if (res.status) {

        if (res.user_id) {
          tracker(verify_msisdn);
          setLoginData(res.user_id, res.token, res.user_name, res.photo, res.operator_id, res.msisdn);
        }
        setVerfiErrorMesg(false)
        setshowLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);

      } else {
        setshowLoading(false);
        setVerfiErrorMesg(true);
        setErrMesg(res.msg)

      }

    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  return (
    <UmniahSignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{subErrorMesg}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          {/* <Trans>register.VERIFYERROR</Trans> */}
          <Trans> {errMesg} </Trans>
        </FloatingAlert>
      ) : null}
      {waitingMesg ? (
        <FloatingAlert>
          <Trans>sendCode</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup operatorSignupGamez'>
        <div className="formsLayout formsLayoutgamezorid formsLayoutniger">
          <div className="header">
            <Link to="/">
              <div className="logogamezroid logo" />
            </Link>
          </div>
          {!showverif ?
            <>

              <div className="links-Section links-SectionGamezorid links-SectionUmniah">

                <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                  <Trans>Your content is ready!</Trans>
                </h3>


                <div className="play"></div>

                <form>

                  <div>
                    <div className="inputs">
                      <select className="select selectUmnia">
                        <option value="">Jordan (+962)</option>
                      </select>
                      <input
                        type="text"
                        className="input inputumnia"
                        placeholder={t("Enter your phone number")}
                        value={activeData}
                        onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                      />
                    </div>
                    <div className="iconSelect">
                    <i className="icon money" />
                      <span className="subplan">                      <Trans>Subscribtion_Plan</Trans>
                      </span>
                      {/* <Trans>Subscribtion_Plan</Trans> */}
                      {/* <div>
                      <i className="icon money" />
                      <p><Trans>Subscribtion_Plan</Trans></p>

                      </div> */}
                     
                      <select
                        style={{ width: '55%' }}
                        className="select"
                        defaultValue={signupPlan}
                        onChange={e => setSignupPlan(e.target.value)}
                      >
                        <Trans>{plansOptions}</Trans>
                      </select>

                    </div></div>


                </form>

                <div>
                  <button
                    id="btnsub"
                    className="btn gamezroid btn_gamezroid"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      signup(e);
                    }}
                  >
                    <Trans>Signup</Trans>
                  </button>
                </div>

                <div className="row text-center" >
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                      <p><Trans>evaluation</Trans></p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                      <p><Trans>game</Trans></p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                      <p><Trans>user</Trans></p>
                    </div>
                  </div>
                </div>
              </div>

            </>
            : null}

          <div className="center">

            {showverif ?
              <div>
                <input
                  type="text"
                  className="input"
                  value={verify_code}
                  id="verifyInput"
                  maxLength='6'
                  placeholder={t("Enter_verifycode")}
                  onChange={e => onInputChange(e.target.value)}
                />
                <br></br><br></br>

                <button
                  className="btn"
                  disabled={activeData ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }}
                >
                  <Trans>Verify_code</Trans>
                </button>
              </div> : null}

          </div>

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}


          <div className="footer">
            {/* <div className="ncellTos">
                  <h3><Trans>terms_and_conditions</Trans></h3>

                  <p><Trans>OMSUBSCRIBE_DAILY</Trans></p>
                  <p> <Trans>OMSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>OM_UNSUBSCRIBE</Trans> </p>


                </div> */}
          </div>

        </div>
      </div>

    </UmniahSignupPageDisplay>
  );
}
export default withTranslation()(UmniahSignupPage)
