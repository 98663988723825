import axios from "axios";
import envo from "../environments/environmentProd";
import { getLoginData } from "../helpers/helpers";
import forgetPassword from "./forgetPassword";
import login from "./login";
import logout from "./logout";
import sendVerficationCode from "./sendVerficationCode";
import subscribe from "./subscribe";
import unsubscribe from "./unsubscribe";
import verifyCode from "./verifyCode";
import verifyCodeyemen from "./verifyCodeyemen";
import Terms from "./Terms"
import Termscheck from './termsCheck'

const apiUrl = envo.apiUrl;
class Client {
    async auth() {
        if (!localStorage.getItem("userId") || !localStorage.getItem("token")) {
            return false;
        }
        const url = `${apiUrl}/checkAccessToken/${localStorage.getItem("token")}`;
        const res = await axios(url);
        if (res.status !== 200) {
            return false;
        }
        const data = res.data;

        if (!data) {
            return false;
        }
        if (!data.status) {
            return false;
        }
        if (data.status === true) {
            const resUserId = data.user_id;
            const localUserId = getLoginData().userId;
            if (resUserId === localUserId) {
                return true;
            }
            return false;
        }
        return false;
    }
}
Client.prototype.login = login;
Client.prototype.logout = logout;
Client.prototype.subscribe = subscribe;
Client.prototype.sendVerficationCode = sendVerficationCode;
Client.prototype.verifyCode = verifyCode;
Client.prototype.verifyCodeyemen = verifyCodeyemen;
Client.prototype.forgetPassword = forgetPassword;
Client.prototype.unsubscribe = unsubscribe;
Client.prototype.Terms = Terms;
Client.prototype.Termscheck = Termscheck;



export const client = new Client();