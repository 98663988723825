import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./JawwalgamezroidSignupPage.scss"
import JawwalgamezroidSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getPhoneByHeader, getRedirectUrl, subscribe } from "./services";


const randomBg = bgSliders.getRandomBg();
function JawwalgamezroidSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorMesgverify, seterrorMesgverify] = useState("");

  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [signup, setSignup] = useState("signup");

  const urljawwal = window.location.hostname.includes("jawwal");
  const url = window.location.href;


  const APImsisdn = {
    bhmsisdn: ''
  }

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    // if (url.includes('signup2')) {
    //   setSignup("signup2")
    // }

    // async function fetchit() {
    //   var linkheader = "http://ao.playit.mobi/checkheaderunitel.php";
    //   const num = await getPhoneByHeader(linkheader);
    //   var phone_h = num;
    //   setActiveData(phone_h);
    //   if (phone_h != false) {
    //     setCheckPhone(true)
    //   }
    // }
    // fetchit();
  }, []);
  const Subscribe = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();
      var url = ""
        url = `http://api.playit.mobi/api/v2/jawwal/gamezroid/sendpin?msisdn=${phon_n}`;

        setshowLoading(true);
        axios.get(url)
          .then(res => {
            console.log(res);
            if (res.data.status == true) {
              setshowLoading(false);
              if (res.data.user_id) {
                setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
                //window.location.href = "/";
              }
              
                setShowverif(true);
                setErrorMesg(false)
                //window.location.href = "/";
             
             

              
            } else {
              setshowLoading(false);
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true)
              //window.location.href ="/login"
            }

          })
          .catch(function (error) {
            setErrorMesg(true)
            setShowverif(false)
          })
          .finally(function () {
            console.log('complete');
          });
      
     
    }
  };
  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      // if (!phon_n.slice(0, 4).includes('244')) {
      //   phon_n = '244' + phon_n;
      // }
     // http://api.playit.mobi/api/v2/jawwal/gamezroid/verify?msisdn=962789100130&pin=1234&pin=11
      axios.get(`http://api.playit.mobi/api/v2/jawwal/gamezroid/verify?msisdn=${phon_n}&pin=${verifyCode}`)
        .then(res => {
          console.log(res);
          if (res.data.status == true) {
            setVerfiErrorMesg(false)
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              window.location.href = "/";
            }
            window.location.href = "/";
          } else {
            setVerfiErrorMesg(true);
            seterrorMesgverify(res.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error);
          setVerfiErrorMesg(true)
        })
        .finally(function () {

          console.log('complete');
        });
    }
  };



  return (
    <JawwalgamezroidSignupPageDisplay className="" bgImg={ signup == 'signup'? '': randomBg}>
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div  className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo' />
            </Link>
          </div>
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3>
              <div className="play"></div>

              <input
                type="text"
                className="input gamezoneInput"
                placeholder={t("Enter_your_phone_number")}
                value={activeData}
                disabled={checkPhone == true}
                onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                
              />
              <button
                id="signup"
                className="btn btngamezone"
                disabled={activeData ? "" : "disabled"}
                onClick={(e) => { Subscribe(e) }}
              >
                {t("Subscribe")}
              </button>

              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter">
            {!showverif ? <div>

            </div> : null}
            <div className="ncellTos jawwalgamezoridncellTos">
              <h3><Trans>terms_and_conditions</Trans></h3>
              <p><Trans>JAWWALGameSUBSCRIBE_DAILY</Trans></p>
              <p> <Trans>JAWWALGameSUBSCRIBE_TAX</Trans> </p>
              <p> <Trans>JAWWALGamSUBSCRIBEUNSUBSCRIBE</Trans> </p>
            </div>
          </div>
            </div>

            : null}

          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeJawwalGameszorid</Trans></p>
              <input
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

                
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }

{showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}


          

        </div>
      </div>

    </JawwalgamezroidSignupPageDisplay>
  );
}
export default withTranslation()(JawwalgamezroidSignupPage)
