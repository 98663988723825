


export const list4 = [
  {
    url: "/profile",
    text: "side_nav.profile",
    logged: true
  },
  {
    url: "/my-games",
    text: "my_games",
    logged: true
  },
  {
    url: "/leaderboard",
    text: "side_nav.ranking"
  },
  // {
  //   url: "/",
  //   text: "side_nav.my_coins",
  //   logged: true
  // },
  {
    url: "/faqs",
    text: "side_nav.faq"
  },
  {
    url: "/tos",
    text: "terms_and_conditions"
   },
  // {
  //   url: "/rules",
  //   text: "side_nav.rules"
  // }
  {
    url: "/videoPlay",
    text: "side_nav.videos_tutorials"
  },
  // {
  //   url: "/payment",
  //   text: "payment_methods",
  //   logged: true
  // },
  {
    url: "/change-language",
    text: "side_nav.language"
  },
  {
    url: "/logout",
    text: "side_nav.logout",
    logged: true
  },
  {
    url: "/unsubscribe",
    text: "unsubscribe",
    logged: true
  }
];
