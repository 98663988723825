import React from "react";
import GameList from "../../../components/GameList/GameList";
import h from "../../../helpers/helpers";
export default function CategoryGames({ name, category, games }) {
  let cname = h.getNameByLang(name.en, name.ar, name.fr,name.pt);

  const hostName = window.location.hostname;
  const cat_theme = h.getCategoryByHostName(hostName);

  const urlooredoo = window.location.hostname.includes("ooredoo");
  const urlethio = window.location.hostname.includes("ethio");
  const urlorange = window.location.hostname.includes("orange");
  const urlzainjordan = window.location.hostname.includes("zainjordan");
  const urlgamezroid = window.location.hostname.includes("gamezroid");
  const urlstckwuit = window.location.hostname.includes("stckuwait");
  const urlgamezone= window.location.hostname.includes("ao.playit");
  const urlsabafone= window.location.hostname.includes("sabafon");
  const urlafricell= window.location.hostname.includes("africell");
  const urlksa= window.location.hostname.includes("ksa");
  const urlumniah= window.location.hostname.includes("umniah");
  const urlEgy = (window.location.hostname.includes("egypt") && !window.location.hostname.includes("orange"));
  const urlDemo= window.location.hostname.includes("demo");
  const urlVodafone = window.location.hostname.includes("vodafoneegypt");
  const urlcompetition = window.location.hostname.includes("competition");
  const urlOmantel = window.location.hostname.includes("omantel");
  const urlmtnng = window.location.hostname.includes("mtnng");
  const urlncell = window.location.hostname.includes("ncell");
  const urlMtn = window.location.hostname.includes("gamesstore");
  const urlAlgeriaor = window.location.hostname.includes("dzo");
  const urlDu = window.location.hostname.includes("du");
  const urlkorekiq = window.location.hostname.includes("korekiq");

  const urlmauritania = window.location.hostname.includes("mauritania");
  const urltelkomsel = window.location.hostname.includes("telkomsel");













if(urlncell || urlOmantel || urlcompetition ||urlDu || urlkorekiq ||urltelkomsel|| urlmauritania ||urlAlgeriaor||urlzainjordan || urlethio || urlMtn ||urlDemo || urlksa || urlVodafone || urlumniah || urlooredoo || urlstckwuit || urlgamezroid || urlgamezone || urlsabafone || urlafricell || urlEgy  || urlorange ||  urlmtnng ) 
{
  switch(name.en)
  {
    case 'action':
      name.en = 'Action'
      break;

    case 'Action':
        name.en = 'Action'
      break;
  
      case 'sports':
      name.en = 'Sports'
      break;

      case 'kids and education':
        name.en = 'Kids and Education'
        break;
       
    case 'arcade':
       name.en = 'Arcade'
    break;

    case 'adventure':
       name.en = 'Adventure'
    break;
  
    case 'casual':
      name.en = 'casual'
      break;

      case 'strategy':
        name.en = 'Strategy'
        break;

        
  case 'brain':
       name.en = 'Brain'
     break;
  
        case 'multiplayer':
          name.en = 'multiplayer'
          break;

     case 'racing':
            name.en = 'Racing'
      break;
             
      case 'virtual reality':
        name.en = 'Virtual Reality'
  break;
             
          default:
      break;
  }
}
  //console.log()
  console.log("list" , games)

  return (
    <>
      <div className="container">
        <div className="categoryTitle">
          <img src={"/assets/icons/themes/" + cat_theme + "/" +  name.en + ".svg"} alt={name.en} />
          {cname}
        </div>
      </div>
      <GameList games={games} />
    </>
  );
}
