import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { client } from './../../auth/Client';
import './LogoutPage.scss';
import LogoutPageDisplay from './LogoutPage.styled';

class LogoutPage extends Component {
	async logout(e) {
		e.preventDefault();
		await client.logout();
		this.props.history.push("/login");
	}
	render() {
		return (
			<>
			<LogoutPageDisplay>

				<p className="logoutQues"><Trans>logout.you_want_to_exit</Trans></p>
				<div className="normBtn">
					<button onClick={(e) => { this.props.history.push("/")}} className="btn"><Trans>logout.keep_me_logged</Trans></button>
				</div>
				<div>
					<button className="btn secondary" onClick={(e) => this.logout(e)}><Trans>logout.exit</Trans></button>
				</div>
			</LogoutPageDisplay>
			</>
		)
	}
}
export default withTranslation()(LogoutPage)
