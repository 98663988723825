import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";

const headers = { 'bhmsisdn':''} ;
export async function bhrainLogin(msisdn) {
  headers.bhmsisdn = msisdn
  try {
    const url = `http://www.api.playit.mobi/api/v1/getMSIDN${headers}`;
    const res = await axios.get(url);
    if (res.data && res.data !== false) {
      const data = res.data;
      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        data.user_email
      );
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}


export async function getPhoneByHeader(linkheader = "") {
    try {
     
     const res = await axios.get(linkheader);
     console.log('resdata',res);
     
     if (res.data && res.data !== "") {
       return res.data;
     }
     return false;
   } catch (err) {
     console.log(err);
     return false;
   }
 }


export async function getRedirectUrl(phone, plan) {
  try {
    const url = "http://api.playit.mobi/api/v2/ncell/getNCellConsentPage";
    const options = {
      phone,
      plan
    };
    const res = await axios.post(url, options);
    if (res.data) {
      const data = res.data;
      if (!data.redirectionURL) {
        return false;
      }
      return data.redirectionURL;
    }
  } catch (err) {
    console.log(err);
  }
}
export async function subscribe(phone, cpTransactionID) {
  try {
    const url = "http://api.playit.mobi/api/v2/ncell/Subscribe";
    const options = {
      phone,
      cpTransactionID
    };
    const res = await axios.post(url, options);
    if (res.data) {
      const data = res.data;
      if (!data.status) {
        return false;
      }
      return true;
    }
  } catch (err) {
    console.log(err);
  }
}
